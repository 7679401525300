import React from 'react';
import PropType from 'prop-types';

import { Button } from 'components/ui';
import { withStepperContext, StepperContext } from 'components/ui/stepper';

export class PreviousStep extends React.Component {
  static propTypes = {
    prevStep: PropType.func.isRequired,
    stepState: PropType.oneOfType([PropType.func, PropType.object]).isRequired
  };

  goBack = () => {
    const { prevStep, stepState } = this.props;

    if (!(stepState instanceof Function)) prevStep(stepState);
    else prevStep({});
  };

  render() {
    const {
      prevStep,
      nextStep,
      stepState,
      activeStep: _activeStep,
      prevSteps: _prevSteps,
      ...props
    } = this.props;

    return (
      <StepperContext.Consumer>
        {({ prevSteps, activeStep }) => {
          if (!prevSteps[activeStep]) return null;
          return (
            <Button onClick={this.goBack} {...props}>
              Back
            </Button>
          );
        }}
      </StepperContext.Consumer>
    );
  }
}

export default withStepperContext(PreviousStep);
