export default () => ({
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 22,
    marginBottom: 10
  },
  nameWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  name: {
    paddingLeft: 8,
    '& input': {
      fontSize: 28,
      fontWeight: 600
    }
  },
  botsDirectory: {
    marginTop: 30
  },
  botLink: {
    display: 'flex',
    alignItems: 'center'
  },
  botName: {
    paddingLeft: 8
  }
});
