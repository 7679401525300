/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveBotFromOrganizationInput = {|
  clientMutationId?: ?string,
  organizationRowId?: ?any,
  botRowId?: ?any,
|};
export type removeOrganizationBotMutationVariables = {|
  input: RemoveBotFromOrganizationInput,
  organizationRowId: any,
|};
export type removeOrganizationBotMutationResponse = {|
  +removeBotFromOrganization: ?{|
    +query: ?{|
      +organizationByRowId: ?{|
        +bots: {|
          +totalCount: number
        |}
      |}
    |}
  |}
|};
export type removeOrganizationBotMutation = {|
  variables: removeOrganizationBotMutationVariables,
  response: removeOrganizationBotMutationResponse,
|};
*/


/*
mutation removeOrganizationBotMutation(
  $input: RemoveBotFromOrganizationInput!
  $organizationRowId: BigInt!
) {
  removeBotFromOrganization(input: $input) {
    query {
      organizationByRowId(rowId: $organizationRowId) {
        bots {
          totalCount
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveBotFromOrganizationInput!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationRowId",
    "type": "BigInt!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "organizationRowId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BotsConnection",
  "kind": "LinkedField",
  "name": "bots",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeOrganizationBotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveBotFromOrganizationPayload",
        "kind": "LinkedField",
        "name": "removeBotFromOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organizationByRowId",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeOrganizationBotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RemoveBotFromOrganizationPayload",
        "kind": "LinkedField",
        "name": "removeBotFromOrganization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organizationByRowId",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "removeOrganizationBotMutation",
    "operationKind": "mutation",
    "text": "mutation removeOrganizationBotMutation(\n  $input: RemoveBotFromOrganizationInput!\n  $organizationRowId: BigInt!\n) {\n  removeBotFromOrganization(input: $input) {\n    query {\n      organizationByRowId(rowId: $organizationRowId) {\n        bots {\n          totalCount\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac3b3efeca8ed0b05bc5c544729ba135';

module.exports = node;
