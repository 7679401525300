/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Provider = "CNP" | "COGNITO" | "GOOGLE" | "KEYCLOAK" | "MICROSOFT_SIA_PARTNERS" | "ONE_ACCOUNT" | "ONE_ACCOUNT_AXA" | "PASS_AXA" | "STRATUMN" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type inviteOrgMemberModal_organization$ref: FragmentReference;
declare export opaque type inviteOrgMemberModal_organization$fragmentType: inviteOrgMemberModal_organization$ref;
export type inviteOrgMemberModal_organization = {|
  +name: string,
  +rowId: any,
  +organizationMemberLicensesLeft: ?number,
  +providers: ?$ReadOnlyArray<?Provider>,
  +account: ?{|
    +id: string,
    +rowId: any,
  |},
  +$refType: inviteOrgMemberModal_organization$ref,
|};
export type inviteOrgMemberModal_organization$data = inviteOrgMemberModal_organization;
export type inviteOrgMemberModal_organization$key = {
  +$data?: inviteOrgMemberModal_organization$data,
  +$fragmentRefs: inviteOrgMemberModal_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "inviteOrgMemberModal_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationMemberLicensesLeft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "providers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Organization"
};
})();
// prettier-ignore
(node/*: any*/).hash = '29dbe06f38ee495bf69b52c115874051';

module.exports = node;
