import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation updateOrganizationMutation($input: UpdateOrganizationByRowIdInput!) {
    updateOrganizationByRowId(input: $input) {
      organization {
        id
        rowId
        name
        avatar
      }
    }
  }
`;

export default (environment, organizationRowId, name, avatar) =>
  withSpanAsync('updateOrganizationByRowId', SpanType.mutation, async () => {
    // This request is a patch so we do not want to write over the current value
    // with an empty one.
    const patch = {};
    if (name) patch.name = name;
    if (avatar) patch.avatar = avatar;

    const variables = {
      input: {
        rowId: organizationRowId,
        patch
      }
    };

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);

          resolve();
        },
        onError: err => {
          const error = new Error(`Unable to update Organization ${name}`);

          console.error(err);

          reject(error);
        }
      });
    });
  });
