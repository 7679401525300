import * as Sentry from '@sentry/react';

export const SpanType = {
  reqIn: 'app.request.incoming',
  reqOut: 'app.request.outgoing',
  mutation: 'app.mutation',
  processing: 'app.processing',
  gqlResolver: 'app.gql.resolver',
  gqlQuery: 'app.gql.query',
  gqlMutation: 'app.gql.mutation'
};

/**
 * This represents a function that optionally takes a span. There are certain cases where
 * we want the function to end the span and not our wrapper such as async functions.
 */
export const withSpanAsync = async (name, spanType, fn) =>
  Sentry.startSpan({ name }, async spanInstance => {
    Sentry.setTag('spanType', spanType);
    try {
      // try to run our wrapped function
      const result = await fn(spanInstance);
      return result;
    } finally {
      if (spanInstance) spanInstance.end();
    }
  });

/**
 * This represents a function that optionally takes a span. There are certain cases where
 * we want the function to end the span and not our wrapper such as async functions.
 */
export const withSpan = (name, spanType, fn) =>
  Sentry.startSpan({ name }, spanInstance => {
    Sentry.setTag('spanType', spanType);
    try {
      // try to run our wrapped function
      const result = fn(spanInstance);
      return result;
    } finally {
      if (spanInstance) spanInstance.end();
    }
  });
