import React from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import compose from 'lodash.flowright';
import { ROUTE_MY_PROFILE, ROUTE_EDIT_PROFILE } from 'constant/routes';

import { LeftPane } from 'components/layout';

import MyProfile from './myProfile';
import EditProfile from './editProfile';

export function profileRoute() {
  return (
    <>
      <LeftPane title="Profile">
        <Switch>
          <Route exact path={ROUTE_MY_PROFILE} component={MyProfile} />
          <Route exact path={ROUTE_EDIT_PROFILE} component={EditProfile} />
          {/* Fallback for non-existing pages */}
          <Route>
            <Redirect to={ROUTE_MY_PROFILE} />
          </Route>
        </Switch>
      </LeftPane>
    </>
  );
}

export default compose(withRouter)(profileRoute);
