import { commitMutation } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation editAccountMembershipOrgMutation(
    $input: EditAccountMembershipInput!
    $organizationRowId: BigInt!
  ) {
    editAccountMembership(input: $input) {
      boolean
      query {
        organizationByRowId(rowId: $organizationRowId) {
          organizationOwnerLicensesLeft
        }
      }
    }
  }
`;

// This mutation needs both the accountId and the accountRowId. The acountRowId
// is used for the mutation itself and the accountId is used to update the
// record in the store.
export default (
  environment,
  organizationRowId,
  role,
  userRowId,
  accountId,
  accountRowId,
  grantOrRevoke,
  connectionKey // Fragment connection key in order to update the relay store
) =>
  withSpanAsync('editAccountMembershipOrg', SpanType.mutation, async () => {
    const variables = {
      input: {
        _role: role,
        _userId: userRowId,
        _accountId: accountRowId,
        grantOrRevoke
      },
      organizationRowId
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        console.log('Response received from server: ', response, errors);
      },
      onError: err => {
        console.error(err);
      },
      updater: store => {
        const payload = store.getRootField('editAccountMembership');
        // If the mutation is successful we need to update the store so that
        // the permission is updated visually.
        if (payload && payload.getValue('boolean')) {
          // Get the effected user from the account object.
          const account = store.get(accountId);

          /* 
          No need to pass in the filters to getConnection.
          However, we need to fetch the 'edges'
        */

          const members = ConnectionHandler.getConnection(
            account,
            connectionKey
          );
          const edges = members.getLinkedRecords('edges');

          const user = edges
            .map(edge => edge.getLinkedRecord('node'))
            .find(node => node.getValue('userId') === userRowId);

          // Set the correct value for the correct role based on mutation args.
          const getTargetField = () => {
            if (role === 'ADMIN') {
              return 'admin';
            }
            if (role === 'LEADER') {
              return 'leader';
            }
            return 'reader';
          };

          const targetField = getTargetField();
          const targetValue = grantOrRevoke === 'GRANT';
          user.setValue(targetValue, targetField);

          // If the user removes their own admin access update the canUpdate
          // property in the associated account to update the UI.
          const loggedInUserRowId = store
            .getRoot()
            .getLinkedRecord('me')
            .getValue('rowId');
          if (
            role === 'ADMIN' &&
            grantOrRevoke === 'REVOKE' &&
            userRowId === loggedInUserRowId
          ) {
            account.setValue(false, 'canUpdate');
          }
        }
      }
    });
  });
