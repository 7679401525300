export default () => ({
  modalSecondaryField: {
    marginTop: 14
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    '& > button': { marginLeft: 10 }
  }
});
