import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import { OptionsContext } from 'components/ui/options';

import styles from './option.style';

export const Option = ({
  id,
  children,
  classes,
  disabled,
  title,
  description
}) => (
  <OptionsContext.Consumer>
    {({ selected, select }) => {
      const onClick = () => select(id);
      return (
        <button
          disabled={disabled}
          className={classNames(classes.root, {
            [classes.disabled]: disabled
          })}
          onClick={onClick}
        >
          <div className={classes.radio}>
            {selected === id && <div className={classes.innerRadio} />}
          </div>
          <div
            className={classNames(classes.children, {
              [classes.selected]: selected === id
            })}
          >
            <div className={classes.title}>{title}</div>
            {description && (
              <div className={classes.description}>{description}</div>
            )}
            {children}
          </div>
        </button>
      );
    }}
  </OptionsContext.Consumer>
);

Option.propTypes = {
  id: PropType.oneOfType([PropType.string, PropType.number]).isRequired,
  classes: PropType.object.isRequired,
  title: PropType.string.isRequired,
  description: PropType.string,
  children: PropType.node,
  disabled: PropType.bool
};

Option.defaultProps = {
  disabled: false,
  description: null,
  children: null
};

export default injectSheet(styles)(Option);
