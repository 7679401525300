export default {
  root: {
    height: '100vh',
    display: 'flex',
    flexFlow: 'column nowrap',
    overflowY: 'hidden',
    backgroundColor: '#F2F1F5'
  },
  header: {
    flexShrink: 0
  },
  body: {
    flexGrow: 1,
    overflowY: 'auto'
  }
};
