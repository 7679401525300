/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Provider = "CNP" | "COGNITO" | "GOOGLE" | "KEYCLOAK" | "MICROSOFT_SIA_PARTNERS" | "ONE_ACCOUNT" | "ONE_ACCOUNT_AXA" | "PASS_AXA" | "STRATUMN" | "%future added value";
export type SendUserInviteInput = {|
  userRowId: any,
  accountRowId: any,
  providers?: ?$ReadOnlyArray<?Provider>,
|};
export type sendUserInviteMutationVariables = {|
  input: SendUserInviteInput
|};
export type sendUserInviteMutationResponse = {|
  +sendUserInvite: ?{|
    +userRowId: any,
    +accountRowId: any,
  |}
|};
export type sendUserInviteMutation = {|
  variables: sendUserInviteMutationVariables,
  response: sendUserInviteMutationResponse,
|};
*/


/*
mutation sendUserInviteMutation(
  $input: SendUserInviteInput!
) {
  sendUserInvite(input: $input) {
    userRowId
    accountRowId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "SendUserInviteInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendUserInvitePayload",
    "kind": "LinkedField",
    "name": "sendUserInvite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userRowId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accountRowId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sendUserInviteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sendUserInviteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "sendUserInviteMutation",
    "operationKind": "mutation",
    "text": "mutation sendUserInviteMutation(\n  $input: SendUserInviteInput!\n) {\n  sendUserInvite(input: $input) {\n    userRowId\n    accountRowId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cf7098f68d8f8e541c78d48309a16e94';

module.exports = node;
