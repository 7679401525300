export default theme => ({
  section: {
    marginTop: 43,
    color: theme.grey1
  },
  sectionHeader: {
    paddingBottom: 15,
    borderBottom: `1px solid ${theme.grey6}`,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  sectionHeaderTitle: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1
  },
  sectionHeaderTitleText: {
    paddingRight: 6,
    fontSize: 16,
    fontWeight: 600
  },
  sectionHeaderCollapse: {
    paddingRight: 12,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '&[data-is-collapsible=true]': {
      cursor: 'pointer'
    }
  },
  sectionHeaderCollapseMessage: {
    marginRight: 8,
    color: theme.grey4,
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 500
  },
  sectionHeaderCollapseIcon: {
    flexShrink: 0,
    height: 17,
    width: 17,
    color: theme.grey1,
    transition: 'transform 0.3s ease-in-out',
    transform: 'rotate(90deg)',
    '&[data-input-open=true]': {
      transform: 'rotate(0deg)'
    }
  },
  keyItem: {
    padding: '15px 0px',
    borderBottom: `1px solid ${theme.grey6}`,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 400
  },
  keyInfo: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    flexGrow: 1
  },
  keyIdx: {
    fontWeight: 500,
    minWidth: 38
  },
  keyEvent: {
    minWidth: 165
  },
  keyActions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  keyAction: {
    paddingLeft: 20
  },
  addKeyButton: {
    marginTop: 20
  }
});
