import React from 'react';
import PropTypes from 'prop-types';
import { LoaderTraceLogo } from '@stratumn/atomic';
import injectSheet from 'react-jss/lib/injectSheet';
import styles from './loadingState.style';

const LoadingState = ({ classes }) => (
  <div className={classes.loadingContainer}>
    <LoaderTraceLogo />
  </div>
);

LoadingState.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectSheet(styles)(LoadingState);
