/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type collaboratorsHeader_organization$ref = any;
type removeCollaboratorModal_collaborator$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type collaborators_organization$ref: FragmentReference;
declare export opaque type collaborators_organization$fragmentType: collaborators_organization$ref;
export type collaborators_organization = {|
  +id: string,
  +rowId: any,
  +canUpdate: ?boolean,
  +account: ?{|
    +id: string,
    +rowId: any,
  |},
  +collaborators: {|
    +totalCount: number,
    +edges: $ReadOnlyArray<{|
      +node: ?{|
        +id: string,
        +rowId: any,
        +name: string,
        +email: string,
        +avatar: ?string,
        +pending: ?boolean,
        +inviteExpired: boolean,
        +teams: {|
          +totalCount: number,
          +nodes: $ReadOnlyArray<?{|
            +name: string,
            +account: ?{|
              +rowId: any
            |},
          |}>,
        |},
        +$fragmentRefs: removeCollaboratorModal_collaborator$ref,
      |}
    |}>,
  |},
  +$fragmentRefs: collaboratorsHeader_organization$ref,
  +$refType: collaborators_organization$ref,
|};
export type collaborators_organization$data = collaborators_organization;
export type collaborators_organization$key = {
  +$data?: collaborators_organization$data,
  +$fragmentRefs: collaborators_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "teamFilter",
      "type": "TeamFilter"
    },
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "collaboratorFilter",
      "type": "UserFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "collaborators"
        ]
      }
    ]
  },
  "name": "collaborators_organization",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "collaborators",
      "args": null,
      "concreteType": "UsersConnection",
      "kind": "LinkedField",
      "name": "__Collaborators_collaborators_connection",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UsersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "avatar",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "pending",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "inviteExpired",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "filter",
                      "variableName": "teamFilter"
                    }
                  ],
                  "concreteType": "TeamsConnection",
                  "kind": "LinkedField",
                  "name": "teams",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Team",
                      "kind": "LinkedField",
                      "name": "nodes",
                      "plural": true,
                      "selections": [
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Account",
                          "kind": "LinkedField",
                          "name": "account",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "teamFilter",
                      "variableName": "teamFilter"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "removeCollaboratorModal_collaborator"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "collaboratorsHeader_organization"
    }
  ],
  "type": "Organization"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd42d9e6c106b4de9340f4ff284d67a2b';

module.exports = node;
