import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Modal,
  ModalActions,
  ModalContent,
  Pushbutton,
  Prose
} from '@stratumn/atomic';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { withGqlClient } from 'wrappers';

import { removeTeamMember } from 'mutations';
import to from 'await-to-js';
import { SpanType, withSpanAsync } from 'tracing';

export const RemoveTeamMemberModal = props => {
  const [submitted, setSubmit] = useState(false);

  const { environment, member, toggleModal } = props;
  const { teams: { nodes: [team] } } = member;

  const removeMemberGp = () => {
    withSpanAsync(`removeTeamMember`, SpanType.processing, async () => {
      setSubmit(true);

      const [err] = await to(
        removeTeamMember(
          environment,
          member.id,
          member.rowId,
          team.id,
          team.rowId,
          team.organization.rowId
        )
      );

      if (err) {
        setSubmit(false);
      } else {
        toggleModal();
      }

      return {};
    });
  };

  return (
    <Modal
      title="Remove from Team"
      handleCollapse={toggleModal}
      closeButtonLabel="Cancel"
    >
      <ModalContent>
        <Prose
          light
          text={`This action will remove **${member.name}** from the **${
            team.name
          }**`}
        />
      </ModalContent>
      <ModalActions>
        <Pushbutton onClick={toggleModal}>cancel</Pushbutton>
        <Pushbutton warning onClick={removeMemberGp} disabled={submitted}>
          Remove from team
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

RemoveTeamMemberModal.propTypes = {
  environment: PropType.object.isRequired,
  member: PropType.object.isRequired,
  toggleModal: PropType.func.isRequired
};

export default createFragmentContainer(withGqlClient(RemoveTeamMemberModal), {
  member: graphql`
    fragment removeTeamMemberModal_member on User
      @argumentDefinitions(
        teamFilter: { type: "TeamFilter", defaultValue: {} }
      ) {
      name
      id
      rowId
      teams(filter: $teamFilter) {
        nodes {
          id
          rowId
          name
          organization {
            rowId
          }
        }
      }
    }
  `
});
