export default theme => ({
  container: {
    textAlign: 'center',
    marginTop: 100,
    fontSize: 30,
    letterSpacing: -1.5,
    fontWeight: 200,
    color: theme.grey1
  }
});
