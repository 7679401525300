/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Provider = "CNP" | "COGNITO" | "GOOGLE" | "KEYCLOAK" | "MICROSOFT_SIA_PARTNERS" | "ONE_ACCOUNT" | "ONE_ACCOUNT_AXA" | "PASS_AXA" | "STRATUMN" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type inviteCollaboratorsModal_team$ref: FragmentReference;
declare export opaque type inviteCollaboratorsModal_team$fragmentType: inviteCollaboratorsModal_team$ref;
export type inviteCollaboratorsModal_team = {|
  +rowId: any,
  +name: string,
  +account: ?{|
    +id: string
  |},
  +organization: ?{|
    +organizationCollaboratorsLicensesLeft: ?number,
    +providers: ?$ReadOnlyArray<?Provider>,
  |},
  +$refType: inviteCollaboratorsModal_team$ref,
|};
export type inviteCollaboratorsModal_team$data = inviteCollaboratorsModal_team;
export type inviteCollaboratorsModal_team$key = {
  +$data?: inviteCollaboratorsModal_team$data,
  +$fragmentRefs: inviteCollaboratorsModal_team$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamAccountRowId",
      "type": "BigInt!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "inviteCollaboratorsModal_team",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "organizationCollaboratorsLicensesLeft",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "providers",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Team"
};
// prettier-ignore
(node/*: any*/).hash = 'c8038a1d8391c92cf1badfbf42d93ba5';

module.exports = node;
