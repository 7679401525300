/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Provider = "CNP" | "COGNITO" | "GOOGLE" | "KEYCLOAK" | "MICROSOFT_SIA_PARTNERS" | "ONE_ACCOUNT" | "ONE_ACCOUNT_AXA" | "PASS_AXA" | "STRATUMN" | "%future added value";
export type UpdateOrganizationByRowIdInput = {|
  clientMutationId?: ?string,
  patch: OrganizationPatch,
  rowId: any,
|};
export type OrganizationPatch = {|
  name?: ?string,
  description?: ?string,
  avatar?: ?string,
  domainName?: ?string,
  providers?: ?$ReadOnlyArray<?Provider>,
|};
export type updateOrganizationMutationVariables = {|
  input: UpdateOrganizationByRowIdInput
|};
export type updateOrganizationMutationResponse = {|
  +updateOrganizationByRowId: ?{|
    +organization: ?{|
      +id: string,
      +rowId: any,
      +name: string,
      +avatar: ?string,
    |}
  |}
|};
export type updateOrganizationMutation = {|
  variables: updateOrganizationMutationVariables,
  response: updateOrganizationMutationResponse,
|};
*/


/*
mutation updateOrganizationMutation(
  $input: UpdateOrganizationByRowIdInput!
) {
  updateOrganizationByRowId(input: $input) {
    organization {
      id
      rowId
      name
      avatar
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateOrganizationByRowIdInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateOrganizationPayload",
    "kind": "LinkedField",
    "name": "updateOrganizationByRowId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateOrganizationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateOrganizationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateOrganizationMutation",
    "operationKind": "mutation",
    "text": "mutation updateOrganizationMutation(\n  $input: UpdateOrganizationByRowIdInput!\n) {\n  updateOrganizationByRowId(input: $input) {\n    organization {\n      id\n      rowId\n      name\n      avatar\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0e084ef909ab541131b0884ec1e06b9c';

module.exports = node;
