/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTeamByRowIdInput = {|
  clientMutationId?: ?string,
  patch: TeamPatch,
  rowId: any,
|};
export type TeamPatch = {|
  name?: ?string,
  description?: ?string,
  avatar?: ?string,
  organizationId?: ?any,
  createdAt?: ?any,
  updatedAt?: ?any,
  email?: ?string,
|};
export type updateTeamMutationVariables = {|
  input: UpdateTeamByRowIdInput
|};
export type updateTeamMutationResponse = {|
  +updateTeamByRowId: ?{|
    +team: ?{|
      +id: string,
      +email: ?string,
      +rowId: any,
      +name: string,
      +avatar: ?string,
    |}
  |}
|};
export type updateTeamMutation = {|
  variables: updateTeamMutationVariables,
  response: updateTeamMutationResponse,
|};
*/


/*
mutation updateTeamMutation(
  $input: UpdateTeamByRowIdInput!
) {
  updateTeamByRowId(input: $input) {
    team {
      id
      email
      rowId
      name
      avatar
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateTeamByRowIdInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateTeamPayload",
    "kind": "LinkedField",
    "name": "updateTeamByRowId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateTeamMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateTeamMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateTeamMutation",
    "operationKind": "mutation",
    "text": "mutation updateTeamMutation(\n  $input: UpdateTeamByRowIdInput!\n) {\n  updateTeamByRowId(input: $input) {\n    team {\n      id\n      email\n      rowId\n      name\n      avatar\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '90be829310a68815c36d576f98f15b99';

module.exports = node;
