export const KEY_SIGN = 'SIGN';
export const KEY_ENCRYPT = 'ENCRYPT';

// these are the role types for members/ collaborators (used for instance in the addOrganizationMembers mutation)
export const ROLE_ADMIN = 'ADMIN';
export const ROLE_READER = 'READER';

// these are the admin types for members/ collaborators (used for instance in the editAccountMembership mutations)
export const ADMIN_REVOKE = 'REVOKE';
export const ADMIN_GRANT = 'GRANT';

// these are the leader types for members/ collaborators (used for instance in the editAccountMembership mutations)
export const LEADER_REVOKE = 'REVOKE';
export const LEADER_GRANT = 'GRANT';

// Pagination types
export const TEAMS_PER_PAGE = 20;
export const TEAMS_ORDER_BY = 'NAME_ASC';

// Relay Fragment types
export const COLLABORATOR_KEY = 'Collaborators_collaborators';
export const MEMBER_KEY = 'People_members';
export const TEAM_MEMBER_KEY = 'Team_members';

// Auth providers
export const AUTH_PROVIDER_STRATUMN = 'STRATUMN';
export const AUTH_PROVIDER_ONE_ACCOUNT_AXA = 'ONE_ACCOUNT_AXA';
export const AUTH_PROVIDER_KEYCLOAK = 'KEYCLOAK';
export const AUTH_PROVIDER_MICROSOFT_SIA = 'MICROSOFT_SIA_PARTNERS';

// keys
// load them by creation date ascending
export const KEYS_ORDER_BY = 'CREATED_AT_ASC';
