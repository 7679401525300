import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import PropType from 'prop-types';
import { createFragmentContainer } from 'react-relay';

import to from 'await-to-js';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';
import { validate as isValidEmail } from 'isemail';

import { addTeamMembers } from 'mutations';

import {
  Modal,
  ModalContent,
  ModalActions,
  Pushbutton,
  Prose,
  Check
} from '@stratumn/atomic';
import * as Sentry from '@sentry/react';
import { EmailChip, TableRow } from 'components/ui';
import { SpanType, withSpanAsync } from 'tracing';
import { withGqlClient, withUser } from 'wrappers';

import { ROLE_READER } from 'constant/types';

import styles from './inviteCollaboratorsModal.style';

/**
 * Utils
 */

const isInList = (email, collaborators) =>
  collaborators.find(c => c.email === email) !== undefined;

const isValid = (email, collaborators) =>
  isValidEmail(email) && !isInList(email, collaborators);

const isDisabled = (value, collaboratorsLength, submitted) =>
  (!isValidEmail(value) && collaboratorsLength === 0) || submitted;

/**
 * Component
 */

export function InviteCollaboratorsModal({
  classes,
  environment,
  team,
  team: { organization },
  onClose
}) {
  const [submitted, setSubmit] = useState(false);
  const [value, setValue] = useState('');
  const [selectedProviders, setProviders] = useState({});
  const [collaborators, setCollaborators] = useState([]);

  const handleKeyDown = event => {
    if (['Enter', 'Tab', ' ', ','].includes(event.key)) {
      event.preventDefault();

      const email = value.trim().toLowerCase();

      if (value && isValid(email, collaborators)) {
        collaborators.push({
          email: email,
          role: ROLE_READER
        });
        setValue('');
      }
    }
    if (['Backspace'].includes(event.key) && collaborators.length > 0) {
      event.preventDefault();
      if (!value) {
        setCollaborators(collaborators.slice(0, -1));
      }
      setValue(value.slice(0, -1));
    }
  };

  const handlePaste = event => {
    event.preventDefault();

    const paste = event.clipboardData.getData('text');
    const emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (emails) {
      const toBeAdded = emails.filter(email => !isInList(email, collaborators));

      const newCollaborators = [...collaborators];

      for (let i = 0; i < toBeAdded.length; i += 1) {
        newCollaborators.push({
          email: toBeAdded[i],
          role: ROLE_READER
        });
      }

      setCollaborators(newCollaborators);
    }
  };

  const handleDelete = emailToRemove => {
    setCollaborators(
      collaborators.filter(collaborator => collaborator.email !== emailToRemove)
    );
  };

  const submitNewPerson = () =>
    withSpanAsync('addTeamMembers', SpanType.processing, async () => {
      setSubmit(true);

      if (isValid(value, collaborators))
        collaborators.push({
          email: value,
          role: ROLE_READER
        });

      const providerList = Object.keys(selectedProviders).filter(
        p => selectedProviders[p]
      );
      const collaboratorsWithProviders = providerList.length
        ? collaborators.map(mem => ({
            ...mem,
            allowedProviders: providerList
          }))
        : collaborators;

      const [err] = await to(
        addTeamMembers(
          environment,
          team.account.id,
          team.rowId,
          collaboratorsWithProviders
        )
      );

      if (err) {
        Sentry.captureException(err);
        setSubmit(false);
      } else {
        onClose();
      }

      return {};
    });

  const toggleSelected = provider => {
    const selected = { ...selectedProviders }; // pass a copy not a reference
    if (selected[provider]) {
      delete selected[provider];
    } else {
      selected[provider] = true;
    }
    setProviders(selected);
  };

  const renderProviderRow = provider => (
    <TableRow key={provider}>
      {provider}
      <Check
        label={provider}
        handleChange={() => toggleSelected(provider)}
        checked={selectedProviders[provider]}
      />
    </TableRow>
  );

  const renderModalContent = () => {
    if (organization.organizationCollaboratorsLicensesLeft === 0) {
      return (
        <Prose
          light
          text="You have **0 Seats remaining**. Update your plan or remove a member first."
        />
      );
    }
    return (
      <>
        <Prose
          light
          text={`Invite people to the **${
            team.name
          }** Team as Collaborators. You have **${
            organization.organizationCollaboratorsLicensesLeft === null
              ? 'no Seats limit**'
              : 'Seats remaining**'
          }`}
        />
        <div className={classes.inputContainer}>
          <div className={classes.root}>
            <div className={classes.emailTagContainer}>
              <ul className={classes.emailTagWrapper}>
                {collaborators.map(collaborator => (
                  <EmailChip
                    key={collaborator.email}
                    email={collaborator.email}
                    handleDelete={handleDelete}
                  />
                ))}
              </ul>
            </div>

            <input
              className={classes.inputField}
              placeholder="Type Email address and press Enter"
              type="text"
              label="Email address"
              value={value}
              onChange={e => setValue(e.target.value)}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
            />
          </div>
        </div>
        {organization.providers && (
          <div>
            <div className={classes.title}>
              Select a list of authentication providers that your users can
              signup with
            </div>
            <ul className={classes.providerList}>
              {organization.providers.map(renderProviderRow)}
            </ul>
          </div>
        )}
      </>
    );
  };

  return (
    <Modal
      title="Invite Collaborators"
      handleCollapse={onClose}
      closeButtonLabel="Cancel"
    >
      <ModalContent>{renderModalContent()}</ModalContent>
      <ModalActions>
        <Pushbutton onClick={onClose}>Cancel</Pushbutton>
        <Pushbutton
          primary
          onClick={submitNewPerson}
          disabled={isDisabled(value, collaborators.length, submitted)}
        >
          Invite Collaborators
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
}

InviteCollaboratorsModal.propTypes = {
  classes: PropType.object.isRequired,
  environment: PropType.object.isRequired,
  team: PropType.object.isRequired,
  onClose: PropType.func.isRequired
};

export default createFragmentContainer(
  compose(withUser, withGqlClient, injectSheet(styles))(
    InviteCollaboratorsModal
  ),
  {
    team: graphql`
      fragment inviteCollaboratorsModal_team on Team
        @argumentDefinitions(teamAccountRowId: { type: "BigInt!" }) {
        rowId
        name
        account {
          id
        }
        organization {
          organizationCollaboratorsLicensesLeft
          providers
        }
      }
    `
  }
);
