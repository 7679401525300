import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { AvatarGroup, DirectoryRow, Pushtext } from '@stratumn/atomic';

import nameToInitials from 'utils/nameToInitials';

import pluralize from 'utils/pluralize';

import styles from './teamRow.style';

export function TeamRow(props) {
  const {
    classes,
    team: {
      avatar,
      account: { members: { totalCount } },
      name,
      rowId,
      bots: { totalCount: botsCount }
    },
    teamsTotalCount,
    index,
    onClick
  } = props;

  const renderMain = (
    <Pushtext onClick={() => onClick(rowId)}>
      <div className={classes.link}>
        <AvatarGroup
          initials={nameToInitials(name)}
          nth={Number(rowId)}
          size={37}
          src={avatar}
        />
        <span className={classes.teamName}>{name}</span>
      </div>
    </Pushtext>
  );

  return (
    <DirectoryRow
      isLast={index === teamsTotalCount}
      main={renderMain}
      cell1={<div>{pluralize('Member', totalCount)}</div>}
      cell2={botsCount ? <div>{pluralize('Bot', botsCount)}</div> : null}
    />
  );
}

TeamRow.propTypes = {
  classes: PropType.object.isRequired,
  team: PropType.object.isRequired,
  teamsTotalCount: PropType.number.isRequired,
  index: PropType.number.isRequired,
  onClick: PropType.func.isRequired
};

export default createFragmentContainer(injectSheet(styles)(TeamRow), {
  team: graphql`
    fragment teamRow_team on Team {
      id
      rowId
      name
      avatar
      account {
        members {
          totalCount
        }
      }
      bots {
        totalCount
      }
    }
  `
});
