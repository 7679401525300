import React, { useState } from 'react';
import PropType from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import to from 'await-to-js';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';

import { updateUser } from 'mutations';

import { FieldTextCompact, Pushbutton } from '@stratumn/atomic';

import { UploadPhoto } from 'components/ui';
import { SpanType, withSpanAsync } from 'tracing';
import { withGqlClient, withRestClient, withUser } from 'wrappers';

import { ROUTE_MY_PROFILE } from 'constant/routes';

import styles from './editProfile.style';

export function EditProfile(props) {
  const { classes, environment, client, history, user } = props;

  const [submitted, setSubmit] = useState(false);
  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [phone, setPhone] = useState(user.phone || '');
  const [avatar, setAvatar] = useState(user.avatar || '');

  const isValid = () => {
    const regex = /^[0-9+()\-. ]{0,20}$/;
    return !submitted && !!firstName && !!lastName && phone.match(regex);
  };

  const onSubmit = () => {
    withSpanAsync('updateUserInSignup', SpanType.processing, async () => {
      setSubmit(true);

      let avatarUrl;

      if (avatar instanceof File) {
        const { digest } = await client.media.uploadFile(avatar, {
          disableEncryption: true
        });
        avatarUrl = await client.media.buildDownloadURL(digest);
      }

      const [err] = await to(
        updateUser(
          environment,
          user.rowId,
          firstName,
          lastName,
          phone,
          avatarUrl
        )
      );

      if (err) {
        Sentry.captureException(err);
        setSubmit(false);
      } else {
        history.push(ROUTE_MY_PROFILE);
      }
    });
  };

  return (
    <div className={classes.formWrapper}>
      <div className={classes.avatarWrapper}>
        <UploadPhoto
          size={56}
          avatar={JSON.stringify(avatar)}
          getNewAvatar={setAvatar}
          user
          overlay
        />
      </div>

      <div className={classes.fieldWrapper}>
        <FieldTextCompact
          label="First name"
          value={firstName}
          onValueChange={e => setFirstName(e.target.value)}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <FieldTextCompact
          label="Last name"
          value={lastName}
          onValueChange={e => setLastName(e.target.value)}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <FieldTextCompact
          label="Phone"
          value={phone}
          onValueChange={e => setPhone(e.target.value)}
        />
      </div>
      <div className={classes.formBtnWrapper}>
        <div className={classes.formBtnLeft}>
          <Pushbutton onClick={() => history.push(ROUTE_MY_PROFILE)}>
            cancel
          </Pushbutton>
        </div>
        <Pushbutton
          primary
          disabled={!isValid() || submitted}
          onClick={() => onSubmit()}
        >
          save
        </Pushbutton>
      </div>
    </div>
  );
}

EditProfile.propTypes = {
  classes: PropType.object.isRequired,
  environment: PropType.object.isRequired,
  client: PropType.object.isRequired,
  history: PropType.object.isRequired,
  user: PropType.object.isRequired
};

export default compose(
  withUser,
  withRouter,
  withGqlClient,
  withRestClient,
  injectSheet(styles)
)(EditProfile);
