import React from 'react';
import PropType from 'prop-types';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { withGqlClient } from 'wrappers';

import AddTeamBotsModal from './addTeamBotsModal';

const query = graphql`
  query addTeamBotsModalQuery($teamId: ID!, $teamAccountRowId: BigInt!) {
    team: team(id: $teamId) {
      ...addTeamBotsModal_team @arguments(teamAccountRowId: $teamAccountRowId)
    }
  }
`;

export const Renderer = ({
  environment,
  teamId,
  teamAccountRowId,
  onClose
}) => (
  <QueryRenderer
    environment={environment}
    query={query}
    variables={{
      teamId,
      teamAccountRowId
    }}
    render={({ err, props: data }) => {
      if (err) return 'error';
      if (!data) return null;
      const { team } = data;
      return <AddTeamBotsModal team={team} onClose={onClose} />;
    }}
  />
);

Renderer.propTypes = {
  environment: PropType.object.isRequired,
  teamId: PropType.string.isRequired,
  teamAccountRowId: PropType.string.isRequired,
  onClose: PropType.func.isRequired
};

export default withGqlClient(Renderer);
