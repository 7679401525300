import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import PropType from 'prop-types';
import { createFragmentContainer } from 'react-relay';
import { withRouter, NavLink, generatePath } from 'react-router-dom';
import classNames from 'classnames';
import to from 'await-to-js';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';
import * as Sentry from '@sentry/react';
import { updateOrganization } from 'mutations';
import nameToInitials from 'utils/nameToInitials';

import { AvatarGroup, Tally } from '@stratumn/atomic';

import { EditableText, UploadPhoto } from 'components/ui';
import { SpanType, withSpanAsync } from 'tracing';
import { withGqlClient, withRestClient } from 'wrappers';
import {
  ROUTE_MEMBERS,
  ROUTE_TEAMS,
  ROUTE_COLLABORATORS,
  ROUTE_BOTS
} from 'constant/routes';
import { toast } from 'sonner/dist';
import { ChangeOrganization } from '../changeOrganization';
import styles from './organization.style';

export function Organization({
  classes,
  client,
  environment,
  organization,
  theme
}) {
  const {
    account: { members },
    avatar,
    canUpdate,
    collaborators,
    name,
    teams,
    rowId,
    bots
  } = organization;
  const [orgName, setOrgName] = useState(name || '');

  const editOrganization = (newAvatar, newName) =>
    withSpanAsync('updateOrganization', SpanType.processing, async () => {
      let newAvatarUrl;

      if (newAvatar instanceof File) {
        const { digest } = await client.media.uploadFile(newAvatar, {
          disableEncryption: true
        });
        newAvatarUrl = await client.media.buildDownloadURL(digest);
      }

      const [err] = await to(
        updateOrganization(environment, rowId, newName, newAvatarUrl)
      );

      if (err) {
        Sentry.captureException(err);
      } else if (newAvatarUrl) {
        toast.success(`Saved new Organization's avatar`);
      } else {
        toast.success(`Saved new Organization's name ${newName.toUpperCase()}`);
      }
    });

  const editInput = input => {
    if (input !== orgName) {
      setOrgName(input);
      editOrganization(null, input);
    }
  };

  /**
   * NavLink activeClassName from react-router-dom overides className from react-jss.
   * Therefore, we have to use inline style to make sure our color is appropriately adjusted when the sidenav link is active.
   */
  const sidenavLinkStyle = {
    fontWeight: 700,
    color: theme.grey1
  };

  return (
    <>
      <div className={classes.orgTitle}>
        {canUpdate ? (
          <UploadPhoto
            size={40}
            name={name}
            avatar={avatar}
            getNewAvatar={editOrganization}
            overlayOnHover
          />
        ) : (
          <AvatarGroup
            onClick={editOrganization}
            size={40}
            initials={nameToInitials(name)}
            src={avatar}
          />
        )}
        {canUpdate ? (
          <div className={classNames(classes.orgName, classes.canUpdateInput)}>
            <EditableText
              input={orgName}
              placeholder="Organization's name"
              getInput={editInput}
            />
          </div>
        ) : (
          <div className={classes.orgName}>{name}</div>
        )}
        <ChangeOrganization />
      </div>
      <nav className={classes.subHeader}>
        <NavLink
          to={`${generatePath(ROUTE_MEMBERS, { orgId: rowId })}`}
          activeStyle={sidenavLinkStyle}
          className={classes.sidenavLink}
          strict
          draggable="false"
        >
          <p>Members</p>
          <span className={classes.tally}>
            <Tally value={members.totalCount} />
          </span>
        </NavLink>
        <NavLink
          to={`${generatePath(ROUTE_BOTS, { orgId: rowId })}`}
          activeStyle={sidenavLinkStyle}
          className={classes.sidenavLink}
          strict
          draggable="false"
        >
          <p>Bots</p>
          <span className={classes.tally}>
            <Tally value={bots.totalCount} />
          </span>
        </NavLink>
        <NavLink
          to={`${generatePath(ROUTE_TEAMS, { orgId: rowId })}`}
          activeStyle={sidenavLinkStyle}
          className={classes.sidenavLink}
          strict
          draggable="false"
        >
          <p>Teams</p>
          <span className={classes.tally}>
            <Tally value={teams.totalCount} />
          </span>
        </NavLink>
        <NavLink
          to={generatePath(ROUTE_COLLABORATORS, { orgId: rowId })}
          activeStyle={sidenavLinkStyle}
          className={classes.sidenavLink}
          strict
          draggable="false"
        >
          <p>Collaborators</p>
          <span className={classes.tally}>
            <Tally value={collaborators.totalCount} />
          </span>
        </NavLink>
      </nav>
    </>
  );
}

Organization.propTypes = {
  classes: PropType.object.isRequired,
  environment: PropType.object.isRequired,
  history: PropType.object.isRequired,
  organization: PropType.object.isRequired
};

export default createFragmentContainer(
  compose(withGqlClient, withRestClient, withRouter, injectSheet(styles))(
    Organization
  ),
  {
    organization: graphql`
      fragment organization_organization on Organization {
        rowId
        name
        avatar
        canUpdate
        isLeader
        account {
          members {
            totalCount
          }
        }
        collaborators {
          totalCount
        }
        teams {
          totalCount
        }
        bots {
          totalCount
        }
      }
    `
  }
);
