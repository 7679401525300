import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import styles from './field.style';

export class Field extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired,
    label: PropType.string.isRequired,
    value: PropType.oneOfType([PropType.string, PropType.number]),
    className: PropType.string
  };

  static defaultProps = {
    value: undefined,
    className: ''
  };

  render() {
    const { className, classes, label, value } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.label}>{label}</div>
        <div
          className={classNames(
            classes.value,
            { [classes.blank]: !value },
            className
          )}
        >
          {value || 'Not added'}
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(Field);
