import { commitMutation } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation addTeamBotsMutation(
    $input: AddBotsToTeamInput!
    $teamRowId: BigInt!
  ) {
    addBotsToTeam(input: $input) {
      query {
        teamByRowId(rowId: $teamRowId) {
          bots {
            totalCount
          }
        }
      }
      bots {
        rowId
        name
        avatar
      }
    }
  }
`;

export default (environment, teamId, teamRowId, botsRowIds) =>
  withSpanAsync('addBotsToTeam', SpanType.mutation, async () => {
    const variables = {
      input: {
        teamRowId,
        botsRowIds
      },
      teamRowId
    };

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);

          resolve();
        },
        onError: err => {
          console.error(err);
          reject(err);
        },
        updater: store => {
          const payload = store.getRootField('addBotsToTeam');

          const newBots = payload.getLinkedRecords('bots');
          const newBotsTotalCount = payload
            .getLinkedRecord('query')
            .getLinkedRecord('teamByRowId', { rowId: teamRowId })
            .getLinkedRecord('bots')
            .getValue('totalCount');

          const team = store.get(teamId);
          const oldBots = team.getLinkedRecord('bots');

          /* 
          We don't need to pass in the filters to getConnection.
          Since, we're using filters, we now need to fetch the 'edges', not the 'nodes'
        */
          const botsConnection = ConnectionHandler.getConnection(
            team,
            'Team_bots'
          );

          /* 
          We loop through the bots' array and create a new edge for each of them.
        */
          newBots.forEach(bot => {
            const edge = ConnectionHandler.createEdge(
              store,
              botsConnection,
              bot,
              'BotsEdge' // type
            );

            ConnectionHandler.insertEdgeAfter(botsConnection, edge);
          });

          // Update the old Team bots count
          oldBots.setValue(newBotsTotalCount, 'totalCount');
          botsConnection.setValue(newBotsTotalCount, 'totalCount');
        }
      });
    });
  });
