export default (name, count) => {
  switch (count) {
    case 0:
      return `No ${name}s`;
    case 1:
      return `1 ${name}`;
    default:
      return `${count} ${name}s`;
  }
};
