import React from 'react';
import PropType from 'prop-types';
import compose from 'lodash.flowright';

import { Button } from 'components/ui';
import { withStepperContext } from 'components/ui/stepper';
import { toast } from 'sonner/dist';

export class NextStep extends React.PureComponent {
  static propTypes = {
    nextStep: PropType.func.isRequired,
    isValid: PropType.bool.isRequired,
    stepState: PropType.oneOfType([PropType.func, PropType.object]).isRequired,
    nextStepName: PropType.string.isRequired
  };

  goNext = async () => {
    const { nextStep, stepState, nextStepName } = this.props;

    try {
      let result = stepState;
      if (stepState instanceof Function) result = await stepState();
      nextStep(nextStepName, result);
    } catch (error) {
      toast.error(error.message);
    }
  };

  render() {
    const {
      nextStep,
      prevStep,
      isValid,
      stepState,
      nextStepName,
      prevSteps,
      activeStep,
      ...props
    } = this.props;
    return (
      <Button onClick={this.goNext} disabled={!isValid} {...props}>
        Next
      </Button>
    );
  }
}

export default compose(withStepperContext)(NextStep);
