/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type bot_bot$ref = any;
export type KeysOrderBy = "ACCOUNT_ID_ASC" | "ACCOUNT_ID_DESC" | "CREATED_AT_ASC" | "CREATED_AT_DESC" | "DEPRECATED_ASC" | "DEPRECATED_AT_ASC" | "DEPRECATED_AT_DESC" | "DEPRECATED_DESC" | "ID_ASC" | "ID_DESC" | "NATURAL" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "PUBLIC_KEY_ASC" | "PUBLIC_KEY_DESC" | "UPDATED_AT_ASC" | "UPDATED_AT_DESC" | "USAGE_ASC" | "USAGE_DESC" | "%future added value";
export type botRootQueryVariables = {|
  botRowId: any,
  orderKeysBy?: ?$ReadOnlyArray<KeysOrderBy>,
|};
export type botRootQueryResponse = {|
  +botByRowId: ?{|
    +$fragmentRefs: bot_bot$ref
  |}
|};
export type botRootQuery = {|
  variables: botRootQueryVariables,
  response: botRootQueryResponse,
|};
*/


/*
query botRootQuery(
  $botRowId: BigInt!
  $orderKeysBy: [KeysOrderBy!]
) {
  botByRowId(rowId: $botRowId) {
    ...bot_bot
    id
  }
}

fragment bot_bot on Bot {
  id
  rowId
  name
  description
  avatar
  account {
    keys(orderBy: $orderKeysBy) {
      nodes {
        id
        rowId
        publicKey
        deprecated
        createdAt
        deprecatedAt
      }
    }
    id
  }
  organization {
    id
    rowId
    name
    canUpdate
  }
  teams {
    totalCount
    nodes {
      id
      rowId
      name
      avatar
      canUpdate
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "botRowId",
    "type": "BigInt!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderKeysBy",
    "type": "[KeysOrderBy!]"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "botRowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUpdate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "botRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Bot",
        "kind": "LinkedField",
        "name": "botByRowId",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "bot_bot"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "botRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Bot",
        "kind": "LinkedField",
        "name": "botByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "orderBy",
                    "variableName": "orderKeysBy"
                  }
                ],
                "concreteType": "KeysConnection",
                "kind": "LinkedField",
                "name": "keys",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Key",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "publicKey",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deprecated",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deprecatedAt",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamsConnection",
            "kind": "LinkedField",
            "name": "teams",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "botRootQuery",
    "operationKind": "query",
    "text": "query botRootQuery(\n  $botRowId: BigInt!\n  $orderKeysBy: [KeysOrderBy!]\n) {\n  botByRowId(rowId: $botRowId) {\n    ...bot_bot\n    id\n  }\n}\n\nfragment bot_bot on Bot {\n  id\n  rowId\n  name\n  description\n  avatar\n  account {\n    keys(orderBy: $orderKeysBy) {\n      nodes {\n        id\n        rowId\n        publicKey\n        deprecated\n        createdAt\n        deprecatedAt\n      }\n    }\n    id\n  }\n  organization {\n    id\n    rowId\n    name\n    canUpdate\n  }\n  teams {\n    totalCount\n    nodes {\n      id\n      rowId\n      name\n      avatar\n      canUpdate\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c29a3a5a10e0b33e27fac5de69e56927';

module.exports = node;
