/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type peopleHeader_organization$ref = any;
type people_organization$ref = any;
export type TeamFilter = {|
  rowId?: ?BigIntFilter,
  name?: ?StringFilter,
  description?: ?StringFilter,
  avatar?: ?StringFilter,
  organizationId?: ?BigIntFilter,
  accountId?: ?BigIntFilter,
  createdAt?: ?DatetimeFilter,
  updatedAt?: ?DatetimeFilter,
  email?: ?StringFilter,
  canInsert?: ?BooleanFilter,
  canUpdate?: ?BooleanFilter,
  and?: ?$ReadOnlyArray<TeamFilter>,
  or?: ?$ReadOnlyArray<TeamFilter>,
  not?: ?TeamFilter,
|};
export type BigIntFilter = {|
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
|};
export type StringFilter = {|
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  similarTo?: ?string,
  notSimilarTo?: ?string,
|};
export type DatetimeFilter = {|
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
|};
export type BooleanFilter = {|
  isNull?: ?boolean,
  equalTo?: ?boolean,
  notEqualTo?: ?boolean,
  distinctFrom?: ?boolean,
  notDistinctFrom?: ?boolean,
  in?: ?$ReadOnlyArray<boolean>,
  notIn?: ?$ReadOnlyArray<boolean>,
|};
export type MemberFilter = {|
  userId?: ?BigIntFilter,
  accountId?: ?BigIntFilter,
  reader?: ?BooleanFilter,
  admin?: ?BooleanFilter,
  leader?: ?BooleanFilter,
  collaborator?: ?BooleanFilter,
  email?: ?StringFilter,
  name?: ?StringFilter,
  and?: ?$ReadOnlyArray<MemberFilter>,
  or?: ?$ReadOnlyArray<MemberFilter>,
  not?: ?MemberFilter,
|};
export type peopleRootQueryVariables = {|
  orgRowId: any,
  teamFilter?: ?TeamFilter,
  memberFilter?: ?MemberFilter,
|};
export type peopleRootQueryResponse = {|
  +organizationByRowId: ?{|
    +$fragmentRefs: peopleHeader_organization$ref & people_organization$ref
  |}
|};
export type peopleRootQuery = {|
  variables: peopleRootQueryVariables,
  response: peopleRootQueryResponse,
|};
*/


/*
query peopleRootQuery(
  $orgRowId: BigInt!
  $teamFilter: TeamFilter
  $memberFilter: MemberFilter
) {
  organizationByRowId(rowId: $orgRowId) {
    ...peopleHeader_organization
    ...people_organization_14z3Rv
    id
  }
}

fragment inviteOrgMemberModal_organization on Organization {
  name
  rowId
  organizationMemberLicensesLeft
  providers
  account {
    id
    rowId
  }
}

fragment peopleHeader_organization on Organization {
  ...inviteOrgMemberModal_organization
  canUpdate
  id
  rowId
  organizationOwnerLicensesLeft
  isLeader
  account {
    id
    rowId
    headerMembers: members {
      totalCount
      edges {
        node {
          admin
          leader
          userId
          collaborator
          user {
            id
            rowId
            name
            email
            pending
            inviteExpired
            teams(filter: $teamFilter) {
              totalCount
              nodes {
                name
                id
              }
            }
          }
        }
      }
    }
  }
}

fragment people_organization_14z3Rv on Organization {
  ...peopleHeader_organization
  id
  rowId
  canUpdate
  isLeader
  account {
    id
    rowId
    members(filter: $memberFilter) {
      totalCount
      edges {
        node {
          admin
          leader
          userId
          collaborator
          user {
            id
            rowId
            name
            email
            avatar
            pending
            inviteExpired
            teams(filter: $teamFilter) {
              totalCount
            }
          }
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgRowId",
    "type": "BigInt!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamFilter",
    "type": "TeamFilter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "memberFilter",
    "type": "MemberFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "orgRowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "leader",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collaborator",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pending",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inviteExpired",
  "storageKey": null
},
v13 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "teamFilter"
  }
],
v14 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "memberFilter"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "peopleRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationByRowId",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "peopleHeader_organization"
          },
          {
            "args": [
              {
                "kind": "Variable",
                "name": "memberFilter",
                "variableName": "memberFilter"
              },
              {
                "kind": "Variable",
                "name": "teamFilter",
                "variableName": "teamFilter"
              }
            ],
            "kind": "FragmentSpread",
            "name": "people_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "peopleRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organizationMemberLicensesLeft",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "providers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              {
                "alias": "headerMembers",
                "args": null,
                "concreteType": "MembersConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MembersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Member",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v3/*: any*/),
                              (v2/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": (v13/*: any*/),
                                "concreteType": "TeamsConnection",
                                "kind": "LinkedField",
                                "name": "teams",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Team",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v14/*: any*/),
                "concreteType": "MembersConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MembersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Member",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v3/*: any*/),
                              (v2/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatar",
                                "storageKey": null
                              },
                              (v11/*: any*/),
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": (v13/*: any*/),
                                "concreteType": "TeamsConnection",
                                "kind": "LinkedField",
                                "name": "teams",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v14/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "People_members",
                "kind": "LinkedHandle",
                "name": "members"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUpdate",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organizationOwnerLicensesLeft",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLeader",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "peopleRootQuery",
    "operationKind": "query",
    "text": "query peopleRootQuery(\n  $orgRowId: BigInt!\n  $teamFilter: TeamFilter\n  $memberFilter: MemberFilter\n) {\n  organizationByRowId(rowId: $orgRowId) {\n    ...peopleHeader_organization\n    ...people_organization_14z3Rv\n    id\n  }\n}\n\nfragment inviteOrgMemberModal_organization on Organization {\n  name\n  rowId\n  organizationMemberLicensesLeft\n  providers\n  account {\n    id\n    rowId\n  }\n}\n\nfragment peopleHeader_organization on Organization {\n  ...inviteOrgMemberModal_organization\n  canUpdate\n  id\n  rowId\n  organizationOwnerLicensesLeft\n  isLeader\n  account {\n    id\n    rowId\n    headerMembers: members {\n      totalCount\n      edges {\n        node {\n          admin\n          leader\n          userId\n          collaborator\n          user {\n            id\n            rowId\n            name\n            email\n            pending\n            inviteExpired\n            teams(filter: $teamFilter) {\n              totalCount\n              nodes {\n                name\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment people_organization_14z3Rv on Organization {\n  ...peopleHeader_organization\n  id\n  rowId\n  canUpdate\n  isLeader\n  account {\n    id\n    rowId\n    members(filter: $memberFilter) {\n      totalCount\n      edges {\n        node {\n          admin\n          leader\n          userId\n          collaborator\n          user {\n            id\n            rowId\n            name\n            email\n            avatar\n            pending\n            inviteExpired\n            teams(filter: $teamFilter) {\n              totalCount\n            }\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1b27ec9be8b6828842554377cd2e850e';

module.exports = node;
