export default theme => ({
  emailTag: {
    display: 'inline-flex',
    marginLeft: 7,
    padding: '3px 7px',
    fontSize: 14,
    color: theme.grey2,
    backgroundColor: theme.grey8
  },
  emailTagRemove: {
    paddingLeft: 7,
    '&:hover': {
      cursor: 'pointer'
    }
  }
});
