import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation updateUserMutation($input: UpdateUserByRowIdInput!) {
    updateUserByRowId(input: $input) {
      user {
        id
        rowId
        name
        firstName
        lastName
        phone
        avatar
      }
    }
  }
`;

export default (environment, userRowId, firstName, lastName, phone, avatar) =>
  withSpanAsync('updateUserByRowId', SpanType.mutation, async () => {
    // This request is a patch so we do not want to write over the current value
    // with an empty one
    // Note there's an exception: some users don't want to share their phone number
    const patch = {};
    if (firstName) patch.firstName = firstName;
    if (lastName) patch.lastName = lastName;
    patch.phone = phone;
    if (avatar) patch.avatar = avatar;

    const variables = {
      input: {
        rowId: userRowId,
        patch
      }
    };

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);

          resolve();
        },
        onError: err => {
          const error = new Error(
            `Unable to update user ${firstName} ${lastName}`
          );

          console.error(err);

          reject(error);
        }
      });
    });
  });
