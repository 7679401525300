export default theme => ({
  root: {
    width: '100%',
    fontSize: 14,
    backgroundColor: theme.greyBackground1,
    border: `1px solid ${theme.greyBorder2}`,
    borderRadius: 2,
    padding: 8,
    color: theme.grey2,
    '&:not(:last-child)': {
      marginBottom: 15
    }
  },
  rootDynamic: props => ({
    cursor: !!props.onClick && 'pointer'
  }),
  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed'
  },
  title: {
    color: theme.grey1,
    '&:not(:last-child)': {
      marginBottom: 5
    }
  }
});
