import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import PropType from 'prop-types';
import { createFragmentContainer } from 'react-relay';

import to from 'await-to-js';
import compose from 'lodash.flowright';
import { validate as isValidEmail } from 'isemail';
import injectSheet from 'react-jss';
import * as Sentry from '@sentry/react';
import { addOrganizationMembers } from 'mutations';

import {
  Modal,
  ModalContent,
  ModalActions,
  Pushbutton,
  Prose,
  Check
} from '@stratumn/atomic';

import { EmailChip, TableRow } from 'components/ui';
import { SpanType, withSpanAsync } from 'tracing';
import { withGqlClient, withUser } from 'wrappers';

import { ROLE_READER } from 'constant/types';

import styles from './inviteOrgMemberModal.style';

/**
 * Utils
 */

const isInList = (email, members) =>
  members.find(m => m.email === email) !== undefined;

const isValid = (email, members) =>
  isValidEmail(email) && !isInList(email, members);

const isDisabled = (value, membersLength, submitted) =>
  (!isValidEmail(value) && membersLength === 0) || submitted;

/**
 * Component
 */

export function InviteOrgMemberModal({
  classes,
  environment,
  organization,
  toggleModal
}) {
  const [submitted, setSubmit] = useState(false);
  const [value, setValue] = useState('');
  const [selectedProviders, setProviders] = useState({});
  const [members, setMembers] = useState([]);

  const handleKeyDown = event => {
    if (['Enter', 'Tab', ' ', ','].includes(event.key)) {
      event.preventDefault();

      const email = value.trim().toLowerCase();

      if (value && isValid(email, members)) {
        members.push({
          email: email,
          role: ROLE_READER
        });
        setValue('');
      }
    }
    if (['Backspace'].includes(event.key) && members.length > 0) {
      event.preventDefault();
      if (!value) {
        setMembers(members.slice(0, -1));
      }
      setValue(value.slice(0, -1));
    }
  };

  const handlePaste = event => {
    event.preventDefault();

    const paste = event.clipboardData.getData('text');
    const emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (emails) {
      const toBeAdded = emails.filter(email => !isInList(email, members));

      const newMembers = [...members];

      for (let i = 0; i < toBeAdded.length; i += 1) {
        newMembers.push({
          email: toBeAdded[i],
          role: ROLE_READER
        });
      }

      setMembers(newMembers);
    }
  };

  const handleDelete = emailToRemove => {
    setMembers(members.filter(member => member.email !== emailToRemove));
  };

  const submitNewPerson = () =>
    withSpanAsync('addOrganizationMembers', SpanType.processing, async () => {
      setSubmit(true);

      if (isValid(value, members))
        members.push({
          email: value,
          role: ROLE_READER
        });

      const providerList = Object.keys(selectedProviders).filter(
        p => selectedProviders[p]
      );
      const membersWithProviders = providerList.length
        ? members.map(mem => ({
            ...mem,
            allowedProviders: providerList
          }))
        : members;

      const [err] = await to(
        addOrganizationMembers(
          environment,
          organization.account.id,
          organization.rowId,
          membersWithProviders
        )
      );

      if (err) {
        Sentry.captureException(err);
        setSubmit(false);
      } else {
        toggleModal();
      }

      return {};
    });

  const toggleSelected = provider => {
    const selected = { ...selectedProviders }; // pass a copy not a reference
    if (selected[provider]) {
      delete selected[provider];
    } else {
      selected[provider] = true;
    }
    setProviders(selected);
  };

  const getProviderName = provider => {
    switch (provider) {
      case 'ONE_ACCOUNT_AXA':
        return 'One Account AXA';
      case 'STRATUMN':
        return 'Stratumn Account';
      case 'MICROSOFT_SIA_PARTNERS':
        return 'Sia Partners';
      default: {
        const firstLetter = provider[0];
        return (
          firstLetter.toUpperCase() +
          provider
            .slice(1)
            .toLowerCase()
            .replaceAll('_', ' ')
        );
      }
    }
  };

  const renderProviderRow = provider => (
    <TableRow key={provider}>
      {getProviderName(provider)}
      <Check
        label={provider}
        handleChange={() => toggleSelected(provider)}
        checked={selectedProviders[provider]}
      />
    </TableRow>
  );

  const renderModalContent = () => {
    if (organization.organizationMemberLicensesLeft === 0) {
      return (
        <Prose
          light
          text="You have **0 Seats remaining**. Update your plan or remove a member first."
        />
      );
    }
    return (
      <>
        <Prose
          light
          text={`You have **${
            organization.organizationMemberLicensesLeft === null
              ? 'no Seats limit**'
              : 'Seats remaining**'
          }`}
        />
        <div className={classes.inputContainer}>
          <div className={classes.root}>
            <div className={classes.emailTagContainer}>
              <ul className={classes.emailTagWrapper}>
                {members.map(member => (
                  <EmailChip
                    key={member.email}
                    email={member.email}
                    handleDelete={handleDelete}
                  />
                ))}
              </ul>
            </div>

            <input
              className={classes.inputField}
              placeholder="Type Email address"
              type="text"
              label="Email address"
              value={value}
              onChange={e => setValue(e.target.value)}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
            />
          </div>
        </div>
        {organization.providers && (
          <div>
            <div className={classes.title}>
              Select a list of authentication providers that your users can
              signup with
            </div>
            <ul className={classes.providerList}>
              {organization.providers.map(renderProviderRow)}
            </ul>
          </div>
        )}
      </>
    );
  };

  return (
    <Modal
      title="Invite Organization Members"
      handleCollapse={toggleModal}
      closeButtonLabel="Cancel"
    >
      <ModalContent>{renderModalContent()}</ModalContent>
      <ModalActions>
        <Pushbutton onClick={toggleModal}>Cancel</Pushbutton>
        <Pushbutton
          primary
          onClick={submitNewPerson}
          disabled={isDisabled(value, members.length, submitted)}
        >
          Invite Members
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
}

InviteOrgMemberModal.propTypes = {
  classes: PropType.object.isRequired,
  environment: PropType.object.isRequired,
  organization: PropType.object.isRequired,
  toggleModal: PropType.func.isRequired
};

export default createFragmentContainer(
  compose(withUser, withGqlClient, injectSheet(styles))(InviteOrgMemberModal),
  {
    organization: graphql`
      fragment inviteOrgMemberModal_organization on Organization {
        name
        rowId
        organizationMemberLicensesLeft
        providers
        account {
          id
          rowId
        }
      }
    `
  }
);
