import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import { FieldSearchCompact } from '@stratumn/atomic';

import styles from './searchBar.style';

export function SearchBar({ classes, label, children, search, setSearch }) {
  return (
    <div className={classes.root}>
      <FieldSearchCompact
        label={label}
        name="search"
        value={search}
        onValueChange={e => setSearch(e.target.value)}
        maxWidth
      />

      {children}
    </div>
  );
}

SearchBar.propTypes = {
  classes: PropType.object.isRequired,
  label: PropType.string.isRequired,
  children: PropType.node,
  search: PropType.string.isRequired,
  setSearch: PropType.func.isRequired
};

SearchBar.defaultProps = {
  children: null
};

export default injectSheet(styles)(SearchBar);
