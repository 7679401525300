import { commitMutation } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation addTeamMembersMutation(
    $input: AddTeamMembersInput!
    $teamRowId: BigInt!
  ) {
    addTeamMembers(input: $input) {
      query {
        teamByRowId(rowId: $teamRowId) {
          account {
            members {
              totalCount
            }
          }
          organization {
            collaborators {
              totalCount
            }
          }
        }
      }
      members {
        reader
        admin
        collaborator
        userId
        user {
          id
          rowId
          email
          accountId
          name
          avatar
          pending
        }
      }
    }
  }
`;

export default (environment, accountId, teamRowId, members) =>
  withSpanAsync('addTeamMembers', SpanType.mutation, async () => {
    const variables = {
      input: {
        teamRowId,
        members
      },
      teamRowId
    };

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);

          resolve();
        },
        onError: err => {
          console.error(err);

          reject(err);
        },
        updater: store => {
          const payload = store.getRootField('addTeamMembers');

          const newMembers = payload.getLinkedRecords('members');
          const newMembersTotalCount = payload
            .getLinkedRecord('query')
            .getLinkedRecord('teamByRowId', { rowId: teamRowId })
            .getLinkedRecord('account')
            .getLinkedRecord('members')
            .getValue('totalCount');

          const account = store.get(accountId);
          const oldMembers = account.getLinkedRecord('members');

          /* 
          We don't need to pass in the filters to getConnection.
          Since, we're using filters, we now need to fetch the 'edges', not the 'nodes'
        */
          const membersConnection = ConnectionHandler.getConnection(
            account,
            'Team_members'
          );

          /* 
          We loop through the members' array and create a new edge for each of them.
        */
          newMembers.forEach(member => {
            const edge = ConnectionHandler.createEdge(
              store,
              membersConnection,
              member,
              'MembersEdge' // type
            );

            ConnectionHandler.insertEdgeAfter(membersConnection, edge);
          });

          // Update the old Team members count
          oldMembers.setValue(newMembersTotalCount, 'totalCount');
          membersConnection.setValue(newMembersTotalCount, 'totalCount');
        }
      });
    });
  });
