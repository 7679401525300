export default () => ({
  container: {
    lineHeight: '12px',
    '&:focus': {
      marginTop: 5
    },
    '& p': {
      display: 'flex',
      alignItems: 'center',
      maxWidth: 'fit-content',
      color: '#000',
      fontSize: 12,
      fontWeight: 400,
      '& button': {
        lineHeight: '20px',
        marginLeft: 5,
        '& span': {
          display: 'inline-flex',
          marginLeft: 5,
          color: '#5246F7',
          '&:hover': {
            textDecoration: 'underline',
            '& svg': {
              display: 'block'
            }
          }
        },
        '& svg': {
          display: 'none',
          height: 16,
          color: '#5246F7'
        }
      },
      '& span': {
        marginLeft: 5,
        color: '#5246F7'
      }
    }
  },
  button: {
    '&:not([disabled]):hover': {
      color: '#5246F7'
    }
  },
  title: {
    marginBottom: 10
  },
  edit: {
    display: 'flex',
    '& label': {
      marginRight: 5,
      '& div:first-child': {
        display: 'none'
      },
      '& input': {
        height: 36,
        width: 320,
        fontSize: 14,
        fontWeight: 400
      },
      '& svgIcon': {
        width: 28
      }
    }
  },
  addEmail: {
    fontSize: 12,
    color: '#5246F7',
    maxHeight: 12,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      display: 'none',
      width: 26
    },
    '&:hover': {
      textDecoration: 'underline',
      '& svg': {
        display: 'block'
      }
    }
  },
  editIcon: {
    height: 25,
    width: 25
  }
});
