export default theme => ({
  container: {
    padding: '12px 20px',
    height: theme.footerHeight,
    backgroundColor: theme.grey8,
    color: theme.grey2,
    fontSize: 12,
    '& a': {
      textDecoration: 'none',
      color: theme.indigo2,
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
});
