import React from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { ROUTE_MY_PROFILE } from 'constant/routes';

import { Oops } from '@stratumn/atomic';

const ErrorContext = React.createContext({});

export const withSpecificError = Component => props => (
  <ErrorContext.Consumer>
    {({ handleError }) => (
      <Component errorContext={{ handleError: handleError }} {...props} />
    )}
  </ErrorContext.Consumer>
);

export class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    error: null
  };

  componentDidCatch(error, info) {
    this.setState({ error });
    const sentryEventId = Sentry.captureException(error, {
      extra: info
    });
    console.log('sentryEventId :>> ', sentryEventId);
  }

  handleError = error => {
    this.setState({ error });
  };

  goTo = route => {
    this.setState({ error: null });
    this.props.history.push(route);
  };

  renderErrorPage = () => {
    const { error } = this.state;
    return (
      <Oops
        onHomeClick={() => this.goTo(error.path || ROUTE_MY_PROFILE)}
        label={error.label}
        description={error.description}
      />
    );
  };

  render() {
    const { error } = this.state;
    const { children } = this.props;

    return (
      <ErrorContext.Provider value={{ handleError: this.handleError }}>
        {error ? this.renderErrorPage() : children}
      </ErrorContext.Provider>
    );
  }
}

export default compose(withRouter)(ErrorBoundary);
