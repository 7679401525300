import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import { IconButtonClose } from 'components/ui';

import styles from './emailChip.style';

export function EmailChip(props) {
  const { classes, email, handleDelete } = props;
  return (
    <li className={classes.emailTag}>
      {email}
      <span className={classes.emailTagRemove}>
        <IconButtonClose onClick={() => handleDelete(email)} />
      </span>
    </li>
  );
}

EmailChip.propTypes = {
  classes: PropType.object.isRequired,
  email: PropType.string.isRequired,
  handleDelete: PropType.func.isRequired
};

export default injectSheet(styles)(EmailChip);
