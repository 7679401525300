import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Pushbutton, RadioButton } from '@stratumn/atomic';
import { Download } from '@stratumn/icons';
import injectSheet from 'react-jss';

import styles from './typeFileViewsMenu.style';

export const TypeFileViewsMenu = ({ classes, onDownload, onClose }) => {
  const menuRef = useRef(null);
  const [fileType, setFileType] = useState('csv');
  const handleTypeFileChange = event => {
    setFileType(event.target.value);
  };
  const handleClickDownload = () => {
    onClose();
    onDownload(fileType);
  };

  return (
    <>
      <div className={classes.tyleFileViewsMenuUnderlay} onClick={onClose} />
      <div ref={menuRef} className={classes.typeFileViewsMenuContainer}>
        <div className={classes.typeFileViewsMenuRadioButtons}>
          <RadioButton
            dataCy="csv-radio"
            showLabel
            label="CSV (.csv)"
            value="csv"
            checked={fileType === 'csv'}
            handleChange={handleTypeFileChange}
          />
          <RadioButton
            dataCy="excel-radio"
            showLabel
            label="Excel (.xls)"
            value="excel"
            checked={fileType === 'excel'}
            handleChange={handleTypeFileChange}
          />
        </div>
        <div className={classes.typeFileViewsButton}>
          <Pushbutton
            dataCy="download-button"
            secondary
            onClick={handleClickDownload}
          >
            <Download className={classes.typeFileViewsButtonIcon} />
            Download
          </Pushbutton>
        </div>
      </div>
    </>
  );
};

TypeFileViewsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  onDownload: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default injectSheet(styles)(TypeFileViewsMenu);
