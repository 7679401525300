export default theme => ({
  root: {
    '&:not(:first-child)': {
      paddingTop: 14
    },
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.greyBorder1}`,
      paddingBottom: 14
    }
  },
  label: {
    fontSize: 11,
    color: theme.grey1,
    textTransform: 'uppercase',
    letterSpacing: 1,
    paddingBottom: 5,
    fontWeight: 600
  },
  value: {
    color: theme.grey1,
    fontSize: 14
  },
  blank: {
    color: theme.grey1
  }
});
