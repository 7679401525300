import React, { useRef } from 'react';
import PropType from 'prop-types';

export function EditableText({ input, placeholder, getInput }) {
  const [value, setValue] = React.useState(input || '');
  const inputRef = useRef();

  const keypressHandler = event => {
    if (event.key === 'Enter') inputRef.current.blur();
  };

  const handleBlur = () => getInput(value);

  return (
    <input
      type="text"
      placeholder={placeholder}
      ref={inputRef}
      value={value}
      onChange={e => setValue(e.target.value)}
      onKeyPress={keypressHandler}
      onBlur={handleBlur}
    />
  );
}

EditableText.propTypes = {
  input: PropType.string.isRequired,
  placeholder: PropType.string,
  getInput: PropType.func.isRequired
};

EditableText.defaultProps = {
  placeholder: null
};

export default EditableText;
