import { commitMutation } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import graphql from 'babel-plugin-relay/macro';
import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation addBotMutation($input: CreateBotInput!) {
    createBot(input: $input) {
      bot {
        id
        rowId
        name
        avatar
        description
        teams {
          totalCount
        }
      }
    }
  }
`;

export default (
  environment,
  organizationId, // in order to update the store
  organizationRowId,
  name,
  publicKey,
  description,
  avatarUri
) =>
  withSpanAsync('addBots', SpanType.mutation, async () => {
    const variables = {
      input: {
        name,
        publicKey,
        description,
        organizationId: organizationRowId,
        avatar: avatarUri
      }
    };

    const error = new Error(`Unable to create bot ${name}`);

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);
          if (errors && errors.length) {
            reject(error);
          }
          resolve();
        },
        onError: err => {
          console.error(err);
          reject(error);
        },
        updater: store => {
          const payload = store.getRootField('createBot');
          const newBot = payload.getLinkedRecord('bot');

          const organization = store.get(organizationId);
          const bots = organization.getLinkedRecord('bots');
          const totalCount = bots.getValue('totalCount');

          /* 
          We don't need to pass in the filters to getConnection.
          However, because we're using filters, we need to fetch the 'edges', not the 'nodes'
        */
          const botsConnection = ConnectionHandler.getConnection(
            organization,
            'Bots_bots'
          );

          const edge = ConnectionHandler.createEdge(
            store,
            botsConnection,
            newBot,
            'BotsEdge' // type
          );

          ConnectionHandler.insertEdgeAfter(botsConnection, edge);

          // Update the old bots count
          bots.setValue(totalCount + 1, 'totalCount');
          botsConnection.setValue(totalCount + 1, 'totalCount');
        }
      });
    });
  });
