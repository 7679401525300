/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Provider = "CNP" | "COGNITO" | "GOOGLE" | "KEYCLOAK" | "MICROSOFT_SIA_PARTNERS" | "ONE_ACCOUNT" | "ONE_ACCOUNT_AXA" | "PASS_AXA" | "STRATUMN" | "%future added value";
export type UpdateUserByRowIdInput = {|
  clientMutationId?: ?string,
  patch: UserPatch,
  rowId: any,
|};
export type UserPatch = {|
  phone?: ?string,
  avatar?: ?string,
  pending?: ?boolean,
  firstName?: ?string,
  lastName?: ?string,
  allowedProviders?: ?$ReadOnlyArray<?Provider>,
  notificationPreference?: ?any,
|};
export type updateUserMutationVariables = {|
  input: UpdateUserByRowIdInput
|};
export type updateUserMutationResponse = {|
  +updateUserByRowId: ?{|
    +user: ?{|
      +id: string,
      +rowId: any,
      +name: string,
      +firstName: ?string,
      +lastName: ?string,
      +phone: ?string,
      +avatar: ?string,
    |}
  |}
|};
export type updateUserMutation = {|
  variables: updateUserMutationVariables,
  response: updateUserMutationResponse,
|};
*/


/*
mutation updateUserMutation(
  $input: UpdateUserByRowIdInput!
) {
  updateUserByRowId(input: $input) {
    user {
      id
      rowId
      name
      firstName
      lastName
      phone
      avatar
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateUserByRowIdInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserPayload",
    "kind": "LinkedField",
    "name": "updateUserByRowId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "updateUserMutation",
    "operationKind": "mutation",
    "text": "mutation updateUserMutation(\n  $input: UpdateUserByRowIdInput!\n) {\n  updateUserByRowId(input: $input) {\n    user {\n      id\n      rowId\n      name\n      firstName\n      lastName\n      phone\n      avatar\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bfadfab10cbdf8b444690317be17f3a7';

module.exports = node;
