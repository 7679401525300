import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { Pushbutton } from '@stratumn/atomic';

import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';

import { withGqlClient, withUser } from 'wrappers';
import { downloadCsv } from 'utils/downloadCsv';
import { downloadXls } from 'utils/downloadXls';
import TypeFileViewsMenu from 'components/ui/typeFileViewsMenu';
import styles from './collaboratorsHeader.style';

export const CollaboratorsHeader = props => {
  const [isExportOptionShown, setIsExportOptionShown] = useState(false);

  const handleDownload = fileType => {
    const { headerCollaborators } = props.organization;
    const sortedCollaborators = [...headerCollaborators.edges].sort((a, b) =>
      a.node.name.localeCompare(b.node.name)
    );
    const headers = ['Collaborator Name', 'Collaborator Email', 'Team Name'];
    const rows = sortedCollaborators.flatMap(collaborator => {
      const teamNames = collaborator.node.teams.nodes.map(team => team.name);

      if (teamNames.length === 0) {
        // If collaborator has no teams, add an empty line
        return [[collaborator.node.name, collaborator.node.email, '']];
      }
      return teamNames.map(teamName => [
        collaborator.node.name,
        collaborator.node.email,
        teamName
      ]);
    });

    if (fileType === 'csv') {
      downloadCsv(headers, rows, 'members.csv');
    } else if (fileType === 'excel') {
      downloadXls(headers, rows, 'members.xls');
    }
  };

  const toggleShowExportOption = () => {
    setIsExportOptionShown(prev => !prev);
  };
  const { classes } = props;

  return ReactDOM.createPortal(
    <>
      <div className={classes.buttonContainer}>
        <Pushbutton secondary onClick={toggleShowExportOption}>
          Export data
        </Pushbutton>
        {isExportOptionShown && (
          <TypeFileViewsMenu
            onDownload={handleDownload}
            onClose={toggleShowExportOption}
          />
        )}
      </div>
    </>,
    document.getElementById('btn-download-csv')
  );
};

CollaboratorsHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired
};

export default createFragmentContainer(
  compose(withUser, withGqlClient, injectSheet(styles))(CollaboratorsHeader),
  {
    organization: graphql`
      fragment collaboratorsHeader_organization on Organization {
        id
        rowId
        account {
          id
          rowId
        }
        headerCollaborators: collaborators {
          totalCount
          edges {
            node {
              id
              rowId
              name
              email
              pending
              inviteExpired
              teams(filter: $teamFilter) {
                totalCount
                nodes {
                  name
                }
              }
            }
          }
        }
      }
    `
  }
);
