import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import { withNotifications } from 'wrappers/notifications';
import compose from 'lodash.flowright';
import { PageNavigation } from 'components/layout';

import styles from './body.style';

export const Body = ({ classes, notifications, children, user }) => (
  <div className={classes.wrapper}>
    <div className={classes.notificationsContainer}>
      {Object.values(notifications).map((message, i) => (
        <div className={classes.notification} key={i}>
          {message}
        </div>
      ))}
    </div>
    <section className={classes.container}>
      <PageNavigation user={user} />
      <section className={classes.pageContent}>{children}</section>
    </section>
  </div>
);

Body.propTypes = {
  classes: PropType.object.isRequired,
  notifications: PropType.object.isRequired,
  user: PropType.object.isRequired,
  children: PropType.node
};

Body.defaultProps = {
  children: null
};

export default compose(injectSheet(styles), withNotifications)(Body);
