const FileSaver = require('file-saver');

export const downloadCsv = (headers, rows, fileName) => {
  const csvContent = headers
    .join(',')
    .concat('\n')
    .concat(rows.map(row => row.join(',')).join('\n'));

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  FileSaver.saveAs(blob, fileName);
};
