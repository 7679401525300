export default theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: 15
    },
    '&:focus': { outline: 0 }
  },
  radio: {
    height: 16,
    width: 16,
    borderRadius: 8,
    border: `1px solid ${theme.greyBorder2}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    '&:focus': { outline: 0 }
  },
  innerRadio: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: theme.indigo3
  },
  disabled: {
    opacity: 0.5,
    '& > div': {
      cursor: 'not-allowed'
    }
  },
  children: {
    backgroundColor: theme.greyBackground1,
    border: `1px solid ${theme.greyBorder2}`,
    borderRadius: 2,
    padding: 8,
    color: theme.grey2,
    width: '100%'
  },
  title: {
    color: theme.grey1,
    '&:not(:last-child)': {
      marginBottom: 5
    }
  },
  description: {
    fontSize: 12
  },
  selected: {
    border: `1px solid ${theme.indigo3}`
  }
});
