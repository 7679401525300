export default theme => ({
  title: {
    fontSize: 11,
    color: theme.grey2
  },
  avatarTitle: {
    fontSize: 14,
    color: theme.grey2,
    marginBottom: 10
  },
  fieldTitle: {
    fontSize: 16,
    color: theme.grey4
  },
  fieldExplainer: {
    fontSize: 11,
    color: theme.grey2,
    marginTop: 5,
    marginBottom: 20
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: 10,
    '& > *': {
      marginLeft: 10
    }
  },
  fileInput: { display: 'none' }
});
