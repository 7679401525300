import React from 'react';
import PropType from 'prop-types';
import { Popover, PopoverMenuItem, Avatar } from '@stratumn/atomic';
import compose from 'lodash.flowright';
import { withUser } from 'wrappers';

export const ChangeOrganization = ({
  user,
  selectedOrganization,
  superUserOrgs,
  switchOrganization
}) => {
  const organizations =
    (superUserOrgs && superUserOrgs.nodes) || user.organizations?.nodes;

  const renderOrganization = organization => (
    <PopoverMenuItem
      key={organization.rowId}
      onClick={() => switchOrganization(organization)}
      icon={<Avatar src={organization.avatar} size={25} />}
      selected={organization.rowId === selectedOrganization?.rowId}
    >
      {organization.name}
    </PopoverMenuItem>
  );

  return (
    <>
      {organizations?.length > 1 && (
        <Popover>
          {organizations
            .slice(0)
            .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name))
            .map(renderOrganization)}
        </Popover>
      )}
    </>
  );
};

const OrganizationShape = PropType.shape({
  rowId: PropType.string,
  name: PropType.string,
  avatar: PropType.string
});

ChangeOrganization.propTypes = {
  user: PropType.shape({
    organizations: PropType.shape({
      nodes: PropType.arrayOf(OrganizationShape)
    })
  }).isRequired,
  selectedOrganization: OrganizationShape,
  superUserOrgs: PropType.shape({
    nodes: PropType.arrayOf(OrganizationShape)
  }),
  switchOrganization: PropType.func.isRequired
};

ChangeOrganization.defaultProps = {
  selectedOrganization: null,
  superUserOrgs: null
};

export default compose(withUser)(ChangeOrganization);
