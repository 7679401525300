import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Modal,
  ModalActions,
  ModalContent,
  Pushbutton,
  Prose
} from '@stratumn/atomic';

import { withGqlClient } from 'wrappers';
import * as Sentry from '@sentry/react';
import { removeTeamBot } from 'mutations';
import to from 'await-to-js';
import { SpanType, withSpanAsync } from 'tracing';

export const RemoveTeamBotModal = ({ environment, bot, team, toggleModal }) => {
  const [submitted, setSubmit] = useState(false);

  const removeBotGp = () => {
    withSpanAsync(`removeTeamBot`, SpanType.processing, async () => {
      setSubmit(true);

      const [err] = await to(
        removeTeamBot(environment, bot.id, bot.rowId, team.id, team.rowId)
      );

      if (err) {
        Sentry.captureException(err);
        setSubmit(false);
      } else {
        toggleModal();
      }

      return {};
    });
  };

  return (
    <Modal
      title="Remove from Team"
      handleCollapse={toggleModal}
      closeButtonLabel="Cancel"
    >
      <ModalContent>
        <Prose
          light
          text={`This action will remove **${bot.name}** from the **${
            team.name
          }** Team`}
        />
      </ModalContent>
      <ModalActions>
        <Pushbutton onClick={toggleModal}>cancel</Pushbutton>
        <Pushbutton warning onClick={removeBotGp} disabled={submitted}>
          Remove from team
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

RemoveTeamBotModal.propTypes = {
  environment: PropType.object.isRequired,
  bot: PropType.object.isRequired,
  team: PropType.object.isRequired,
  toggleModal: PropType.func.isRequired
};

export default withGqlClient(RemoveTeamBotModal);
