import React from 'react';
import PropType from 'prop-types';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';
import { withRouter, Redirect } from 'react-router-dom';

import { withGqlClient, withUser, withSpecificError } from 'wrappers';
import { ROUTE_COLLABORATORS } from 'constant/routes';
import { toast } from 'sonner/dist';

import { LoaderTraceLogo } from '@stratumn/atomic';
import Collaborator from './collaborator';

const query = graphql`
  query collaboratorRootQuery($userRowId: BigInt!, $teamFilter: TeamFilter) {
    userByRowId(rowId: $userRowId) {
      ...collaborator_collaborator @arguments(teamFilter: $teamFilter)
    }
  }
`;

export const Renderer = withSpecificError(
  ({ error, props: data, match: { params } }) => {
    if (!data && !error) return <LoaderTraceLogo />;

    let userRowId;

    if (data) userRowId = data.userByRowId;

    if (error || !userRowId) {
      toast.error(`the collaborator with the id ${params.id} does not exist.`);
      return <Redirect to={ROUTE_COLLABORATORS} />;
    }

    return <Collaborator collaborator={userRowId} />;
  }
);

Renderer.propTypes = {
  error: PropType.object,
  props: PropType.object
};

Renderer.defaultProps = {
  error: undefined,
  props: undefined
};

export const collaboratorRoot = props => {
  const { environment, selectedOrganization, collaboratorRowId } = props;
  const variables = {};

  const { rowId } = selectedOrganization;

  variables.userRowId = collaboratorRowId;
  variables.teamFilter = { organizationId: { equalTo: rowId } };

  // We need to make sure that the location props are passed to child components correctly.
  // https://github.com/ReactTraining/react-router/blob/v4.2.2/packages/react-router/docs/guides/blocked-updates.md
  const RendererWithRouter = withRouter(Renderer);

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={variables}
      render={RendererWithRouter}
    />
  );
};

collaboratorRoot.propTypes = {
  environment: PropType.object.isRequired,
  collaboratorRowId: PropType.string.isRequired,
  selectedOrganization: PropType.object
};

collaboratorRoot.defaultProps = {
  selectedOrganization: null
};

export default compose(withUser, withGqlClient)(collaboratorRoot);
