export default theme => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    overflowY: 'hidden'
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.greyTable,
    height: '100%',
    padding: '13px 21px',
    width: 285,
    boxSizing: 'border-box',
    '& > *': {
      marginBottom: 10
    },
    flexShrink: 0
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'white',
    padding: '28px 30px',
    paddingBottom: '0px',
    height: '100%',
    overflowY: 'auto',
    '& .tableContent': {
      height: 'calc(100% - 37px)',
      overflowY: 'auto',
      paddingRight: '15px'
    },
    '& .columnTitles': {
      height: '33px'
    }
  },
  orgTitle: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 14
  },
  orgName: {
    fontSize: 20,
    marginLeft: 5,
    fontWeight: 500,
    cursor: 'default',
    width: '12rem',
    overflow: 'hidden',
    transition: 'background-color 0.2s',
    '& input': {
      width: '100%',
      paddingInline: '4px',
      borderColor: theme.grey3
    }
  },
  canUpdateInput: {
    '&:hover': {
      backgroundColor: theme.grey8
    }
  },
  subHeader: {
    '& > *': {
      marginLeft: 5,
      fontSize: 16
    }
  },

  sidenavLink: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 12,
    textDecoration: 'none',
    color: theme.grey2,
    '&:hover': {
      color: theme.grey1
    }
  },
  tally: {
    marginLeft: 7
  }
});
