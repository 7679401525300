import React from 'react';
import injectSheet from 'react-jss';
import PropType from 'prop-types';
import compose from 'lodash.flowright';
import { validate as isValidEmail } from 'isemail';
import { Pushbutton } from '@stratumn/atomic';

import { TextInput } from 'components/ui';
import { LeftPane } from 'components/layout';
import { withRestClient } from 'wrappers';

import styles from './admin.style';

export class Admin extends React.Component {
  static propTypes = {
    classes: PropType.object.isRequired,
    client: PropType.object.isRequired
  };

  state = {
    email: '',
    disabled: false,
    pendingInvitations: []
  };

  componentDidMount() {
    this.fetchPendingInvitations();
  }

  fetchPendingInvitations = () => {
    this.props.client.pendingInvitations().then(({ values }) => {
      this.setState({ pendingInvitations: values });
    });
  };

  handleEmailChange = e => {
    this.setState({ email: e.target.value });
  };

  onSendInvitation = () => {
    this.setState({ disabled: true });
    this.props.client
      .invite(this.state.email)
      .then(({ url }) => {
        this.setState({ email: '', disabled: false });
        // useful to log the signup url for testing
        console.log(url);
        this.fetchPendingInvitations();
      })
      .catch(() => {
        this.setState({ disabled: false });
      });
  };

  disableButton = () => {
    const { disabled, email } = this.state;
    return disabled || !isValidEmail(email);
  };

  revokeInvitation = async token => {
    console.log('revoking', token);
    await this.props.client.revokeInvitation(token);
    this.fetchPendingInvitations();
  };

  render() {
    const { classes } = this.props;
    const { pendingInvitations } = this.state;
    return (
      <LeftPane title="Admin">
        <h3>Invite people</h3>
        <div className={classes.invitation}>
          <TextInput
            type="email"
            label="email"
            onChange={this.handleEmailChange}
            top
          />
          <Pushbutton
            onClick={this.onSendInvitation}
            disabled={this.disableButton()}
            primary
          >
            Send invitation
          </Pushbutton>
        </div>
        {!!pendingInvitations.length && (
          <>
            <h3>Pending invitations</h3>
            <div className={classes.pendingInvitations}>
              {pendingInvitations.map(({ email, token }, idx) => (
                <div key={idx} className={classes.pendingInvitation}>
                  <div>{email}</div>
                  <Pushbutton onClick={() => this.revokeInvitation(token)}>
                    revoke
                  </Pushbutton>
                </div>
              ))}
            </div>
          </>
        )}
      </LeftPane>
    );
  }
}

export default compose(withRestClient, injectSheet(styles))(Admin);
