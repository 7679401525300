import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import styles from './divider.style';

export class Divider extends React.PureComponent {
  static propTypes = {
    text: PropType.string.isRequired,
    classes: PropType.object.isRequired
  };

  render() {
    const { classes, text } = this.props;

    return <div className={classes.divider}>{text}</div>;
  }
}

export default injectSheet(styles)(Divider);
