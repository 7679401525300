import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Modal,
  ModalActions,
  ModalContent,
  Pushbutton,
  Prose
} from '@stratumn/atomic';
import * as Sentry from '@sentry/react';
import { withGqlClient } from 'wrappers';

import { expireKey } from 'mutations';
import to from 'await-to-js';
import { SpanType, withSpanAsync } from 'tracing';

export const ExpireKeyModal = ({
  environment,
  keyName,
  keyObj,
  toggleModal
}) => {
  const [submitted, setSubmit] = useState(false);

  const expireKeyFn = () => {
    withSpanAsync(`expireKey`, SpanType.processing, async () => {
      setSubmit(true);

      const [err] = await to(expireKey(environment, keyObj.rowId));

      if (err) {
        Sentry.captureException(err);
        setSubmit(false);
      } else {
        toggleModal();
      }

      return {};
    });
  };

  return (
    <Modal
      title="Expire Signing Key"
      handleCollapse={toggleModal}
      closeButtonLabel="Cancel"
    >
      <ModalContent>
        <Prose
          light
          text={`This action will definitively deprecate signing key **${keyName ||
            keyObj.rowId}**. It will not be possible to sign with it afterwards.`}
        />
      </ModalContent>
      <ModalActions>
        <Pushbutton onClick={toggleModal}>cancel</Pushbutton>
        <Pushbutton warning onClick={expireKeyFn} disabled={submitted}>
          Expire
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

ExpireKeyModal.propTypes = {
  environment: PropType.object.isRequired,
  keyName: PropType.string,
  keyObj: PropType.object.isRequired,
  toggleModal: PropType.func.isRequired
};
ExpireKeyModal.defaultProps = {
  keyName: ''
};

export default withGqlClient(ExpireKeyModal);
