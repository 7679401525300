import React, { useState } from 'react';
import PropType from 'prop-types';
import { withRouter, generatePath, Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';

import to from 'await-to-js';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';

import { updateBot } from 'mutations';

import {
  FieldTextCompact,
  FieldTextAreaCompact,
  Pushbutton
} from '@stratumn/atomic';

import { UploadPhoto } from 'components/ui';
import { LeftPane } from 'components/layout';

import { SpanType, withSpanAsync } from 'tracing';
import { withGqlClient, withRestClient } from 'wrappers';

import { ROUTE_BOT } from 'constant/routes';

import styles from './editBotProfile.style';

export const EditBotProfile = ({
  classes,
  environment,
  client,
  bot,
  history
}) => {
  const [submitted, setSubmit] = useState(false);
  const [name, setName] = useState(bot.name || '');
  const [description, setDescription] = useState(bot.description || '');
  const [avatar, setAvatar] = useState(bot.avatar || '');
  const { organization } = bot;

  const onSubmit = () => {
    withSpanAsync('updateBot', SpanType.processing, async () => {
      setSubmit(true);

      let avatarUrl;

      if (avatar instanceof File) {
        const { digest } = await client.media.uploadFile(avatar, {
          disableEncryption: true
        });
        avatarUrl = await client.media.buildDownloadURL(digest);
      }

      const [err] = await to(
        updateBot(environment, bot.rowId, name, description, avatarUrl)
      );

      if (err) {
        Sentry.captureException(err);
        setSubmit(false);
      } else {
        gotToBotProfilePage();
      }
    });
  };

  const gotToBotProfilePage = () => {
    history.push(
      generatePath(ROUTE_BOT, {
        id: bot.rowId,
        orgId: organization.rowId
      })
    );
  };

  return (
    <LeftPane title="Edit Bot">
      <div className={classes.formWrapper}>
        <div className={classes.avatarWrapper}>
          <UploadPhoto
            size={56}
            avatar={JSON.stringify(avatar)}
            getNewAvatar={setAvatar}
            user
            overlay
          />
        </div>

        <div className={classes.fieldWrapper}>
          <FieldTextCompact
            label="Bot name"
            value={name}
            onValueChange={e => setName(e.target.value)}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <FieldTextAreaCompact
            label="Bot description"
            value={description}
            onValueChange={e => setDescription(e.target.value)}
            rows={4}
            noResize
          />
        </div>
        <div className={classes.formBtnWrapper}>
          <div className={classes.formBtnLeft}>
            <Link
              to={generatePath(ROUTE_BOT, {
                id: bot.rowId,
                orgId: organization.rowId
              })}
              style={{ textDecoration: 'none' }}
            >
              <Pushbutton>cancel</Pushbutton>
            </Link>
          </div>
          <Pushbutton primary disabled={!name || submitted} onClick={onSubmit}>
            save
          </Pushbutton>
        </div>
      </div>
    </LeftPane>
  );
};

EditBotProfile.propTypes = {
  classes: PropType.object.isRequired,
  environment: PropType.object.isRequired,
  client: PropType.object.isRequired,
  history: PropType.object.isRequired,
  bot: PropType.object.isRequired
};

export default createFragmentContainer(
  compose(withRouter, withGqlClient, withRestClient, injectSheet(styles))(
    EditBotProfile
  ),
  {
    bot: graphql`
      fragment editBotProfile_bot on Bot {
        id
        rowId
        name
        description
        avatar
        organization {
          id
          rowId
          name
          canUpdate
        }
      }
    `
  }
);
