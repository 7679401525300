/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type teamsHeader_organization$ref: FragmentReference;
declare export opaque type teamsHeader_organization$fragmentType: teamsHeader_organization$ref;
export type teamsHeader_organization = {|
  +id: string,
  +rowId: any,
  +account: ?{|
    +canUpdate: ?boolean
  |},
  +headerTeams: {|
    +edges: $ReadOnlyArray<{|
      +node: ?{|
        +id: string,
        +rowId: any,
        +name: string,
        +account: ?{|
          +members: {|
            +totalCount: number,
            +nodes: $ReadOnlyArray<?{|
              +collaborator: ?boolean,
              +email: ?string,
              +name: ?string,
            |}>,
          |}
        |},
        +bots: {|
          +totalCount: number,
          +nodes: $ReadOnlyArray<?{|
            +name: string
          |}>,
        |},
      |}
    |}>
  |},
  +$refType: teamsHeader_organization$ref,
|};
export type teamsHeader_organization$data = teamsHeader_organization;
export type teamsHeader_organization$key = {
  +$data?: teamsHeader_organization$data,
  +$fragmentRefs: teamsHeader_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "orderBy",
      "type": "[TeamsOrderBy!]"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "teamsHeader_organization",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canUpdate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "headerTeams",
      "args": [
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        }
      ],
      "concreteType": "TeamsConnection",
      "kind": "LinkedField",
      "name": "teams",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Team",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Account",
                  "kind": "LinkedField",
                  "name": "account",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MembersConnection",
                      "kind": "LinkedField",
                      "name": "members",
                      "plural": false,
                      "selections": [
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Member",
                          "kind": "LinkedField",
                          "name": "nodes",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "collaborator",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "email",
                              "storageKey": null
                            },
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BotsConnection",
                  "kind": "LinkedField",
                  "name": "bots",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Bot",
                      "kind": "LinkedField",
                      "name": "nodes",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization"
};
})();
// prettier-ignore
(node/*: any*/).hash = '36debf15ed34349ba1623dbc32ded31d';

module.exports = node;
