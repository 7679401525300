import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { SpanType, withSpanAsync } from 'tracing';

import { KEYS_ORDER_BY } from 'constant/types';

const mutation = graphql`
  mutation addBotKeyMutation(
    $input: CreateBotSigningKeyInput!
    $orderKeysBy: [KeysOrderBy!]
  ) {
    createBotSigningKey(input: $input) {
      account {
        id
        rowId
        keys(orderBy: $orderKeysBy) {
          totalCount
          nodes {
            id
            rowId
            publicKey
            deprecated
            createdAt
            deprecatedAt
          }
        }
      }
    }
  }
`;

export default (environment, botRowId, publicKey) =>
  withSpanAsync('createBotSigningKey', SpanType.mutation, async () => {
    const variables = {
      input: {
        botRowId,
        publicKey
      },
      orderKeysBy: KEYS_ORDER_BY
    };

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);
          resolve();
        },
        onError: err => {
          console.error(err);
          reject(err);
        }
      });
    });
  });
