export default theme => ({
  signIn: {
    fontSize: 11,
    color: theme.grey2
  },
  passwordPrompt: {
    paddingTop: 17,
    paddingBottom: 7
  },
  modalActionsRoot: {
    display: 'flex',
    overflowX: 'auto',
    flexShrink: 0
  },
  modalActions: {
    display: 'flex',
    paddingTop: 0,
    paddingRight: 14,
    paddingLeft: 14,
    paddingBottom: 25,
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});
