export default theme => ({
  wrapper: {
    minHeight: `calc(100vh - ${theme.headerHeight}px - ${
      theme.footerHeight
    }px - 2rem)`
  },
  notification: {
    backgroundColor: theme.warningRed,
    color: 'white',
    padding: '10px 20px',
    '&:not(:last-child)': {
      borderBottom: '1px solid #C6443D'
    }
  },
  container: {
    margin: 21
  },
  pageContent: {
    borderRadius: '10px',
    height: `calc(100vh - ${theme.headerHeight}px - ${
      theme.footerHeight
    }px - 5rem)`,
    overflowY: 'hidden',
    position: 'relative',
    backgroundColor: 'white',
    boxShadow: theme.indigoShadow1
  }
});
