import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import PropType from 'prop-types';
import { createFragmentContainer } from 'react-relay';
import { withRouter, generatePath } from 'react-router-dom';

import injectSheet from 'react-jss';
import compose from 'lodash.flowright';

import nameToInitials from 'utils/nameToInitials';

import {
  AvatarGroup,
  Directory,
  DirectoryRow,
  Pushtext,
  Avatar
} from '@stratumn/atomic';

import { withGqlClient } from 'wrappers';

import { ROUTE_TEAM } from 'constant/routes';

import RemoveOrgMemberModal from './removeOrgMemberModal';
import RemoveTeamMemberModal from './removeTeamMemberModal';

import styles from './member.style';

const INITIAL_STATE = {
  teamRowId: '',

  showModalTeam: false,
  showModalOrg: false
};

export class Member extends React.Component {
  static propTypes = {
    classes: PropType.object.isRequired,
    history: PropType.object.isRequired,
    member: PropType.object.isRequired
  };

  state = INITIAL_STATE;

  toggleModalOrg = () =>
    this.setState({
      showModalOrg: !this.state.showModalOrg
    });

  toggleModalTeam = teamRowId =>
    this.setState({
      teamRowId: teamRowId,
      showModalTeam: !this.state.showModalTeam
    });

  renderTeamRow = (node, index) => {
    const { id, rowId, name, avatar, canUpdate } = node;
    const {
      history,
      match: { params },
      classes,
      member: { teams: { totalCount } }
    } = this.props;

    return (
      <DirectoryRow
        key={id}
        isLast={index === totalCount - 1}
        main={
          <Pushtext
            onClick={() =>
              history.push(
                generatePath(ROUTE_TEAM, {
                  id: rowId,
                  orgId: params.orgId
                })
              )
            }
          >
            <div className={classes.link}>
              <AvatarGroup
                initials={nameToInitials(name)}
                nth={Number(rowId)}
                size={37}
                src={avatar}
              />
              <span className={classes.teamName}>{name}</span>
            </div>
          </Pushtext>
        }
        cell1={
          canUpdate && (
            <Pushtext onClick={() => this.toggleModalTeam(rowId)}>
              Remove
            </Pushtext>
          )
        }
      />
    );
  };

  render() {
    const {
      classes,
      member,
      member: { organizations: { nodes: [organization] } }
    } = this.props;
    const { account: { members: { nodes: [is] } } } = organization;
    const { showModalTeam, showModalOrg, teamRowId } = this.state;

    return (
      <>
        <div className={classes.userHeaderWrapper}>
          <div>
            <Avatar size={56} src={member.avatar} />
            <div className={classes.userName}>{member.name}</div>
            <div className={classes.userRole}>
              {is.admin ? 'Admin' : 'Participant'}
            </div>
          </div>
          <div>
            {organization.canUpdate && (
              <Pushtext mute warning onClick={this.toggleModalOrg}>
                Remove from organization...
              </Pushtext>
            )}
          </div>
        </div>
        <div className={classes.collectionItem}>
          <div className={classes.collectionItemLabel}>email</div>
          <div className={classes.collectionItemValue}>{member.email}</div>
        </div>
        {showModalTeam && (
          <RemoveTeamMemberModal
            memberRowId={member.rowId}
            teamRowId={teamRowId}
            toggleModal={this.toggleModalTeam}
          />
        )}
        {showModalOrg && (
          <RemoveOrgMemberModal
            memberRowId={member.rowId}
            organizationRowId={organization.rowId}
            toggleModal={this.toggleModalOrg}
          />
        )}
        <Directory showColumnTitles columnTitle="teams">
          {member.teams.nodes.map((index, node) =>
            this.renderTeamRow(index, node)
          )}
        </Directory>
      </>
    );
  }
}

export default createFragmentContainer(
  compose(withRouter, withGqlClient, injectSheet(styles))(Member),
  {
    member: graphql`
      fragment member_member on User
        @argumentDefinitions(
          organizationFilter: { type: "OrganizationFilter", defaultValue: {} }
          memberFilter: { type: "MemberFilter", defaultValue: {} }
          teamFilter: { type: "TeamFilter", defaultValue: {} }
        ) {
        ...removeOrgMemberModal_member
          @arguments(
            organizationFilter: $organizationFilter
            teamFilter: $teamFilter
          )
        ...removeTeamMemberModal_member @arguments(teamFilter: $teamFilter)
        id
        rowId
        name
        email
        avatar
        canUpdate
        organizations(filter: $organizationFilter) {
          nodes {
            rowId
            canUpdate
            account {
              members(filter: $memberFilter) {
                nodes {
                  admin
                }
              }
            }
          }
        }
        teams(filter: $teamFilter) {
          totalCount
          nodes {
            id
            rowId
            name
            avatar
            canUpdate
          }
        }
      }
    `
  }
);
