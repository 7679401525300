import React from 'react';
import PropType from 'prop-types';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';

import { withGqlClient, withUser } from 'wrappers';

import RemoveOrgMemberModal from './removeOrgMemberModal';

const query = graphql`
  query removeOrgMemberModalQuery(
    $userRowId: BigInt!
    $teamFilter: TeamFilter
    $organizationFilter: OrganizationFilter # $teamFilter: teamFilter
  ) {
    userByRowId(rowId: $userRowId) {
      ...removeOrgMemberModal_member
        @arguments(
          organizationFilter: $organizationFilter
          teamFilter: $teamFilter
        )
    }
  }
`;

export const Renderer = props => {
  const {
    environment,
    selectedOrganization,
    memberRowId,
    organizationRowId,
    toggleModal
  } = props;
  const variables = {};

  // There are some users that have unrealistic data so we need to account
  // for them to not crash the UI.
  if (!selectedOrganization || !selectedOrganization.rowId) {
    return null;
  }

  variables.userRowId = memberRowId;
  variables.organizationFilter = { rowId: { equalTo: organizationRowId } };
  variables.teamFilter = { organizationId: { equalTo: organizationRowId } };

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={variables}
      render={({ error, props: data }) => {
        if (error) return 'error';
        if (!data) return null;
        const { userByRowId } = data;
        return (
          <RemoveOrgMemberModal
            member={userByRowId}
            toggleModal={toggleModal}
          />
        );
      }}
    />
  );
};

Renderer.propTypes = {
  environment: PropType.object.isRequired,
  selectedOrganization: PropType.object,
  memberRowId: PropType.string.isRequired,
  organizationRowId: PropType.string.isRequired,
  toggleModal: PropType.func.isRequired
};

Renderer.defaultProps = {
  selectedOrganization: null
};

export default compose(withUser, withGqlClient)(Renderer);
