import React from 'react';
import PropType from 'prop-types';

export const NotificationsContext = React.createContext();

export const withNotifications = Component => props => (
  <NotificationsContext.Consumer>
    {({ notifications, addNotification, removeNotification }) => (
      <Component
        {...{ notifications, addNotification, removeNotification }}
        {...props}
      />
    )}
  </NotificationsContext.Consumer>
);

export class Notifications extends React.Component {
  static propTypes = {
    children: PropType.node.isRequired
  };

  state = {
    notifications: {}
  };

  addNotification = (id, message) =>
    new Promise(resolve =>
      this.setState(
        {
          notifications: { ...this.state.notifications, [id]: message }
        },
        resolve
      )
    );

  removeNotification = id =>
    new Promise(resolve => {
      const {
        [id]: oldNotification,
        ...notifications
      } = this.state.notifications;
      this.setState({ notifications }, resolve);
    });

  render() {
    return (
      <NotificationsContext.Provider
        value={{
          notifications: this.state.notifications,
          addNotification: this.addNotification,
          removeNotification: this.removeNotification
        }}
      >
        {this.props.children}
      </NotificationsContext.Provider>
    );
  }
}

export default Notifications;
