import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import PropType from 'prop-types';
import { createFragmentContainer } from 'react-relay';
import { withRouter, generatePath } from 'react-router-dom';

import compose from 'lodash.flowright';
import injectSheet from 'react-jss';

import nameToInitials from 'utils/nameToInitials';

import {
  AvatarGroup,
  Directory,
  DirectoryRow,
  Pushtext,
  Avatar
} from '@stratumn/atomic';

import { withGqlClient } from 'wrappers';

import { ROUTE_TEAM } from 'constant/routes';

import RemoveTeamCollaboratorModal from './removeTeamCollaboratorModal';

import styles from './collaborator.style';

const INITIAL_STATE = {
  teamRowId: '',
  showModal: {
    removeFromTeam: false,
    removeFromOrg: false
  }
};

export class Collaborator extends React.Component {
  static propTypes = {
    classes: PropType.object.isRequired,
    history: PropType.object.isRequired,
    collaborator: PropType.object.isRequired
  };

  state = INITIAL_STATE;

  toggleModal = (name, teamRowId) =>
    this.setState({
      teamRowId: teamRowId || '',
      showModal: {
        [name]: !this.state.showModal[name]
      }
    });

  toggleTeamModal = id => this.toggleModal('removeFromTeam', id);

  toggleTeamOrg = id => this.toggleModal('removeFromOrg', id);

  renderTeamRow = (node, index) => {
    const { id, rowId, name, avatar, canUpdate } = node;

    const {
      history,
      classes,
      collaborator: { teams: { totalCount } },
      match: { params }
    } = this.props;
    return (
      <DirectoryRow
        key={id}
        isLast={index === totalCount - 1}
        main={
          <Pushtext
            onClick={() =>
              history.push(
                generatePath(ROUTE_TEAM, {
                  id: rowId,
                  orgId: params.orgId
                })
              )
            }
          >
            <div className={classes.link}>
              <AvatarGroup
                initials={nameToInitials(name)}
                nth={index}
                size={37}
                src={avatar}
              />
              <span className={classes.teamName}>{name}</span>
            </div>
          </Pushtext>
        }
        cell1={
          canUpdate && (
            <Pushtext onClick={() => this.toggleTeamModal(rowId)}>
              Remove
            </Pushtext>
          )
        }
      />
    );
  };

  render() {
    const {
      classes,
      collaborator: { rowId, avatar, name, email, teams }
    } = this.props;
    const { teamRowId, showModal } = this.state;

    return (
      <>
        {showModal.removeFromTeam && (
          <RemoveTeamCollaboratorModal
            collaboratorRowId={rowId}
            teamRowId={teamRowId}
            onClose={this.toggleTeamModal}
          />
        )}
        <div className={classes.userHeaderWrapper}>
          <div>
            <Avatar size={56} src={avatar} />
            <div className={classes.userName}>{name}</div>
            <div className={classes.userRole}>Collaborator</div>
          </div>
          <div>
            {/* 
              A collaborator is not part of an organization.
              This feature is temporarily disabled.
            */}
            {false && (
              <Pushtext mute warning onClick={this.toggleTeamOrg}>
                Remove from organization...
              </Pushtext>
            )}
          </div>
        </div>
        <div className={classes.collectionItem}>
          <div className={classes.collectionItemLabel}>email</div>
          <div className={classes.collectionItemValue}>{email}</div>
        </div>
        {teams.totalCount > 0 ? (
          <Directory showColumnTitles columnTitle="teams">
            {teams.nodes.map(this.renderTeamRow)}
          </Directory>
        ) : (
          <div>This collaborator is not part of any teams.</div>
        )}
      </>
    );
  }
}

export default createFragmentContainer(
  compose(withRouter, withGqlClient, injectSheet(styles))(Collaborator),
  {
    collaborator: graphql`
      fragment collaborator_collaborator on User
        @argumentDefinitions(
          teamFilter: { type: "TeamFilter", defaultValue: {} }
        ) {
        ...removeCollaboratorModal_collaborator
          @arguments(teamFilter: $teamFilter)
        id
        rowId
        name
        email
        avatar
        teams(filter: $teamFilter) {
          totalCount
          nodes {
            id
            rowId
            avatar
            name
            canUpdate
          }
        }
      }
    `
  }
);
