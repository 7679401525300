import React from 'react';
import PropType from 'prop-types';

import injectSheet from 'react-jss';
import nameToInitials from 'utils/nameToInitials';

import { AvatarButton, AvatarGroupButton } from '@stratumn/atomic';
import { CropDialog } from 'components/ui';

import styles from './uploadPhoto.style';

export class UploadPhoto extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired,
    avatar: PropType.string,
    getNewAvatar: PropType.func.isRequired,
    name: PropType.string,
    overlayOnHover: PropType.bool,
    size: PropType.number,
    nth: PropType.number,
    user: PropType.bool
  };

  static defaultProps = {
    avatar: '',
    name: null,
    overlayOnHover: false,
    size: 32,
    nth: null,
    user: null
  };

  state = {
    croppedImageUrl: this.props.avatar || null,
    imageSrc: null,
    overlay: false,
    uploadFile: null
  };

  handleMouseEnter = () => {
    const { overlayOnHover } = this.props;
    if (overlayOnHover) this.setState({ overlay: true });
  };

  handleMouseLeave = () => {
    const { overlayOnHover } = this.props;
    if (overlayOnHover) this.setState({ overlay: false });
  };

  fileInputRef = React.createRef();

  onAvatarClick = () => this.fileInputRef.current.click();

  onFileChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      const targetFile = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ imageSrc: reader.result })
      );
      reader.readAsDataURL(targetFile);
    }
  };

  onCropFinish = async ({ croppedImageUrl, croppedFile }) => {
    await this.setState({
      imageSrc: null,
      uploadFile: croppedFile,
      croppedImageUrl
    });
    if (this.fileInputRef.current) this.fileInputRef.current.value = '';
    this.props.getNewAvatar(this.state.uploadFile);
  };

  onCropCancel = () => {
    this.setState({ imageSrc: null });
    this.fileInputRef.current.value = '';
  };

  renderButton = () => {
    const { user, size, name, nth } = this.props;
    const { croppedImageUrl, overlay } = this.state;

    if (user) {
      return (
        <AvatarButton
          src={croppedImageUrl}
          onClick={this.onAvatarClick}
          size={size}
          overlay
        />
      );
    }
    return (
      <div
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <AvatarGroupButton
          src={croppedImageUrl}
          onClick={this.onAvatarClick}
          size={size}
          initials={nameToInitials(name)}
          nth={nth}
          overlay={overlay}
        />
      </div>
    );
  };

  render() {
    const { classes, user } = this.props;
    const { imageSrc } = this.state;

    return (
      <>
        {this.renderButton()}
        <input
          type="file"
          ref={this.fileInputRef}
          onChange={this.onFileChange}
          className={classes.fileInput}
        />
        <CropDialog
          actionLabel={user ? 'crop' : 'save'}
          imageSrc={imageSrc}
          onCancel={this.onCropCancel}
          onFinish={this.onCropFinish}
        />
      </>
    );
  }
}

export default injectSheet(styles)(UploadPhoto);
