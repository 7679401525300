export default theme => ({
  title: {
    marginBottom: 16,
    fontSize: 14,
    color: theme.grey2
  },
  name: {
    fontWeight: 800
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  userInfo: {
    display: 'flex',
    '& > div': {
      width: 125,
      textAlign: 'right'
    }
  },
  inputContainer: {
    paddingTop: 14,
    marginBottom: 14
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: 34,
    borderColor: theme.grey5,
    backgroundColor: theme.grey9,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 2,
    transition: 'border-color 150ms linear 0ms'
  },
  emailTagWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  emailTag: {
    display: 'inline-flex',
    marginLeft: 7,
    padding: '3px 7px',
    fontSize: 14,
    color: theme.grey2,
    backgroundColor: theme.grey8
  },
  emailTagRemove: {
    paddingLeft: 7,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  inputField: {
    display: 'inline-block',
    flexGrow: 1,
    appearance: 'none',
    boxSizing: 'border-box',
    outline: 'none',
    backgroundColor: 'transparent',
    borderWidth: 0,
    borderRadius: 'inherit',
    padding: '3px 7px',
    fontSize: 14,
    fontWeight: 400,
    color: theme.grey1,
    '&::placeholder': {
      color: theme.grey4
    }
  },
  providerList: {
    backgroundColor: theme.grey8,
    borderRadius: 2,
    maxHeight: 250,
    overflow: 'auto',
    paddingTop: 6,
    paddingRight: 12,
    paddingLeft: 12
  }
});
