import React from 'react';
import ReactDOM from 'react-dom';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import { Pushbutton } from '@stratumn/atomic';

import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';
import { withGqlClient, withUser } from 'wrappers';

import AddTeamMembersModal from '../../addTeamMembersModal';
import AddTeamBotsModal from '../../addTeamBotsModal';
import InviteCollaboratorsModal from '../inviteCollaboratorsModal';

import styles from './teamHeader.style';

const INITIAL_STATE = {
  showModal: {
    collaborators: false,
    members: false
  }
};

export class TeamHeader extends React.Component {
  static propTypes = {
    team: PropType.object.isRequired,
    classes: PropType.object.isRequired
  };

  state = INITIAL_STATE;

  toggleModal = name =>
    this.setState({
      showModal: {
        [name]: !this.state.showModal[name]
      }
    });

  render() {
    const { classes, team } = this.props;
    const { showModal } = this.state;

    return ReactDOM.createPortal(
      <>
        {showModal.members && (
          <AddTeamMembersModal
            teamId={team.id}
            teamAccountRowId={team.account.rowId}
            onClose={() => this.toggleModal('members')}
          />
        )}
        {showModal.collaborators && (
          <InviteCollaboratorsModal
            teamId={team.id}
            teamAccountRowId={team.account.rowId}
            onClose={() => this.toggleModal('collaborators')}
          />
        )}
        {showModal.bots && (
          <AddTeamBotsModal
            teamId={team.id}
            teamAccountRowId={team.account.rowId}
            onClose={() => this.toggleModal('bots')}
          />
        )}

        {team.canUpdate && (
          <div className={classes.actions}>
            <div className={classes.action}>
              <Pushbutton
                onClick={() => this.toggleModal('collaborators')}
                secondary
              >
                Invite Collaborators
              </Pushbutton>
            </div>
            <div className={classes.action}>
              <Pushbutton onClick={() => this.toggleModal('members')} secondary>
                Add Members
              </Pushbutton>
            </div>
            <Pushbutton onClick={() => this.toggleModal('bots')} secondary>
              Add Bots
            </Pushbutton>
          </div>
        )}
      </>,
      document.getElementById('btn-invite-collaborators')
    );
  }
}

export default createFragmentContainer(
  compose(withUser, withGqlClient, injectSheet(styles))(TeamHeader),
  {
    team: graphql`
      fragment teamHeader_team on Team {
        id
        name
        canUpdate
        account {
          rowId
        }
      }
    `
  }
);
