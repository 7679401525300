/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type teamRow_team$ref = any;
type teamsHeader_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type teams_organization$ref: FragmentReference;
declare export opaque type teams_organization$fragmentType: teams_organization$ref;
export type teams_organization = {|
  +account: ?{|
    +canUpdate: ?boolean
  |},
  +teams: {|
    +totalCount: number,
    +edges: $ReadOnlyArray<{|
      +cursor: ?any,
      +node: ?{|
        +id: string,
        +$fragmentRefs: teamRow_team$ref,
      |},
    |}>,
    +pageInfo: {|
      +hasNextPage: boolean,
      +endCursor: ?any,
    |},
  |},
  +$fragmentRefs: teamsHeader_organization$ref,
  +$refType: teams_organization$ref,
|};
export type teams_organization$data = teams_organization;
export type teams_organization$key = {
  +$data?: teams_organization$data,
  +$fragmentRefs: teams_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "Cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orderBy",
      "type": "[TeamsOrderBy!]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamFilter",
      "type": "TeamFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "teams"
        ]
      }
    ]
  },
  "name": "teams_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canUpdate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "teams",
      "args": null,
      "concreteType": "TeamsConnection",
      "kind": "LinkedField",
      "name": "__Teams_teams_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TeamsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Team",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "teamRow_team"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "teamsHeader_organization"
    }
  ],
  "type": "Organization"
};
// prettier-ignore
(node/*: any*/).hash = '8c1d9f48442900e91455c04c32d1f42b';

module.exports = node;
