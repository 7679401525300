import React, { useState, memo } from 'react';
import graphql from 'babel-plugin-relay/macro';
import PropType from 'prop-types';
import { createFragmentContainer } from 'react-relay';

import compose from 'lodash.flowright';

import { Directory, DirectoryRow, Pushtext } from '@stratumn/atomic';

import { PendingInvite, UserAvatarLink } from 'components/ui';
import { withGqlClient, withUser } from 'wrappers';

import { COLLABORATOR_KEY } from 'constant/types';

import RemoveCollaboratorModal from './removeCollaboratorModal';

export function Collaborators({
  organization: {
    id: organizationId,
    rowId: organizationRowId,
    canUpdate,
    account: { id: organizationAccountId },
    collaborators
  }
}) {
  const [showModal, setShowModal] = useState(false);
  const [currentCollaboratorRowId, setCurrentCollaboratorRowId] = useState(
    null
  );

  const toggleModal = rowId => {
    setShowModal(!showModal);
    setCurrentCollaboratorRowId(rowId);
  };

  const renderUserRow = (index, node) => {
    const {
      id,
      rowId,
      name,
      email,
      avatar,
      pending,
      inviteExpired,
      teams
    } = node;

    let teamLabel;
    switch (teams.totalCount) {
      case 0:
        teamLabel = 'No Teams';
        break;
      case 1:
        teamLabel = '1 Team';
        break;
      default:
        teamLabel = `${teams.totalCount} Teams`;
    }

    return (
      <DirectoryRow
        key={email}
        isLast={index === collaborators.totalCount - 1}
        main={
          <UserAvatarLink
            id={id}
            rowId={rowId}
            name={name || email}
            avatar={avatar}
            noLink={pending}
            collaborator
            organizationRowId={organizationRowId}
          />
        }
        cell1={
          canUpdate &&
          (pending ? (
            <PendingInvite
              accountId={organizationAccountId}
              accountRowId={teams.nodes[0].account.rowId}
              connectionKey={COLLABORATOR_KEY}
              email={email}
              organizationId={organizationId}
              organizationRowId={organizationRowId}
              userId={id}
              userRowId={rowId}
              expired={inviteExpired}
            />
          ) : (
            <Pushtext onClick={() => toggleModal(rowId)}>Remove</Pushtext>
          ))
        }
        cell2={!pending && teamLabel}
      />
    );
  };

  return (
    <>
      <Directory
        showColumnTitles
        columnTitle="Name"
        columnTitle2="Teams"
        columnTitle1="Action"
      >
        {collaborators.edges.map((edge, index) =>
          renderUserRow(index, edge.node)
        )}
      </Directory>
      {showModal && (
        <RemoveCollaboratorModal
          organizationRowId={organizationRowId}
          organizationId={organizationId}
          collaboratorRowId={currentCollaboratorRowId}
          onClose={toggleModal}
        />
      )}
    </>
  );
}

Collaborators.propTypes = {
  environment: PropType.object.isRequired,
  organization: PropType.object.isRequired
};

export default createFragmentContainer(
  compose(withUser, withGqlClient)(memo(Collaborators)),
  {
    organization: graphql`
      fragment collaborators_organization on Organization
        @argumentDefinitions(
          teamFilter: { type: "TeamFilter", defaultValue: {} }
          collaboratorFilter: { type: "UserFilter", defaultValue: {} }
        ) {
        ...collaboratorsHeader_organization
        id
        rowId
        canUpdate
        account {
          id
          rowId
        }
        # In order to use a search filter, we need to fetch the 'edges'. We also need to pass in an empty array as a second argument of the @connection declaration so relay ignores the filter argument in the store.
        # The first argument represents the number of members we fetch on load

        collaborators(first: null, filter: $collaboratorFilter)
          @connection(key: "Collaborators_collaborators", filters: []) {
          totalCount
          edges {
            node {
              ...removeCollaboratorModal_collaborator
                @arguments(teamFilter: $teamFilter)
              id
              rowId
              name
              email
              avatar
              pending
              inviteExpired
              teams(filter: $teamFilter) {
                totalCount
                nodes {
                  name
                  account {
                    rowId
                  }
                }
              }
            }
          }
        }
      }
    `
  }
);
