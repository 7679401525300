/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type inviteCollaboratorsModal_team$ref = any;
export type inviteCollaboratorsModalQueryVariables = {|
  teamId: string,
  teamAccountRowId: any,
|};
export type inviteCollaboratorsModalQueryResponse = {|
  +team: ?{|
    +$fragmentRefs: inviteCollaboratorsModal_team$ref
  |}
|};
export type inviteCollaboratorsModalQuery = {|
  variables: inviteCollaboratorsModalQueryVariables,
  response: inviteCollaboratorsModalQueryResponse,
|};
*/


/*
query inviteCollaboratorsModalQuery(
  $teamId: ID!
) {
  team(id: $teamId) {
    ...inviteCollaboratorsModal_team_2EIa4l
    id
  }
}

fragment inviteCollaboratorsModal_team_2EIa4l on Team {
  rowId
  name
  account {
    id
  }
  organization {
    organizationCollaboratorsLicensesLeft
    providers
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamAccountRowId",
    "type": "BigInt!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "teamId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "inviteCollaboratorsModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "teamAccountRowId",
                "variableName": "teamAccountRowId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "inviteCollaboratorsModal_team"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "inviteCollaboratorsModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organizationCollaboratorsLicensesLeft",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "providers",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "inviteCollaboratorsModalQuery",
    "operationKind": "query",
    "text": "query inviteCollaboratorsModalQuery(\n  $teamId: ID!\n) {\n  team(id: $teamId) {\n    ...inviteCollaboratorsModal_team_2EIa4l\n    id\n  }\n}\n\nfragment inviteCollaboratorsModal_team_2EIa4l on Team {\n  rowId\n  name\n  account {\n    id\n  }\n  organization {\n    organizationCollaboratorsLicensesLeft\n    providers\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab724985baa2c2d3ac6fc47801bfce1f';

module.exports = node;
