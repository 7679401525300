import React, { useState } from 'react';
import injectSheet from 'react-jss';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import {
  FieldTextCompact,
  Modal,
  ModalActions,
  ModalContent,
  Pushbutton
} from '@stratumn/atomic';
import * as Sentry from '@sentry/react';
import to from 'await-to-js';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';
import { SpanType, withSpanAsync } from 'tracing';

import { addTeam } from 'mutations';

import { withGqlClient, withUser } from 'wrappers';
import { downloadCsv } from 'utils/downloadCsv';
import { downloadXls } from 'utils/downloadXls';
import TypeFileViewsMenu from 'components/ui/typeFileViewsMenu';
import styles from './teamsHeader.style';

const INITIAL_STATE = {
  name: '',
  submitted: false,
  showModal: false
};

export const TeamsHeader = props => {
  const [state, setState] = useState(INITIAL_STATE);
  const [isExportOptionShown, setIsExportOptionShown] = useState(false);

  const toggleModal = () =>
    state.showModal
      ? setState(prevState => ({
          ...prevState,
          showModal: false,
          name: ''
        }))
      : setState(prevState => ({
          ...prevState,
          showModal: true
        }));

  const handleChange = name => e =>
    setState(prevState => ({
      ...prevState,
      [name]: e.target.value
    }));

  const resetState = () => setState(INITIAL_STATE);

  const submitNewTeam = () =>
    withSpanAsync('addTeam', SpanType.processing, async () => {
      const { environment, organization: { id, rowId } } = props;

      const { name } = state;

      setState(prevState => ({
        ...prevState,
        submitted: true
      }));

      /*
        In order for the mutation to create the new Team, we need to leave
        ** empty strings ** for the description and the avatar fields.
      */
      const [err] = await to(addTeam(environment, id, rowId, name, '', ''));

      if (err) {
        Sentry.captureException(err);
        setState(prevState => ({
          ...prevState,
          submitted: false
        }));
      } else {
        resetState();
      }

      return {};
    });

  const handleDownload = fileType => {
    const { headerTeams } = props.organization;
    const headers = [
      'Team Name',
      'User Name',
      'User Email',
      'Collaborator',
      'Bot'
    ];
    const rows = headerTeams.edges.flatMap(edge => {
      const teamName = edge.node.name;
      const teamMembers = edge.node.account.members.nodes;
      const teamBots = edge.node.bots.nodes;

      if (teamMembers.length === 0 && teamBots.length === 0) {
        // Handle cases where a team has no members or bots
        return [[teamName, '', '', '', '']];
      }

      const memberRows = teamMembers.map(member => [
        teamName,
        member.name,
        member.email,
        member.collaborator || false,
        false
      ]);
      const botRows = teamBots.map(bot => [
        teamName,
        bot.name,
        '',
        false,
        true
      ]);

      return memberRows.concat(botRows);
    });

    if (fileType === 'csv') {
      downloadCsv(headers, rows, 'members.csv');
    } else if (fileType === 'excel') {
      downloadXls(headers, rows, 'members.xls');
    }
  };

  const toggleShowExportOption = () => {
    setIsExportOptionShown(prev => !prev);
  };

  const { classes, organization: { account: { canUpdate } } } = props;
  const { name, submitted, showModal } = state;

  return ReactDOM.createPortal(
    <>
      <div className={classes.buttonContainer}>
        <Pushbutton secondary onClick={toggleShowExportOption}>
          Export data
        </Pushbutton>
        {canUpdate && (
          <Pushbutton primary onClick={toggleModal}>
            Create New Team
          </Pushbutton>
        )}
        {isExportOptionShown && (
          <TypeFileViewsMenu
            onDownload={handleDownload}
            onClose={toggleShowExportOption}
          />
        )}
      </div>
      {showModal && (
        <Modal
          title="Create new Team"
          handleCollapse={toggleModal}
          closeButtonLabel="Cancel"
        >
          <ModalContent>
            <FieldTextCompact
              label="Team name"
              value={name}
              onValueChange={handleChange('name')}
            />
          </ModalContent>
          <ModalActions>
            <Pushbutton onClick={toggleModal}>cancel</Pushbutton>
            <Pushbutton
              primary
              disabled={!name || submitted}
              onClick={submitNewTeam}
            >
              create team
            </Pushbutton>
          </ModalActions>
        </Modal>
      )}
    </>,
    document.getElementById('btn-create-new-team')
  );
};

TeamsHeader.propTypes = {
  organization: PropTypes.object.isRequired
};

export default createFragmentContainer(
  compose(withUser, withGqlClient, injectSheet(styles))(TeamsHeader),
  {
    organization: graphql`
      fragment teamsHeader_organization on Organization {
        id # in order to update the store
        rowId
        account {
          canUpdate
        }
        headerTeams: teams(orderBy: $orderBy) {
          edges {
            node {
              id
              rowId
              name
              account {
                members {
                  totalCount
                  nodes {
                    collaborator
                    email
                    name
                  }
                }
              }
              bots {
                totalCount
                nodes {
                  name
                }
              }
            }
          }
        }
      }
    `
  }
);
