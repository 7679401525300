export default theme => ({
  root: {
    position: 'relative',
    color: theme.grey3,
    appearance: 'none',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.grey3,
    borderRadius: '50%',
    width: 18,
    height: 18,
    boxSizing: 'border-box',
    cursor: 'default',
    outline: 'none',
    display: 'block',
    boxShadow: `0 0 0 0 ${theme.indigoPastel2}`,
    transition: `box-shadow 150ms linear`,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 34,
      height: 34,
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)'
    },
    '&[disabled]': {
      opacity: 0.3
    },
    '&:focus': {
      boxShadow: `0 0 0 3px ${theme.indigoPastel2}`
    },
    '&:not([disabled]):hover': {
      cursor: 'pointer',
      color: theme.white1,
      backgroundColor: theme.grey3
    },
    '& > svg': {
      display: 'block',
      width: '100%',
      height: '100%',
      stroke: 'currentColor'
    }
  }
});
