// copies the public key to the clipboard
// see: https://stackoverflow.com/a/30810322
export default (text, n = null) => {
  const textArea = document.createElement('textarea');
  textArea.style = {
    width: 0,
    height: 0,
    opacity: 0
  };
  textArea.value = text;
  const node = n || document.body;
  node.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  node.removeChild(textArea);
};
