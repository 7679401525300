import { commitMutation } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation editAccountMembershipTeamMutation(
    $input: EditAccountMembershipInput!
    $teamRowId: BigInt!
  ) {
    editAccountMembership(input: $input) {
      boolean
      query {
        teamByRowId(rowId: $teamRowId) {
          organization {
            organizationMemberLicensesLeft
          }
        }
      }
    }
  }
`;

// This mutation needs both the accountId and the accountRowId. The acountRowId
// is used for the mutation itself and the accountId is used to update the
// record in the store.
export default (
  environment,
  teamRowId,
  role,
  userRowId,
  accountId,
  accountRowId,
  grantOrRevoke,
  connectionKey // Fragment connection key in order to update the relay store
) =>
  withSpanAsync('editAccountMembershipTeam', SpanType.mutation, async () => {
    const variables = {
      input: {
        _role: role,
        _userId: userRowId,
        _accountId: accountRowId,
        grantOrRevoke
      },
      teamRowId
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        console.log('Response received from server: ', response, errors);
      },
      onError: err => {
        console.error(err);
      },
      updater: store => {
        const payload = store.getRootField('editAccountMembership');
        // If the mutation is successful we need to update the store so that
        // the permission is updated visually.
        if (payload && payload.getValue('boolean')) {
          // Get the effected user from the account object.
          const account = store.get(accountId);

          /* 
          No need to pass in the filters to getConnection.
          However, we need to fetch the 'edges'
        */

          const members = ConnectionHandler.getConnection(
            account,
            connectionKey
          );
          const edges = members.getLinkedRecords('edges');

          const user = edges
            .map(edge => edge.getLinkedRecord('node'))
            .find(node => node.getValue('userId') === userRowId);

          // Set the correct value for the correct role based on mutation args.
          const targetField = role === 'ADMIN' ? 'admin' : 'reader';
          const targetValue = grantOrRevoke === 'GRANT';
          user.setValue(targetValue, targetField);

          // If the user removes there own admin access update the canUpdate
          // property in the associated account to update the UI.
          const loggedInUserRowId = store
            .getRoot()
            .getLinkedRecord('me')
            .getValue('rowId');
          if (
            role === 'ADMIN' &&
            grantOrRevoke === 'REVOKE' &&
            userRowId === loggedInUserRowId
          ) {
            account.setValue(false, 'canUpdate');
          }
        }
      }
    });
  });
