import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Modal,
  ModalActions,
  ModalContent,
  Pushbutton,
  Prose
} from '@stratumn/atomic';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import * as Sentry from '@sentry/react';
import { withGqlClient } from 'wrappers';

import { removeTeamMember } from 'mutations';
import to from 'await-to-js';
import { SpanType, withSpanAsync } from 'tracing';

export const RemoveTeamCollaboratorModal = props => {
  const [submitted, setSubmit] = useState(false);

  const { environment, collaborator, onClose } = props;
  const { teams: { nodes: [team] } } = collaborator;

  const removeCollaboratorGp = () =>
    withSpanAsync(`removeTeamMember`, SpanType.processing, async () => {
      setSubmit(true);

      const [err] = await to(
        removeTeamMember(
          environment,
          collaborator.id,
          collaborator.rowId,
          team.id,
          team.rowId,
          team.organization.rowId
        )
      );

      if (err) {
        Sentry.captureException(err);
        setSubmit(false);
      } else {
        onClose();
      }

      return {};
    });

  return (
    <Modal
      title="Remove from Team"
      handleCollapse={onClose}
      closeButtonLabel="Cancel"
    >
      <ModalContent>
        <Prose
          light
          text={`This action will remove **${collaborator.name}** from the **${
            team.name
          }**`}
        />
      </ModalContent>
      <ModalActions>
        <Pushbutton onClick={onClose}>cancel</Pushbutton>
        <Pushbutton warning onClick={removeCollaboratorGp} disabled={submitted}>
          Remove from team
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

RemoveTeamCollaboratorModal.propTypes = {
  environment: PropType.object.isRequired,
  collaborator: PropType.object.isRequired,
  onClose: PropType.func.isRequired
};

export default createFragmentContainer(
  withGqlClient(RemoveTeamCollaboratorModal),
  {
    collaborator: graphql`
      fragment removeTeamCollaboratorModal_collaborator on User
        @argumentDefinitions(
          teamFilter: { type: "TeamFilter", defaultValue: {} }
        ) {
        name
        id
        rowId
        teams(filter: $teamFilter) {
          nodes {
            id
            rowId
            name
            organization {
              rowId
            }
          }
        }
      }
    `
  }
);
