export default theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.headerHeight,
    backgroundColor: theme.greyBackground1,
    borderBottom: `1px solid ${theme.greyBorder1}`,
    padding: '0 32px',
    '& > div': {
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    }
  },
  left: {
    flexGrow: 1
  },
  logo: {
    height: 23
  }
});
