/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type addTeamMembersModal_team$ref: FragmentReference;
declare export opaque type addTeamMembersModal_team$fragmentType: addTeamMembersModal_team$ref;
export type addTeamMembersModal_team = {|
  +organization: ?{|
    +account: ?{|
      +members: {|
        +nodes: $ReadOnlyArray<?{|
          +user: ?{|
            +id: string,
            +rowId: any,
            +email: string,
            +name: string,
            +avatar: ?string,
          |}
        |}>
      |}
    |}
  |},
  +account: ?{|
    +id: string
  |},
  +rowId: any,
  +name: string,
  +$refType: addTeamMembersModal_team$ref,
|};
export type addTeamMembersModal_team$data = addTeamMembersModal_team;
export type addTeamMembersModal_team$key = {
  +$data?: addTeamMembersModal_team$data,
  +$fragmentRefs: addTeamMembersModal_team$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamAccountRowId",
      "type": "BigInt!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "addTeamMembersModal_team",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            {
              "alias": "members",
              "args": [
                {
                  "kind": "Variable",
                  "name": "accountRowId",
                  "variableName": "teamAccountRowId"
                }
              ],
              "concreteType": "MembersConnection",
              "kind": "LinkedField",
              "name": "membersOutsideEntity",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Member",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "email",
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "avatar",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/)
  ],
  "type": "Team"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aada453c0addd017706bd9a1b955cbbe';

module.exports = node;
