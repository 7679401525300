/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type removeTeamMemberModal_member$ref: FragmentReference;
declare export opaque type removeTeamMemberModal_member$fragmentType: removeTeamMemberModal_member$ref;
export type removeTeamMemberModal_member = {|
  +name: string,
  +id: string,
  +rowId: any,
  +teams: {|
    +nodes: $ReadOnlyArray<?{|
      +id: string,
      +rowId: any,
      +name: string,
      +organization: ?{|
        +rowId: any
      |},
    |}>
  |},
  +$refType: removeTeamMemberModal_member$ref,
|};
export type removeTeamMemberModal_member$data = removeTeamMemberModal_member;
export type removeTeamMemberModal_member$key = {
  +$data?: removeTeamMemberModal_member$data,
  +$fragmentRefs: removeTeamMemberModal_member$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "teamFilter",
      "type": "TeamFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "removeTeamMemberModal_member",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "teamFilter"
        }
      ],
      "concreteType": "TeamsConnection",
      "kind": "LinkedField",
      "name": "teams",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Team",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "organization",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dcfeb71b74221e215026fa45978963a3';

module.exports = node;
