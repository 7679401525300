import React, { useState } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import {
  Modal,
  ModalActions,
  ModalContent,
  Prose,
  Pushbutton
} from '@stratumn/atomic';
import compose from 'lodash.flowright';
import * as Sentry from '@sentry/react';
import { withGqlClient } from 'wrappers';

import { removeOrgBot } from 'mutations';
import to from 'await-to-js';
import { SpanType, withSpanAsync } from 'tracing';

import { ROUTE_BOTS } from 'constant/routes';

import styles from './removeOrgBotModal.style';

export const RemoveOrgBotModal = ({
  classes,
  environment,
  bot,
  toggleModal,
  history
}) => {
  const [submitted, setSubmit] = useState(false);

  const { name, rowId, organization, teams } = bot;

  const removeBotOrg = () => {
    withSpanAsync(`removeOrgBot`, SpanType.processing, async () => {
      setSubmit(true);

      const [err] = await to(
        removeOrgBot(environment, rowId, organization.rowId)
      );

      if (err) {
        Sentry.captureException(err);
        setSubmit(false);
      } else {
        history.push(ROUTE_BOTS);
      }

      return {};
    });
  };

  const modalDescriptionText = `This action will remove **${name ||
    rowId}** from **${organization.name}**${
    teams.totalCount > 0 ? ' and the following teams' : ''
  }`;

  return (
    <Modal
      title="Remove from organization"
      handleCollapse={toggleModal}
      closeButtonLabel="Cancel"
    >
      <ModalContent>
        <Prose light text={modalDescriptionText} />
        <ul className={classes.teamList}>
          {teams.nodes.map(n => (
            <li className={classes.teamListItems} key={n.id}>
              {n.name}
            </li>
          ))}
        </ul>
      </ModalContent>
      <ModalActions>
        <Pushbutton onClick={toggleModal}>cancel</Pushbutton>
        <Pushbutton warning onClick={removeBotOrg} disabled={submitted}>
          Remove from organization
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

RemoveOrgBotModal.propTypes = {
  classes: PropType.object.isRequired,
  environment: PropType.object.isRequired,
  history: PropType.object.isRequired,
  bot: PropType.object.isRequired,
  toggleModal: PropType.func.isRequired
};

export default compose(withRouter, withGqlClient, injectSheet(styles))(
  RemoveOrgBotModal
);
