/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type botsHeader_organization$ref: FragmentReference;
declare export opaque type botsHeader_organization$fragmentType: botsHeader_organization$ref;
export type botsHeader_organization = {|
  +id: string,
  +rowId: any,
  +account: ?{|
    +canUpdate: ?boolean
  |},
  +headerBots: {|
    +edges: $ReadOnlyArray<{|
      +node: ?{|
        +id: string,
        +rowId: any,
        +name: string,
        +teams: {|
          +totalCount: number,
          +nodes: $ReadOnlyArray<?{|
            +name: string
          |}>,
        |},
      |}
    |}>
  |},
  +$refType: botsHeader_organization$ref,
|};
export type botsHeader_organization$data = botsHeader_organization;
export type botsHeader_organization$key = {
  +$data?: botsHeader_organization$data,
  +$fragmentRefs: botsHeader_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "botsHeader_organization",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canUpdate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "headerBots",
      "args": null,
      "concreteType": "BotsConnection",
      "kind": "LinkedField",
      "name": "bots",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BotsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Bot",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamsConnection",
                  "kind": "LinkedField",
                  "name": "teams",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Team",
                      "kind": "LinkedField",
                      "name": "nodes",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e01959e77a8499cf9211db6bc1a52fb9';

module.exports = node;
