export default theme => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 5,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.grey6}`,
      paddingBottom: 5
    }
  }
});
