import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { ROUTE_MY_PROFILE, ROUTE_ORG } from 'constant/routes';
import injectSheet from 'react-jss';
import PropType from 'prop-types';
import styles from './pageNavigation.style';

export const PageNavigation = ({ classes, user }) => {
  const MY_PROFILE_LINK = { name: 'My Profile', path: ROUTE_MY_PROFILE };
  const ORGANIZATION_LINK = { name: 'Organization', path: ROUTE_ORG };
  const PAGE_LINKS = [MY_PROFILE_LINK];

  if (user.organizations && user.organizations.nodes.length > 0) {
    PAGE_LINKS.push(ORGANIZATION_LINK);
  }

  return (
    <section className={classes.container}>
      {PAGE_LINKS.map(pageLink => (
        <NavLink
          to={pageLink.path}
          className={classes.pageLink}
          exact={pageLink.path === ROUTE_MY_PROFILE}
          draggable="false"
          key={pageLink.path}
        >
          <p className={classes.linkName}>{pageLink.name}</p>
          <span className={classes.underLine} />
        </NavLink>
      ))}
    </section>
  );
};

PageNavigation.propTypes = {
  classes: PropType.object.isRequired,
  user: PropType.object.isRequired
};

export default withRouter(injectSheet(styles)(PageNavigation));
