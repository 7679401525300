export default theme => ({
  title: {
    marginBottom: 16,
    fontSize: 14,
    color: theme.grey2
  },
  teamName: {
    fontWeight: 800
  },
  botsList: {
    backgroundColor: theme.grey8,
    borderRadius: 2,
    maxHeight: 250,
    overflow: 'scroll',
    paddingTop: 6,
    paddingRight: 12,
    paddingLeft: 12
  }
});
