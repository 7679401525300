import to from 'await-to-js';
import qs from 'query-string';

import { ROUTE_LOGIN, ROUTE_UNAUTHORIZED } from 'constant/routes';
import { withSpanAsync, SpanType } from 'tracing';
import { setLoginRedirect } from 'utils/loginRedirect';
import { toast } from 'sonner/dist';

export class FetchError extends Error {
  constructor(status, ...params) {
    super(...params);

    this.status = status;
  }
}

export default class {
  constructor(history) {
    this.history = history;
  }

  fetch = async (url, fetchOptions, errorOptions = {}) =>
    withSpanAsync('fetch', SpanType.outgoingRequest, async () => {
      const redirectOn403 = errorOptions.redirectOn403 !== false;
      const displayErrorMessage = errorOptions.displayErrorMessage !== false;

      let error;
      const [err, rsp] = await to(fetch(url, fetchOptions));
      if (err) {
        error = new FetchError(null, 'Unable to reach server');
        if (displayErrorMessage) toast.error(error.message);
        throw error;
      }
      // Parse the message and status from the HTTP response. This is not straight
      // forward as we need to account for HTTP errors and graphql errors.
      let message;
      let status;
      let path;
      const [jsonErr, jsonRsp] = await to(rsp.json());
      if (!jsonErr && jsonRsp && jsonRsp.errors) {
        // If the response body contains an errors field it means there was an error
        // even if the HTTP response is 200. This is the case for graphql errors.
        [{ message, path, status }] = jsonRsp.errors;
        if (
          path &&
          path.length > 0 &&
          (path[0] === 'createTeam' || path[0] === 'updateTeamByRowId') &&
          message.includes('duplicate')
        ) {
          status = 409;
        }
      } else {
        // If it is not a graphql error use the HTTP status code.
        ({ status } = rsp);
      }

      if (status >= 400) {
        let display = displayErrorMessage;
        switch (status) {
          case 401:
            // Store the intended page in the react-router state so we can navigate the user
            // back there after they log in.
            setLoginRedirect(window.location.pathname);
            this.history.push(ROUTE_LOGIN);
            error = new FetchError(401, 'Please login to access this content');
            break;
          case 403:
            if (redirectOn403) this.history.push(ROUTE_UNAUTHORIZED);
            display = displayErrorMessage && !redirectOn403;
            error = new FetchError(
              403,
              "You're not authorized to perform this action"
            );
            break;
          case 409:
            error = new FetchError(status, 'Team already exists');
            break;
          default: {
            if (status >= 500 || !message) {
              error = new FetchError(status, 'Something went wrong');
            } else {
              error = new FetchError(status, message);
            }
          }
        }

        if (display) toast.error(error.message);

        throw error;
      }
      if (rsp.status === 204) {
        return undefined;
      }

      return jsonRsp;
    });

  get = async (endpoint, data) => {
    let search = '';
    if (data) search = `?${qs.stringify(data)}`;

    return this.fetch(`${endpoint}${search}`, {
      method: 'GET',
      credentials: 'include'
    });
  };

  post = async (endpoint, data, headers) =>
    this.fetch(endpoint, {
      method: 'POST',
      headers: {
        ...headers,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(data)
    });
}
