import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation createUserSigningKeyMutation($input: CreateUserSigningKeyInput!) {
    createUserSigningKey(input: $input) {
      key {
        id
        rowId
        usage
        createdAt
        publicKey
        deprecated
        privateKey {
          decrypted
          passwordProtected
        }
      }
    }
  }
`;

export default (
  environment,
  accountId,
  userRowId,
  publicKey,
  privateKey,
  passwordProtected
) =>
  withSpanAsync('createUserSigningKey', SpanType.mutation, async () => {
    const variables = {
      input: {
        userRowId,
        publicKey,
        privateKey,
        passwordProtected
      }
    };

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);
          resolve();
        },
        onError: err => {
          console.error(err);

          reject(err);
        },
        updater: store => {
          const payload = store.getRootField('createUserSigningKey');
          const key = payload.getLinkedRecord('key');
          const account = store.get(accountId);

          account.setLinkedRecord(key, 'signingKey');
        }
      });
    });
  });
