import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import { Pushbutton } from '@stratumn/atomic';

import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { withGqlClient, withUser } from 'wrappers';
import compose from 'lodash.flowright';

import { downloadCsv } from 'utils/downloadCsv';
import { downloadXls } from 'utils/downloadXls';
import TypeFileViewsMenu from 'components/ui/typeFileViewsMenu';
import InviteOrgMemberModal from '../inviteOrgMemberModal';
import styles from './peopleHeader.style';

export const PeopleHeader = props => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [isExportOptionShown, setIsExportOptionShown] = useState(false);

  const toggleModal = () => setIsModalShown(prev => !prev);
  const handleDownload = fileType => {
    const { headerMembers } = props.organization.account;

    const sortedMembers = [...headerMembers.edges].sort((a, b) =>
      a.node.user.name.localeCompare(b.node.user.name)
    );

    const headers = [
      'Member Name',
      'Member Email',
      'Owner',
      'Leader',
      'Teams Name'
    ];

    const rows = sortedMembers.flatMap(member => {
      const teamNames = member.node.user.teams.nodes.map(team => team.name);
      if (teamNames.length === 0) {
        return [
          [
            member.node.user.name,
            member.node.user.email,
            member.node.admin,
            member.node.leader,
            '' // Empty string for Team Name when there are no teams
          ]
        ];
      }
      return teamNames.map(teamName => [
        member.node.user.name,
        member.node.user.email,
        member.node.admin,
        member.node.leader,
        teamName
      ]);
    });

    if (fileType === 'csv') {
      downloadCsv(headers, rows, 'members.csv');
    } else if (fileType === 'excel') {
      downloadXls(headers, rows, 'members.xls');
    }
  };

  const toggleShowExportOption = () => {
    setIsExportOptionShown(prev => !prev);
  };

  const { classes, organization: { canUpdate } } = props;
  const { organization } = props;

  return ReactDOM.createPortal(
    <>
      <div className={classes.buttonContainer}>
        <Pushbutton secondary onClick={toggleShowExportOption}>
          Export data
        </Pushbutton>
        {canUpdate && (
          <Pushbutton primary onClick={toggleModal}>
            invite organization members
          </Pushbutton>
        )}
        {isExportOptionShown && (
          <TypeFileViewsMenu
            onDownload={handleDownload}
            onClose={toggleShowExportOption}
          />
        )}
        {isModalShown && (
          <InviteOrgMemberModal
            organization={organization}
            toggleModal={toggleModal}
          />
        )}
      </div>
    </>,
    document.getElementById('btn-invite-org-member')
  );
};

PeopleHeader.propTypes = {
  organization: PropTypes.object.isRequired
};

export default createFragmentContainer(
  compose(withUser, withGqlClient, injectSheet(styles))(PeopleHeader),
  {
    organization: graphql`
      fragment peopleHeader_organization on Organization {
        ...inviteOrgMemberModal_organization
        canUpdate
        id
        rowId
        organizationOwnerLicensesLeft
        isLeader
        account {
          id
          rowId
          headerMembers: members {
            totalCount
            edges {
              node {
                admin
                leader
                userId
                collaborator
                user {
                  id
                  rowId
                  name
                  email
                  pending
                  inviteExpired
                  teams(filter: $teamFilter) {
                    totalCount
                    nodes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  }
);
