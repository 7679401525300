import { commitMutation } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import graphql from 'babel-plugin-relay/macro';
import { SpanType, withSpanAsync } from 'tracing';
import { MEMBER_KEY } from 'constant/types';

const mutation = graphql`
  mutation addOrganizationMembersMutation(
    $input: AddOrganizationMembersInput!
    $teamFilter: TeamFilter
  ) {
    addOrganizationMembers(input: $input) {
      members {
        reader
        admin
        userId
        user {
          rowId
          accountId
          email
          name
          avatar
          pending
          teams(filter: $teamFilter) {
            totalCount
          }
        }
      }
    }
  }
`;

export default (environment, accountId, organizationRowId, members) =>
  withSpanAsync('addOrganizationMembers', SpanType.mutation, async () => {
    const variables = {
      input: {
        organizationRowId,
        members
      },
      teamFilter: {
        organizationId: { equalTo: organizationRowId }
      }
    };

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);

          resolve();
        },
        onError: err => {
          console.error(err);
          reject(err);
        },
        updater: store => {
          const payload = store.getRootField('addOrganizationMembers');
          const newMembers = payload.getLinkedRecords('members');

          const account = store.get(accountId);
          const oldMembers = account.getLinkedRecord('members');
          const totalCount = oldMembers.getValue('totalCount');

          /* 
          We don't need to pass in the filters to getConnection.
          Since, we're using filters, we now need to fetch the 'edges', not the 'nodes'
        */
          const membersConnection = ConnectionHandler.getConnection(
            account,
            MEMBER_KEY
          );

          // Planning to add more than one member at a time.
          newMembers.forEach(member => {
            const edge = ConnectionHandler.createEdge(
              store,
              membersConnection,
              member,
              'MembersEdge' // type
            );

            ConnectionHandler.insertEdgeAfter(membersConnection, edge);
          });

          // Update the old members count
          oldMembers.setValue(totalCount + newMembers.length, 'totalCount');
          membersConnection.setValue(
            totalCount + newMembers.length,
            'totalCount'
          );
        }
      });
    });
  });
