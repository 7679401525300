import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import styles from './options.style';

export const OptionsContext = React.createContext({});

export { default as Option } from './option';

export class Options extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired,
    children: PropType.node.isRequired,
    onSelect: PropType.func.isRequired,
    selected: PropType.oneOfType([PropType.string, PropType.number]),
    className: PropType.string
  };

  static defaultProps = {
    selected: null,
    className: null
  };

  select = id => {
    this.props.onSelect(id);
  };

  render() {
    const { classes, className, children, selected } = this.props;
    return (
      <OptionsContext.Provider value={{ select: this.select, selected }}>
        <div className={classNames(classes.root, className)}>{children}</div>
      </OptionsContext.Provider>
    );
  }
}

export default injectSheet(styles)(Options);
