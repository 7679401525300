/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type addTeamMembersModal_team$ref = any;
export type addTeamMembersModalQueryVariables = {|
  teamId: string,
  teamAccountRowId: any,
|};
export type addTeamMembersModalQueryResponse = {|
  +team: ?{|
    +$fragmentRefs: addTeamMembersModal_team$ref
  |}
|};
export type addTeamMembersModalQuery = {|
  variables: addTeamMembersModalQueryVariables,
  response: addTeamMembersModalQueryResponse,
|};
*/


/*
query addTeamMembersModalQuery(
  $teamId: ID!
  $teamAccountRowId: BigInt!
) {
  team(id: $teamId) {
    ...addTeamMembersModal_team_2EIa4l
    id
  }
}

fragment addTeamMembersModal_team_2EIa4l on Team {
  organization {
    account {
      members: membersOutsideEntity(accountRowId: $teamAccountRowId) {
        nodes {
          user {
            id
            rowId
            email
            name
            avatar
          }
        }
      }
      id
    }
    id
  }
  account {
    id
  }
  rowId
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamAccountRowId",
    "type": "BigInt!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "teamId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addTeamMembersModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "teamAccountRowId",
                "variableName": "teamAccountRowId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "addTeamMembersModal_team"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addTeamMembersModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": "members",
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "accountRowId",
                        "variableName": "teamAccountRowId"
                      }
                    ],
                    "concreteType": "MembersConnection",
                    "kind": "LinkedField",
                    "name": "membersOutsideEntity",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Member",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatar",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "addTeamMembersModalQuery",
    "operationKind": "query",
    "text": "query addTeamMembersModalQuery(\n  $teamId: ID!\n  $teamAccountRowId: BigInt!\n) {\n  team(id: $teamId) {\n    ...addTeamMembersModal_team_2EIa4l\n    id\n  }\n}\n\nfragment addTeamMembersModal_team_2EIa4l on Team {\n  organization {\n    account {\n      members: membersOutsideEntity(accountRowId: $teamAccountRowId) {\n        nodes {\n          user {\n            id\n            rowId\n            email\n            name\n            avatar\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n  account {\n    id\n  }\n  rowId\n  name\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '287abbda577671a914275fd9640da03a';

module.exports = node;
