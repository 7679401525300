export default theme => ({
  tyleFileViewsMenuUnderlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1000
  },
  typeFileViewsMenuContainer: {
    position: 'absolute',
    top: '42px',
    left: '-74px',
    width: '200px',
    backgroundColor: 'white',
    boxShadow: theme.indigoShadow2,
    borderRadius: '6px',
    zIndex: 1001
  },
  typeFileViewsMenuRadioButtons: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-around'
  },
  typeFileViewsButton: {
    display: 'block',
    margin: 5,
    '& button': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& span': {
        display: 'flex',
        alignItems: 'center'
      }
    }
  },
  typeFileViewsButtonIcon: {
    height: 22
  }
});
