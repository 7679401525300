import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation removeTeamBotMutation($input: RemoveBotFromTeamInput!) {
    removeBotFromTeam(input: $input) {
      bot {
        id
        rowId
        name
        teams {
          nodes {
            id
            rowId
            name
          }
        }
      }
    }
  }
`;

export default (environment, botId, botRowId, teamId, teamRowId) =>
  withSpanAsync('removeBotFromTeam', SpanType.mutation, async () => {
    const variables = {
      input: {
        botRowId,
        teamRowId
      }
    };

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);

          resolve();
        },
        onError: err => {
          console.error(err);
          reject(err);
        },
        updater: store => {
          const payload = store.getRootField('removeBotFromTeam');
          if (payload && payload.getValue('boolean')) {
            const bot = store.get(botId);

            // Need to pass the same filter used within our component's fragment.
            const teams = bot
              .getLinkedRecord('teams')
              .getLinkedRecords('nodes');

            const newTeams = teams.filter(n => n.getValue('id') !== teamId);

            // Updating our store
            teams.setLinkedRecords(newTeams, 'nodes');
            teams.setValue(teams.getValue('totalCount') - 1, 'totalCount');
          }
        }
      });
    });
  });
