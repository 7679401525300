/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Provider = "CNP" | "COGNITO" | "GOOGLE" | "KEYCLOAK" | "MICROSOFT_SIA_PARTNERS" | "ONE_ACCOUNT" | "ONE_ACCOUNT_AXA" | "PASS_AXA" | "STRATUMN" | "%future added value";
export type RoleType = "ADMIN" | "LEADER" | "READER" | "%future added value";
export type AddOrganizationMembersInput = {|
  clientMutationId?: ?string,
  organizationRowId?: ?any,
  members?: ?$ReadOnlyArray<?OrganizationMemberInviteInput>,
|};
export type OrganizationMemberInviteInput = {|
  email?: ?string,
  role?: ?RoleType,
  allowedProviders?: ?$ReadOnlyArray<?Provider>,
  userid?: ?number,
|};
export type TeamFilter = {|
  rowId?: ?BigIntFilter,
  name?: ?StringFilter,
  description?: ?StringFilter,
  avatar?: ?StringFilter,
  organizationId?: ?BigIntFilter,
  accountId?: ?BigIntFilter,
  createdAt?: ?DatetimeFilter,
  updatedAt?: ?DatetimeFilter,
  email?: ?StringFilter,
  canInsert?: ?BooleanFilter,
  canUpdate?: ?BooleanFilter,
  and?: ?$ReadOnlyArray<TeamFilter>,
  or?: ?$ReadOnlyArray<TeamFilter>,
  not?: ?TeamFilter,
|};
export type BigIntFilter = {|
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
|};
export type StringFilter = {|
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  similarTo?: ?string,
  notSimilarTo?: ?string,
|};
export type DatetimeFilter = {|
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
|};
export type BooleanFilter = {|
  isNull?: ?boolean,
  equalTo?: ?boolean,
  notEqualTo?: ?boolean,
  distinctFrom?: ?boolean,
  notDistinctFrom?: ?boolean,
  in?: ?$ReadOnlyArray<boolean>,
  notIn?: ?$ReadOnlyArray<boolean>,
|};
export type addOrganizationMembersMutationVariables = {|
  input: AddOrganizationMembersInput,
  teamFilter?: ?TeamFilter,
|};
export type addOrganizationMembersMutationResponse = {|
  +addOrganizationMembers: ?{|
    +members: ?$ReadOnlyArray<?{|
      +reader: ?boolean,
      +admin: ?boolean,
      +userId: ?any,
      +user: ?{|
        +rowId: any,
        +accountId: any,
        +email: string,
        +name: string,
        +avatar: ?string,
        +pending: ?boolean,
        +teams: {|
          +totalCount: number
        |},
      |},
    |}>
  |}
|};
export type addOrganizationMembersMutation = {|
  variables: addOrganizationMembersMutationVariables,
  response: addOrganizationMembersMutationResponse,
|};
*/


/*
mutation addOrganizationMembersMutation(
  $input: AddOrganizationMembersInput!
  $teamFilter: TeamFilter
) {
  addOrganizationMembers(input: $input) {
    members {
      reader
      admin
      userId
      user {
        rowId
        accountId
        email
        name
        avatar
        pending
        teams(filter: $teamFilter) {
          totalCount
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddOrganizationMembersInput!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamFilter",
    "type": "TeamFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reader",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pending",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "filter",
      "variableName": "teamFilter"
    }
  ],
  "concreteType": "TeamsConnection",
  "kind": "LinkedField",
  "name": "teams",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addOrganizationMembersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddOrganizationMembersPayload",
        "kind": "LinkedField",
        "name": "addOrganizationMembers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "members",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addOrganizationMembersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddOrganizationMembersPayload",
        "kind": "LinkedField",
        "name": "addOrganizationMembers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "members",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "addOrganizationMembersMutation",
    "operationKind": "mutation",
    "text": "mutation addOrganizationMembersMutation(\n  $input: AddOrganizationMembersInput!\n  $teamFilter: TeamFilter\n) {\n  addOrganizationMembers(input: $input) {\n    members {\n      reader\n      admin\n      userId\n      user {\n        rowId\n        accountId\n        email\n        name\n        avatar\n        pending\n        teams(filter: $teamFilter) {\n          totalCount\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c6eda381b210f282f48cfcead3b236dc';

module.exports = node;
