/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type bot_bot$ref: FragmentReference;
declare export opaque type bot_bot$fragmentType: bot_bot$ref;
export type bot_bot = {|
  +id: string,
  +rowId: any,
  +name: string,
  +description: ?string,
  +avatar: ?string,
  +account: ?{|
    +keys: {|
      +nodes: $ReadOnlyArray<?{|
        +id: string,
        +rowId: any,
        +publicKey: string,
        +deprecated: boolean,
        +createdAt: any,
        +deprecatedAt: ?any,
      |}>
    |}
  |},
  +organization: ?{|
    +id: string,
    +rowId: any,
    +name: string,
    +canUpdate: ?boolean,
  |},
  +teams: {|
    +totalCount: number,
    +nodes: $ReadOnlyArray<?{|
      +id: string,
      +rowId: any,
      +name: string,
      +avatar: ?string,
      +canUpdate: ?boolean,
    |}>,
  |},
  +$refType: bot_bot$ref,
|};
export type bot_bot$data = bot_bot;
export type bot_bot$key = {
  +$data?: bot_bot$data,
  +$fragmentRefs: bot_bot$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUpdate",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "orderKeysBy",
      "type": "[KeysOrderBy!]"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "bot_bot",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "orderBy",
              "variableName": "orderKeysBy"
            }
          ],
          "concreteType": "KeysConnection",
          "kind": "LinkedField",
          "name": "keys",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Key",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "publicKey",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deprecated",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deprecatedAt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeamsConnection",
      "kind": "LinkedField",
      "name": "teams",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Team",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Bot"
};
})();
// prettier-ignore
(node/*: any*/).hash = '2dec84c80ca843ddaa2eb441a8682462';

module.exports = node;
