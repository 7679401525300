export default {
  invitation: {
    marginTop: 10,
    marginBottom: 10,
    display: 'flex'
  },
  pendingInvitations: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400
  },
  pendingInvitation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 5
  }
};
