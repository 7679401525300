import React, { useEffect, useRef, useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import PropType from 'prop-types';
import { createFragmentContainer } from 'react-relay';
import { withRouter, generatePath, Link } from 'react-router-dom';

import injectSheet from 'react-jss';
import compose from 'lodash.flowright';

import nameToInitials from 'utils/nameToInitials';

import {
  AvatarGroup,
  Directory,
  DirectoryRow,
  Pushtext,
  Pushbutton,
  Avatar,
  Collection,
  CollectionItem
} from '@stratumn/atomic';

import { withGqlClient } from 'wrappers';

import { ROUTE_TEAM, ROUTE_EDIT_BOT_PROFILE } from 'constant/routes';

import RemoveOrgBotModal from './removeOrgBotModal';
import RemoveTeamBotModal from './removeTeamBotModal';
import AddBotKeyModal from './addBotKeyModal';
import ExpireKeyModal from './expireKeyModal';
import Keys from './keys';

import styles from './bot.style';

export const Bot = ({ classes, bot }) => {
  const [showModalOrg, setShowModalOrg] = useState(false);
  const [showModalTeam, setShowModalTeam] = useState(false);
  const [showModalAddKey, setShowModalAddKey] = useState(false);
  const [showModalExpireKey, setShowModalExpireKey] = useState(false);
  const [team, setTeam] = useState(null);
  const [expiredKey, setExpiredKey] = useState(null);

  const pkTimeoutRef = useRef(null);

  useEffect(
    () => () => {
      if (pkTimeoutRef.current) {
        clearTimeout(pkTimeoutRef.current);
      }
    },
    []
  );

  const toggleModalOrg = () => setShowModalOrg(prev => !prev);

  const toggleModalTeam = teamBot => {
    setTeam(teamBot);
    setShowModalTeam(prev => !prev);
  };

  const toggleModalAddKey = () => setShowModalAddKey(prev => !prev);

  const toggleModalExpireKey = expiredKeyBot => {
    setExpiredKey(expiredKeyBot);
    setShowModalExpireKey(prev => !prev);
  };

  const renderTeamRow = (node, index) => {
    const { id, rowId, name, avatar, canUpdate } = node;
    const { teams: { totalCount }, organization: { rowId: orgRowId } } = bot;

    return (
      <DirectoryRow
        key={id}
        isLast={index === totalCount - 1}
        main={
          <Link
            to={generatePath(ROUTE_TEAM, { id: rowId, orgId: orgRowId })}
            component={({ navigate }) => (
              <Pushtext onClick={() => navigate()}>
                <div className={classes.link}>
                  <AvatarGroup
                    initials={nameToInitials(name)}
                    nth={Number(rowId)}
                    size={37}
                    src={avatar}
                  />
                  <span className={classes.teamName}>{name}</span>
                </div>
              </Pushtext>
            )}
          />
        }
        cell1={
          canUpdate && (
            <Pushtext onClick={() => toggleModalTeam(node)}>Remove</Pushtext>
          )
        }
      />
    );
  };

  return (
    <>
      <div className={classes.userHeaderWrapper}>
        <div>
          <Avatar size={56} src={bot.avatar} />
        </div>
        {bot.organization.canUpdate && (
          <div className={classes.updateActions}>
            <div className={classes.removeAction}>
              <Pushtext
                mute
                warning
                onClick={toggleModalOrg}
                dataCy="remove-from-org"
              >
                Remove from organization...
              </Pushtext>
            </div>
            <Link
              to={generatePath(ROUTE_EDIT_BOT_PROFILE, { id: bot.rowId })}
              component={({ navigate }) => (
                <Pushbutton
                  onClick={() => navigate()}
                  secondary
                  dataCy="edit-bot"
                >
                  Edit Bot
                </Pushbutton>
              )}
            />
          </div>
        )}
      </div>
      {showModalTeam && (
        <RemoveTeamBotModal
          bot={bot}
          team={team}
          toggleModal={toggleModalTeam}
        />
      )}
      {showModalOrg && (
        <RemoveOrgBotModal bot={bot} toggleModal={toggleModalOrg} />
      )}
      {showModalAddKey && (
        <AddBotKeyModal bot={bot} toggleModal={toggleModalAddKey} />
      )}
      {showModalExpireKey && (
        <ExpireKeyModal
          keyName={expiredKey?.name}
          keyObj={expiredKey?.key}
          toggleModal={toggleModalExpireKey}
        />
      )}
      <Collection>
        <div className={classes.collectionItemFirstChild}>
          <CollectionItem label="bot name" value={bot.name} idle={!bot.name} />
        </div>
        {bot.description && (
          <CollectionItem label="description" value={bot.description} />
        )}
        <div className={classes.teamsList}>
          <Directory showColumnTitles columnTitle="teams">
            {bot.teams.nodes.map((node, index) => renderTeamRow(node, index))}
          </Directory>
        </div>
        <Keys
          keys={bot.account.keys.nodes}
          expireKey={toggleModalExpireKey}
          addKey={toggleModalAddKey}
        />
      </Collection>
    </>
  );
};

Bot.propTypes = {
  classes: PropType.object.isRequired,
  history: PropType.object.isRequired,
  bot: PropType.object.isRequired
};

export default createFragmentContainer(
  compose(withRouter, withGqlClient, injectSheet(styles))(Bot),
  {
    bot: graphql`
      fragment bot_bot on Bot {
        id
        rowId
        name
        description
        avatar
        account {
          keys(orderBy: $orderKeysBy) {
            nodes {
              id
              rowId
              publicKey
              deprecated
              createdAt
              deprecatedAt
            }
          }
        }
        organization {
          id
          rowId
          name
          canUpdate
        }
        teams {
          totalCount
          nodes {
            id
            rowId
            name
            avatar
            canUpdate
          }
        }
      }
    `
  }
);
