import { theme } from '@stratumn/atomic';

export default {
  ...theme,
  greyBackground1: '#fbfbfb',
  greyBorder1: '#e9e8ee',
  greyBorder2: '#d2d0d7',
  greyBorder3: '#e5e3eb',
  orange: '#F5A125',
  orangeText: '#E59113',
  green: '#54C063',
  greenText: '#41AF50',
  inputSpacing: 15,
  headerHeight: 70,
  footerHeight: 40,
  headerTitle: {
    color: theme.grey1,
    fontSize: 20,
    fontWeight: 600,
    '&:not(:first-child)': {
      marginTop: 35
    },
    '&:not(:last-child)': {
      marginBottom: 15
    }
  }
};
