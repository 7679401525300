import React from 'react';
import PropType from 'prop-types';
// source: https://github.com/DominicTobias/react-image-crop
import ReactCrop from 'react-image-crop';

import {
  Modal,
  ModalActions,
  ModalContent,
  Pushbutton
} from '@stratumn/atomic';

import 'react-image-crop/dist/ReactCrop.css';

export class CropDialog extends React.PureComponent {
  static propTypes = {
    onCancel: PropType.func.isRequired,
    onFinish: PropType.func.isRequired,
    imageSrc: PropType.string,
    actionLabel: PropType.string
  };

  static defaultProps = {
    actionLabel: null,
    imageSrc: null
  };

  state = {
    // Make sure image is cropped as a square.
    crop: { aspect: 1 },
    croppedImageUrl: null,
    croppedFile: null,
    cropped: false
  };

  fileName = 'cropped.png';

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onChange = crop => {
    this.setState({ crop, cropped: crop.height > 0 && crop.width > 0 });
  };

  onComplete = crop => {
    if (this.imageRef && crop.width && crop.height) {
      const canvas = document.createElement('canvas');
      const scaleX = this.imageRef.naturalWidth / this.imageRef.width;
      const scaleY = this.imageRef.naturalHeight / this.imageRef.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        this.imageRef,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob(blob => {
        blob.name = this.fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        const fileObj = new File([blob], this.fileName, {
          type: 'image/png'
        });
        this.setState({
          croppedFile: fileObj,
          croppedImageUrl: this.fileUrl
        });
      }, 'image/png');
    }
  };

  onCancel = () => {
    this.props.onCancel();
  };

  onFinish = () => {
    const { croppedFile, croppedImageUrl } = this.state;

    this.props.onFinish({ croppedImageUrl, croppedFile });
  };

  render() {
    const { imageSrc, actionLabel } = this.props;
    const { crop, cropped } = this.state;

    if (!imageSrc) return null;

    return (
      <Modal
        title="Crop your image."
        handleCollapse={this.onCancel}
        closeButtonLabel="Cancel"
      >
        <ModalContent centered>
          <ReactCrop
            src={imageSrc}
            crop={crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onComplete}
            onChange={this.onChange}
          />
        </ModalContent>
        <ModalActions>
          <Pushbutton onClick={this.onCancel}>cancel</Pushbutton>
          <Pushbutton disabled={!cropped} onClick={this.onFinish} primary>
            {actionLabel}
          </Pushbutton>
        </ModalActions>
      </Modal>
    );
  }
}

export default CropDialog;
