import React from 'react';
import PropType from 'prop-types';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';
import { withRouter, Redirect } from 'react-router-dom';

import { withGqlClient, withUser, withSpecificError } from 'wrappers';
import { ROUTE_BOTS } from 'constant/routes';
import { KEYS_ORDER_BY } from 'constant/types';
import { toast } from 'sonner/dist';

import { LoaderTraceLogo } from '@stratumn/atomic';
import Bot from './bot';

const query = graphql`
  query botRootQuery($botRowId: BigInt!, $orderKeysBy: [KeysOrderBy!]) {
    botByRowId(rowId: $botRowId) {
      ...bot_bot
    }
  }
`;

export const Renderer = withSpecificError(
  ({ error, props: data, match: { params } }) => {
    if (!data && !error) return <LoaderTraceLogo />;

    let bot;

    if (data) bot = data.botByRowId;

    if (error || !bot) {
      toast.error(`the bot with the id ${params.id} does not exist.`);
      return <Redirect to={ROUTE_BOTS} />;
    }

    return <Bot bot={bot} />;
  }
);

Renderer.propTypes = {
  error: PropType.object,
  props: PropType.object
};

Renderer.defaultProps = {
  error: undefined,
  props: undefined
};

export const BotRoot = props => {
  const { environment, botRowId } = props;

  const variables = {};
  variables.botRowId = botRowId;
  variables.orderKeysBy = KEYS_ORDER_BY;

  // We need to make sure that the location props are passed to child components correctly.
  // https://github.com/ReactTraining/react-router/blob/v4.2.2/packages/react-router/docs/guides/blocked-updates.md
  const RendererWithRouter = withRouter(Renderer);

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={variables}
      render={RendererWithRouter}
    />
  );
};

BotRoot.propTypes = {
  environment: PropType.object.isRequired,
  botRowId: PropType.string.isRequired
};

BotRoot.defaultProps = {};

export default compose(withUser, withGqlClient)(BotRoot);
