import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import { Avatar, Pushtext } from '@stratumn/atomic';
import { withRouter, generatePath } from 'react-router-dom';
import { ROUTE_COLLABORATOR, ROUTE_MEMBER } from 'constant/routes';

import styles from './userAvatarLink.style';

export class UserAvatarLink extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired,
    history: PropType.object.isRequired,
    rowId: PropType.string.isRequired,
    name: PropType.string.isRequired,
    avatar: PropType.string,
    noLink: PropType.bool,
    collaborator: PropType.bool,
    organizationRowId: PropType.string
  };

  static defaultProps = {
    noLink: false,
    collaborator: false,
    avatar: null
  };

  handleClick = () => {
    const { history, rowId, collaborator, organizationRowId } = this.props;
    history.push(
      generatePath(collaborator ? ROUTE_COLLABORATOR : ROUTE_MEMBER, {
        id: rowId,
        orgId: organizationRowId
      })
    );
  };

  render() {
    const { classes, name, avatar, noLink } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.avatarWrapper}>
          <Avatar className={classes.avatar} src={avatar} size={32} />
        </div>
        {noLink ? (
          <div className={classes.text}>{name}</div>
        ) : (
          <Pushtext onClick={this.handleClick}>{name}</Pushtext>
        )}
      </div>
    );
  }
}

export default withRouter(injectSheet(styles)(UserAvatarLink));
