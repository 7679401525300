import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import styles from './leftPane.style';

export const LeftPane = ({ title, children, classes }) => (
  <div className={classes.container}>
    <div className={classes.title}>{title}</div>
    <div className={classes.rightPane}>{children}</div>
  </div>
);

LeftPane.propTypes = {
  classes: PropType.object.isRequired,
  title: PropType.string.isRequired,
  children: PropType.node.isRequired
};

export default injectSheet(styles)(LeftPane);
