export default () => ({
  formWrapper: {
    width: 346
  },
  avatarWrapper: {
    marginBottom: 47
  },
  fieldWrapper: {
    marginBottom: 25
  },
  formBtnWrapper: {
    display: 'flex'
  },
  formBtnLeft: {
    marginRight: 11
  },
  fileInput: { display: 'none' }
});
