/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditMembershipActionType = "GRANT" | "REVOKE" | "%future added value";
export type RoleType = "ADMIN" | "LEADER" | "READER" | "%future added value";
export type EditAccountMembershipInput = {|
  clientMutationId?: ?string,
  _accountId?: ?any,
  _userId?: ?any,
  grantOrRevoke?: ?EditMembershipActionType,
  _role?: ?RoleType,
|};
export type editAccountMembershipOrgMutationVariables = {|
  input: EditAccountMembershipInput,
  organizationRowId: any,
|};
export type editAccountMembershipOrgMutationResponse = {|
  +editAccountMembership: ?{|
    +boolean: ?boolean,
    +query: ?{|
      +organizationByRowId: ?{|
        +organizationOwnerLicensesLeft: ?number
      |}
    |},
  |}
|};
export type editAccountMembershipOrgMutation = {|
  variables: editAccountMembershipOrgMutationVariables,
  response: editAccountMembershipOrgMutationResponse,
|};
*/


/*
mutation editAccountMembershipOrgMutation(
  $input: EditAccountMembershipInput!
  $organizationRowId: BigInt!
) {
  editAccountMembership(input: $input) {
    boolean
    query {
      organizationByRowId(rowId: $organizationRowId) {
        organizationOwnerLicensesLeft
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "EditAccountMembershipInput!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationRowId",
    "type": "BigInt!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "boolean",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "organizationRowId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationOwnerLicensesLeft",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editAccountMembershipOrgMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditAccountMembershipPayload",
        "kind": "LinkedField",
        "name": "editAccountMembership",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organizationByRowId",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editAccountMembershipOrgMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditAccountMembershipPayload",
        "kind": "LinkedField",
        "name": "editAccountMembership",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organizationByRowId",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "editAccountMembershipOrgMutation",
    "operationKind": "mutation",
    "text": "mutation editAccountMembershipOrgMutation(\n  $input: EditAccountMembershipInput!\n  $organizationRowId: BigInt!\n) {\n  editAccountMembership(input: $input) {\n    boolean\n    query {\n      organizationByRowId(rowId: $organizationRowId) {\n        organizationOwnerLicensesLeft\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9bfa4237c5c34d18575e4b19719d8249';

module.exports = node;
