import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation sendUserInviteMutation($input: SendUserInviteInput!) {
    sendUserInvite(input: $input) {
      userRowId
      accountRowId
    }
  }
`;

/**
 * Depending on the component, accountRowId will be either:
 * organization.account.rowId || team.account.rowId
 *
 * userId is required to update the relay store
 */
export default (environment, userId, userRowId, accountRowId) =>
  withSpanAsync('sendUserInvite', SpanType.mutation, async () => {
    const variables = {
      input: {
        userRowId,
        accountRowId
      }
    };

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);

          resolve();
        },
        onError: err => {
          console.error(err);

          reject(err);
        },
        updater: store => {
          const user = store.get(userId);
          const expired = user.getValue('inviteExpired');

          if (expired) user.setValue(false, 'inviteExpired');
        }
      });
    });
  });
