/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type signedInViewer_superUserOrgs$ref = any;
export type privateRouteOrgsQueryVariables = {||};
export type privateRouteOrgsQueryResponse = {|
  +organizations: ?{|
    +$fragmentRefs: signedInViewer_superUserOrgs$ref
  |}
|};
export type privateRouteOrgsQuery = {|
  variables: privateRouteOrgsQueryVariables,
  response: privateRouteOrgsQueryResponse,
|};
*/


/*
query privateRouteOrgsQuery {
  organizations {
    ...signedInViewer_superUserOrgs
  }
}

fragment signedInViewer_superUserOrgs on OrganizationsConnection {
  nodes {
    id
    rowId
    name
    avatar
  }
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "privateRouteOrgsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationsConnection",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "signedInViewer_superUserOrgs"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "privateRouteOrgsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationsConnection",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rowId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "privateRouteOrgsQuery",
    "operationKind": "query",
    "text": "query privateRouteOrgsQuery {\n  organizations {\n    ...signedInViewer_superUserOrgs\n  }\n}\n\nfragment signedInViewer_superUserOrgs on OrganizationsConnection {\n  nodes {\n    id\n    rowId\n    name\n    avatar\n  }\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = 'b38098facada75e179104cd12ea36fe3';

module.exports = node;
