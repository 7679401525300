import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation updateBotMutation($input: UpdateBotByRowIdInput!) {
    updateBotByRowId(input: $input) {
      bot {
        id
        rowId
        name
        description
        avatar
      }
    }
  }
`;

export default (environment, botRowId, name, description, avatar) =>
  withSpanAsync('updateBotByRowId', SpanType.mutation, async () => {
    // This request is a patch so we do not want to write over the current value
    // with an empty one
    const patch = {
      name,
      description,
      avatar
    };

    const variables = {
      input: {
        rowId: botRowId,
        patch
      }
    };

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);

          resolve();
        },
        onError: err => {
          const error = new Error(`Unable to update bot ${name}`);

          console.error(err);

          reject(error);
        }
      });
    });
  });
