import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation updateTeamMutation($input: UpdateTeamByRowIdInput!) {
    updateTeamByRowId(input: $input) {
      team {
        id
        email
        rowId
        name
        avatar
      }
    }
  }
`;

export default (environment, teamRowId, name, avatar, email) =>
  withSpanAsync('updateTeamByRowId', SpanType.mutation, async () => {
    // This request is a patch so we do not want to write over the current value
    // with an empty one.
    const patch = {};
    if (name) patch.name = name;
    if (avatar) patch.avatar = avatar;
    // enables saving a null email, when deleting the email
    if (email || email === null) patch.email = email;

    const variables = {
      input: {
        rowId: teamRowId,
        patch
      }
    };

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);

          resolve();
        },
        onError: err => {
          const error = new Error(`Unable to update Team ${name}`);

          console.error(err);

          reject(error);
        }
      });
    });
  });
