/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type addTeamBotsModal_team$ref = any;
export type addTeamBotsModalQueryVariables = {|
  teamId: string,
  teamAccountRowId: any,
|};
export type addTeamBotsModalQueryResponse = {|
  +team: ?{|
    +$fragmentRefs: addTeamBotsModal_team$ref
  |}
|};
export type addTeamBotsModalQuery = {|
  variables: addTeamBotsModalQueryVariables,
  response: addTeamBotsModalQueryResponse,
|};
*/


/*
query addTeamBotsModalQuery(
  $teamId: ID!
) {
  team(id: $teamId) {
    ...addTeamBotsModal_team_2EIa4l
    id
  }
}

fragment addTeamBotsModal_team_2EIa4l on Team {
  organization {
    bots {
      nodes {
        rowId
        name
        avatar
        teams {
          nodes {
            rowId
            id
          }
        }
        id
      }
    }
    id
  }
  account {
    id
  }
  rowId
  id
  name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamAccountRowId",
    "type": "BigInt!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "teamId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addTeamBotsModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "teamAccountRowId",
                "variableName": "teamAccountRowId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "addTeamBotsModal_team"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addTeamBotsModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Team",
        "kind": "LinkedField",
        "name": "team",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BotsConnection",
                "kind": "LinkedField",
                "name": "bots",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Bot",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "avatar",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TeamsConnection",
                        "kind": "LinkedField",
                        "name": "teams",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Team",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "addTeamBotsModalQuery",
    "operationKind": "query",
    "text": "query addTeamBotsModalQuery(\n  $teamId: ID!\n) {\n  team(id: $teamId) {\n    ...addTeamBotsModal_team_2EIa4l\n    id\n  }\n}\n\nfragment addTeamBotsModal_team_2EIa4l on Team {\n  organization {\n    bots {\n      nodes {\n        rowId\n        name\n        avatar\n        teams {\n          nodes {\n            rowId\n            id\n          }\n        }\n        id\n      }\n    }\n    id\n  }\n  account {\n    id\n  }\n  rowId\n  id\n  name\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9e0fa4c70a976a68cc6e6eef31457294';

module.exports = node;
