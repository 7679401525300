import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation expireKeyMutation($input: UpdateKeyByRowIdInput!) {
    updateKeyByRowId(input: $input) {
      key {
        id
        rowId
        deprecated
        deprecatedAt
      }
    }
  }
`;

export default (environment, keyRowId) =>
  withSpanAsync('updateKeyByRowId', SpanType.mutation, async () => {
    const variables = {
      input: {
        rowId: keyRowId,
        patch: {
          deprecated: true
        }
      }
    };

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);

          resolve();
        },
        onError: err => {
          console.error(err);
          reject(err);
        }
      });
    });
  });
