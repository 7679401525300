import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import { Avatar, DirectoryRow, Pushtext } from '@stratumn/atomic';

import pluralize from 'utils/pluralize';

import styles from './botRow.style';

export function BotRow(props) {
  const {
    classes,
    bot: { avatar, name, rowId, teams },
    botsTotalCount,
    index,
    onClick
  } = props;

  const renderMain = (
    <Pushtext onClick={() => onClick(rowId)}>
      <div className={classes.link}>
        <Avatar size={32} src={avatar} />
        <span className={classes.botName}>{name}</span>
      </div>
    </Pushtext>
  );

  return (
    <DirectoryRow
      isLast={index === botsTotalCount}
      main={renderMain}
      cell1={pluralize('Team', teams.totalCount)}
    />
  );
}

BotRow.propTypes = {
  classes: PropType.object.isRequired,
  bot: PropType.object.isRequired,
  botsTotalCount: PropType.number.isRequired,
  index: PropType.number.isRequired,
  onClick: PropType.func.isRequired
};

export default injectSheet(styles)(BotRow);
