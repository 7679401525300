import { commitMutation } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation addTeamMutation($input: CreateTeamInput!) {
    createTeam(input: $input) {
      team {
        ...teamRow_team
      }
    }
  }
`;

export default (
  environment,
  organizationId, // in order to update the store
  organizationRowId,
  name,
  description,
  avatarUri
) =>
  withSpanAsync('addTeam', SpanType.mutation, async () => {
    const variables = {
      input: {
        team: {
          name,
          description,
          organizationId: organizationRowId,
          avatar: avatarUri
        }
      }
    };

    const error = new Error(`Unable to create team ${name}`);

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);
          if (errors && errors.length) {
            reject(error);
          }

          resolve();
        },
        onError: err => {
          console.error(err);

          reject(error);
        },
        updater: store => {
          const payload = store.getRootField('createTeam');
          const newTeam = payload.getLinkedRecord('team');

          const organization = store.get(organizationId);
          const teams = organization.getLinkedRecord('teams');
          const totalCount = teams.getValue('totalCount');

          /* 
          We don't need to pass in the filters to getConnection.
          However, because we're using filters, we need to fetch the 'edges', not the 'nodes'
        */
          const teamsConnection = ConnectionHandler.getConnection(
            organization,
            'Teams_teams'
          );

          const edge = ConnectionHandler.createEdge(
            store,
            teamsConnection,
            newTeam,
            'TeamsEdge' // type
          );

          ConnectionHandler.insertEdgeAfter(teamsConnection, edge);

          // Update the old teams count
          teams.setValue(totalCount + 1, 'totalCount');
          teamsConnection.setValue(totalCount + 1, 'totalCount');
        }
      });
    });
  });
