import React from 'react';
import PropType from 'prop-types';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { withGqlClient } from 'wrappers';

import RemoveTeamCollaboratorModal from './removeTeamCollaboratorModal';

const query = graphql`
  query removeTeamCollaboratorModalQuery(
    $userRowId: BigInt!
    $teamFilter: TeamFilter
  ) {
    userByRowId(rowId: $userRowId) {
      ...removeTeamCollaboratorModal_collaborator
        @arguments(teamFilter: $teamFilter)
    }
  }
`;

export const Renderer = props => {
  const { environment, collaboratorRowId, teamRowId, onClose } = props;
  const variables = {};

  variables.userRowId = collaboratorRowId;
  variables.teamFilter = { rowId: { equalTo: teamRowId } };

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={variables}
      render={({ error, props: data }) => {
        if (error) return 'error';
        if (!data) return null;
        const { userByRowId } = data;
        return (
          <RemoveTeamCollaboratorModal
            collaborator={userByRowId}
            onClose={onClose}
          />
        );
      }}
    />
  );
};

Renderer.propTypes = {
  environment: PropType.object.isRequired,
  collaboratorRowId: PropType.string.isRequired,
  teamRowId: PropType.string.isRequired,
  onClose: PropType.func.isRequired
};

export default withGqlClient(Renderer);
