export default theme => ({
  userHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 24
  },
  userName: {
    padding: '8px 0 4px 0',
    fontSize: 18
  },
  userRole: {
    fontSize: 14,
    color: theme.grey3
  },
  collectionItemLabel: {
    color: theme.grey4,
    fontSize: 10,
    fontWeight: 600,
    paddingBottom: 4,
    letterSpacing: 1,
    textTransform: 'uppercase'
  },
  collectionItemValue: {
    paddingBottom: 16,
    fontSize: 14
  },
  link: {
    display: 'flex',
    alignItems: 'center'
  },
  teamName: {
    paddingLeft: 8
  }
});
