import React from 'react';
import { createBrowserHistory as createHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import * as Sentry from '@sentry/react';
// import { Snackbars } from '@stratumn/atomic';

import { env } from 'constant/env';

import {
  ROUTE_LOGIN,
  ROUTE_SIGNUP,
  ROUTE_OAUTH,
  ROUTE_MY_PROFILE,
  ROUTE_ORG,
  ROUTE_UNAUTHORIZED,
  ROUTE_GENERATE_KEYS,
  ROUTE_ADMIN,
  ROUTE_FORGOT,
  ROUTE_RESET,
  ROUTE_PASSWORD_UPDATE_COMPONENT,
  ROUTE_EDIT_PROFILE_SIGNUP,
  ROUTE_EDIT_BOT_PROFILE,
  ROUTE_GDPR_POLICY_PAGE
} from 'constant/routes';
import {
  Login,
  Signup,
  OAuth,
  MyProfile,
  Unauthorized,
  GenerateKeys,
  Admin,
  Forgot,
  Reset,
  EditProfileSignup,
  Organization,
  UpdatePassword,
  EditBotProfile,
  GdprPolicy
} from 'components';

import {
  RestClient,
  GqlClient,
  ErrorBoundary,
  PrivateRoute,
  Notifications
} from 'wrappers';
import { Toaster } from 'sonner/dist';

import theme from 'style';
import 'style/style.css';

import NotFound from './organization/notFound';

export const history = createHistory();

Sentry.init({
  dsn: 'https://20d3edc8d209ff95a0572bc67154bb64@sentry.stratu.mn/6',
  environment: env.APP_ENVIRONMENT,
  debug: env.SENTRY_DEBUG === 'true',
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({
      history
    }),
    Sentry.replayIntegration()
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    '.*api-public.*',
    '.*api-private.*',
    /^.*\.stratumn\.com/,
    new RegExp(`^.*\\.${env.APP_ENVIRONMENT}\\.stratumn\\.com`),
    /^\//
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Deny local errors
  denyUrls: [/^https?:\/\/([a-z-]+\.)*local\.stratumn\.com/]
});

const Root = () => (
  <ThemeProvider theme={theme}>
    <Router history={history}>
      {/* <Snackbars> */}
      <ErrorBoundary>
        <RestClient>
          <GqlClient>
            <Notifications>
              <Switch>
                <Route path={ROUTE_LOGIN} exact component={Login} />
                <Route path={ROUTE_FORGOT} exact component={Forgot} />
                <Route path={ROUTE_RESET} exact component={Reset} />
                <Route path={ROUTE_SIGNUP} exact component={Signup} />
                <Route path={ROUTE_OAUTH} exact component={OAuth} />
                <PrivateRoute
                  path={ROUTE_GENERATE_KEYS}
                  component={GenerateKeys}
                  withoutHeader
                  exact
                />
                <PrivateRoute
                  path={ROUTE_PASSWORD_UPDATE_COMPONENT}
                  component={UpdatePassword}
                  withoutHeader
                  exact
                />
                <PrivateRoute
                  path={ROUTE_EDIT_PROFILE_SIGNUP}
                  component={EditProfileSignup}
                  withoutHeader
                  exact
                />
                <PrivateRoute path={ROUTE_ORG} component={Organization} />
                <PrivateRoute
                  path={ROUTE_EDIT_BOT_PROFILE}
                  component={({ match: { params: { id } } }) => (
                    <EditBotProfile botRowId={id} />
                  )}
                />
                <PrivateRoute
                  path={ROUTE_GDPR_POLICY_PAGE}
                  exact
                  component={GdprPolicy}
                />
                <Route
                  path={ROUTE_UNAUTHORIZED}
                  exact
                  component={Unauthorized}
                />
                <PrivateRoute path={ROUTE_ADMIN} exact component={Admin} />

                {/* 
                      In order to navigate inside myProfile component between the profile view and the edit view routes, we removed the exact <Route> prop. 
                      Originally, ROUTE_MY_PROFILE (./) was in a higher position within the Switch component which would stop the navigation. 
                      One solution was to move the ROUTE_MY_PROFILE to the bottom of the Switch component.
                    */}
                <PrivateRoute path={ROUTE_MY_PROFILE} component={MyProfile} />
                <Route path="*" component={NotFound} />
              </Switch>
            </Notifications>
          </GqlClient>
        </RestClient>
      </ErrorBoundary>
      <Toaster richColors closeButton position="bottom-left" />
      {/* </Snackbars> */}
    </Router>
  </ThemeProvider>
);

export default Root;
