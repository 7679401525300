export default theme => ({
  divider: {
    '&::before,&::after': {
      content: '""',
      borderTop: `5px solid ${theme.grey8}`,
      flex: '1 0 auto'
    },
    '&::before': {
      marginRight: 10
    },
    '&::after': {
      marginLeft: 10
    },
    marginBottom: 18,
    fontSize: 11,
    color: theme.grey2,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }
});
