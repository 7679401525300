/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditMembershipActionType = "GRANT" | "REVOKE" | "%future added value";
export type RoleType = "ADMIN" | "LEADER" | "READER" | "%future added value";
export type EditAccountMembershipInput = {|
  clientMutationId?: ?string,
  _accountId?: ?any,
  _userId?: ?any,
  grantOrRevoke?: ?EditMembershipActionType,
  _role?: ?RoleType,
|};
export type editAccountMembershipTeamMutationVariables = {|
  input: EditAccountMembershipInput,
  teamRowId: any,
|};
export type editAccountMembershipTeamMutationResponse = {|
  +editAccountMembership: ?{|
    +boolean: ?boolean,
    +query: ?{|
      +teamByRowId: ?{|
        +organization: ?{|
          +organizationMemberLicensesLeft: ?number
        |}
      |}
    |},
  |}
|};
export type editAccountMembershipTeamMutation = {|
  variables: editAccountMembershipTeamMutationVariables,
  response: editAccountMembershipTeamMutationResponse,
|};
*/


/*
mutation editAccountMembershipTeamMutation(
  $input: EditAccountMembershipInput!
  $teamRowId: BigInt!
) {
  editAccountMembership(input: $input) {
    boolean
    query {
      teamByRowId(rowId: $teamRowId) {
        organization {
          organizationMemberLicensesLeft
          id
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "EditAccountMembershipInput!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamRowId",
    "type": "BigInt!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "boolean",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "teamRowId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationMemberLicensesLeft",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editAccountMembershipTeamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditAccountMembershipPayload",
        "kind": "LinkedField",
        "name": "editAccountMembership",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "teamByRowId",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editAccountMembershipTeamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditAccountMembershipPayload",
        "kind": "LinkedField",
        "name": "editAccountMembership",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "teamByRowId",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "editAccountMembershipTeamMutation",
    "operationKind": "mutation",
    "text": "mutation editAccountMembershipTeamMutation(\n  $input: EditAccountMembershipInput!\n  $teamRowId: BigInt!\n) {\n  editAccountMembership(input: $input) {\n    boolean\n    query {\n      teamByRowId(rowId: $teamRowId) {\n        organization {\n          organizationMemberLicensesLeft\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f46cfcae66ac73eb656747fa65b7b9e';

module.exports = node;
