/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type addTeamBotsModal_team$ref: FragmentReference;
declare export opaque type addTeamBotsModal_team$fragmentType: addTeamBotsModal_team$ref;
export type addTeamBotsModal_team = {|
  +organization: ?{|
    +bots: {|
      +nodes: $ReadOnlyArray<?{|
        +rowId: any,
        +name: string,
        +avatar: ?string,
        +teams: {|
          +nodes: $ReadOnlyArray<?{|
            +rowId: any
          |}>
        |},
      |}>
    |}
  |},
  +account: ?{|
    +id: string
  |},
  +rowId: any,
  +id: string,
  +name: string,
  +$refType: addTeamBotsModal_team$ref,
|};
export type addTeamBotsModal_team$data = addTeamBotsModal_team;
export type addTeamBotsModal_team$key = {
  +$data?: addTeamBotsModal_team$data,
  +$fragmentRefs: addTeamBotsModal_team$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamAccountRowId",
      "type": "BigInt!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "addTeamBotsModal_team",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BotsConnection",
          "kind": "LinkedField",
          "name": "bots",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Bot",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "avatar",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamsConnection",
                  "kind": "LinkedField",
                  "name": "teams",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Team",
                      "kind": "LinkedField",
                      "name": "nodes",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    (v2/*: any*/),
    (v1/*: any*/)
  ],
  "type": "Team"
};
})();
// prettier-ignore
(node/*: any*/).hash = '1c84d40e2cda7253460e6ed7cd679a0f';

module.exports = node;
