import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import styles from './logo.style';

import logo from '../../../assets/account_logo.svg';

export class Logo extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.logoBox}>
        <img src={logo} alt="Stratumn ID" className={classes.logo} />
      </div>
    );
  }
}

export default injectSheet(styles)(Logo);
