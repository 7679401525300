import React from 'react';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import PropType from 'prop-types';
import { PushbuttonHref } from '@stratumn/atomic';
import compose from 'lodash.flowright';
import qs from 'query-string';
import {
  KEYCLOAK_URL,
  MICROSOFT_SIA_URL,
  ONE_ACCOUNT_AXA_URL
} from 'constant/api';
import axaLogo from 'assets/axa_logo_white.svg';
import keycloakLogo from 'assets/keycloak_logo.png';
import siaPartnersLogo from 'assets/sia_partners_logo.png';
import {
  AUTH_PROVIDER_KEYCLOAK,
  AUTH_PROVIDER_ONE_ACCOUNT_AXA,
  AUTH_PROVIDER_MICROSOFT_SIA
} from 'constant/types';
import { ENABLE_KEYCLOAK_OAUTH } from 'constant/featureFlags';
import styles from './oauthOptions.style';

export const OauthOptions = props => {
  const { classes, location, providers } = props;

  const oauthUrl = providerUrl => {
    const {
      responseType,
      clientId,
      redirectUri,
      codeChallenge,
      codeChallengeMethod,
      invitationToken
    } = qs.parse(location.search);

    const provider =
      {
        [ONE_ACCOUNT_AXA_URL]: AUTH_PROVIDER_ONE_ACCOUNT_AXA,
        [KEYCLOAK_URL]: AUTH_PROVIDER_KEYCLOAK,
        [MICROSOFT_SIA_URL]: AUTH_PROVIDER_MICROSOFT_SIA
      }[providerUrl] || '';

    const state = Buffer.from(
      JSON.stringify({
        redirectUri,
        responseType,
        clientId,
        codeChallenge,
        codeChallengeMethod,
        provider,
        invitationToken
      })
    ).toString('base64');
    return `${providerUrl}&state=${state}`;
  };

  const containsProvider = p => !providers || providers.includes(p);

  const oneAccount = containsProvider(AUTH_PROVIDER_ONE_ACCOUNT_AXA) && (
    <PushbuttonHref
      href={oauthUrl(ONE_ACCOUNT_AXA_URL)}
      prefix={<img src={axaLogo} alt="Axa logo" className={classes.axaLogo} />}
      primary
    >
      One Account AXA
    </PushbuttonHref>
  );

  const keycloak = ENABLE_KEYCLOAK_OAUTH &&
    containsProvider(AUTH_PROVIDER_KEYCLOAK) && (
      <PushbuttonHref
        href={oauthUrl(KEYCLOAK_URL)}
        prefix={
          <img
            src={keycloakLogo}
            alt="Keycloak logo"
            className={classes.keycloakLogo}
          />
        }
        primary
      >
        Keycloak
      </PushbuttonHref>
    );

  const microsoftSia = containsProvider(AUTH_PROVIDER_MICROSOFT_SIA) && (
    <PushbuttonHref
      href={oauthUrl(MICROSOFT_SIA_URL)}
      prefix={
        <img
          src={siaPartnersLogo}
          alt="sia partners logo"
          className={classes.siaPartnersLogo}
        />
      }
      primary
    >
      Sia Partners
    </PushbuttonHref>
  );

  return (
    <div className={classes.oauthOptions}>
      {oneAccount}
      {keycloak}
      {microsoftSia}
    </div>
  );
};

OauthOptions.propTypes = {
  location: PropType.object.isRequired,
  classes: PropType.object.isRequired,
  providers: PropType.arrayOf(PropType.string)
};

OauthOptions.defaultProps = {
  providers: null
};

export default compose(withRouter, injectSheet(styles))(OauthOptions);
