export default () => ({
  directoryWrapper: {
    marginTop: 32
  },
  pushbuttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '15px',
    marginBottom: '15px'
  }
});
