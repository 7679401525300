import React, { useState } from 'react';
import PropType from 'prop-types';
import { withRouter } from 'react-router-dom';

import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';

import { useDebounce } from 'utils/hooks';

import { SearchBar } from 'components/ui';

import { withGqlClient, withUser } from 'wrappers';
import { SEARCH_DELAY } from 'constant/timer';
import { TEAMS_PER_PAGE, TEAMS_ORDER_BY } from 'constant/types';

import Teams from './teams';
import TeamsHeader from './teamsHeader';
import LoadingState from '../loadingState';

const query = graphql`
  query teamsRootQuery(
    $orgRowId: BigInt!
    $teamFilter: TeamFilter
    $orderBy: [TeamsOrderBy!]
    # Pagination
    $cursor: Cursor
    $count: Int!
  ) {
    organizationByRowId(rowId: $orgRowId) {
      ...teamsHeader_organization
      ...teams_organization
        @arguments(
          teamFilter: $teamFilter
          orderBy: $orderBy
          # Pagintation
          cursor: $cursor
          count: $count
        )
    }
  }
`;

export const Renderer = ({ error, props: data }) => {
  if (error) return <div>error!</div>;
  if (!data) return <LoadingState />;
  const { organizationByRowId } = data;
  return (
    <>
      <TeamsHeader organization={organizationByRowId} />
      <Teams organization={organizationByRowId} />
    </>
  );
};

Renderer.propTypes = {
  error: PropType.object,
  props: PropType.object
};

Renderer.defaultProps = {
  error: undefined,
  props: undefined
};

export const TeamsRoot = props => {
  const { environment, selectedOrganization, match } = props;
  const variables = {};

  const [char, setChar] = useState('');
  const search = useDebounce(char, SEARCH_DELAY);

  // There are some users that have unrealistic data so we need to account
  // for them to not crash the UI.
  if (!selectedOrganization || !selectedOrganization.rowId) {
    return null;
  }

  variables.orgRowId = match.params.orgId || selectedOrganization.rowId;
  variables.teamFilter = {
    name: { likeInsensitive: `%${search}%` }
  };
  variables.orderBy = TEAMS_ORDER_BY;

  /**
   * Pagination Variables
   */
  variables.count = TEAMS_PER_PAGE;
  variables.cursor = null;

  // We need to make sure that the location props are passed to child components correctly.
  // https://github.com/ReactTraining/react-router/blob/v4.2.2/packages/react-router/docs/guides/blocked-updates.md
  const RendererWithRouter = withRouter(Renderer);

  return (
    <>
      <SearchBar label="Search Teams" search={char} setSearch={setChar}>
        <div id="btn-create-new-team" />
      </SearchBar>

      <QueryRenderer
        environment={environment}
        query={query}
        variables={variables}
        render={RendererWithRouter}
      />
    </>
  );
};

TeamsRoot.propTypes = {
  environment: PropType.object.isRequired,
  selectedOrganization: PropType.object
};

TeamsRoot.defaultProps = {
  selectedOrganization: null
};

export default compose(withUser, withGqlClient)(TeamsRoot);
