export default theme => ({
  optionBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 10
  },
  forgot: {
    marginTop: 4,
    fontSize: 11
  },

  signUp: {
    marginTop: 20,
    fontSize: 11,
    color: theme.grey2
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 18
  },
  formField: {
    '& + &': {
      paddingTop: 15
    }
  }
});
