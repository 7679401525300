export default {
  oauthOptions: {
    '& > *': {
      margin: '0 8px 8px'
    }
  },
  axaLogo: {
    height: 21
  },
  keycloakLogo: {
    height: 21
  },
  siaPartnersLogo: {
    height: 21
  }
};
