/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type KeyUsage = "ENCRYPT" | "SIGN" | "%future added value";
export type CreateUserSigningKeyInput = {|
  clientMutationId?: ?string,
  userRowId?: ?any,
  publicKey?: ?string,
  privateKey?: ?string,
  passwordProtected?: ?boolean,
|};
export type createUserSigningKeyMutationVariables = {|
  input: CreateUserSigningKeyInput
|};
export type createUserSigningKeyMutationResponse = {|
  +createUserSigningKey: ?{|
    +key: ?{|
      +id: string,
      +rowId: any,
      +usage: KeyUsage,
      +createdAt: any,
      +publicKey: string,
      +deprecated: boolean,
      +privateKey: ?{|
        +decrypted: ?string,
        +passwordProtected: boolean,
      |},
    |}
  |}
|};
export type createUserSigningKeyMutation = {|
  variables: createUserSigningKeyMutationVariables,
  response: createUserSigningKeyMutationResponse,
|};
*/


/*
mutation createUserSigningKeyMutation(
  $input: CreateUserSigningKeyInput!
) {
  createUserSigningKey(input: $input) {
    key {
      id
      rowId
      usage
      createdAt
      publicKey
      deprecated
      privateKey {
        decrypted
        passwordProtected
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateUserSigningKeyInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usage",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publicKey",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deprecated",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decrypted",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "passwordProtected",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createUserSigningKeyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUserSigningKeyPayload",
        "kind": "LinkedField",
        "name": "createUserSigningKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Key",
            "kind": "LinkedField",
            "name": "key",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PrivateKey",
                "kind": "LinkedField",
                "name": "privateKey",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createUserSigningKeyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateUserSigningKeyPayload",
        "kind": "LinkedField",
        "name": "createUserSigningKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Key",
            "kind": "LinkedField",
            "name": "key",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PrivateKey",
                "kind": "LinkedField",
                "name": "privateKey",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createUserSigningKeyMutation",
    "operationKind": "mutation",
    "text": "mutation createUserSigningKeyMutation(\n  $input: CreateUserSigningKeyInput!\n) {\n  createUserSigningKey(input: $input) {\n    key {\n      id\n      rowId\n      usage\n      createdAt\n      publicKey\n      deprecated\n      privateKey {\n        decrypted\n        passwordProtected\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7f482e129cfeda4f529355ab561eee89';

module.exports = node;
