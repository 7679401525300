/*
  Use case:
  If a team does not have any avatar, this function will be triggered.
  It will either return the first letter of a 'one word' team; 
  or the first letters of a 'two words' team. 

  e.g:  New York = NY
        Paris = P
*/
export default name => {
  const regex = /[ ,-]+/; // space, dash or comma
  const nameArr = name.split(regex);
  if (nameArr.length > 1) {
    return nameArr[0].charAt(0) + nameArr[1].charAt(0);
  }
  return name[0];
};
