import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import PropType from 'prop-types';
import { createFragmentContainer } from 'react-relay';
import * as Sentry from '@sentry/react';
import to from 'await-to-js';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';

import { addTeamMembers } from 'mutations';

import {
  Modal,
  ModalContent,
  ModalActions,
  Pushbutton,
  Check
} from '@stratumn/atomic';

import { TableRow, UserAvatarLink } from 'components/ui';
import { SpanType, withSpanAsync } from 'tracing';
import { withGqlClient } from 'wrappers';

import styles from './addTeamMembersModal.style';

export class AddTeamMembersModal extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired,
    environment: PropType.object.isRequired,
    team: PropType.object.isRequired,
    onClose: PropType.func.isRequired
  };

  state = {
    selected: {},
    submitting: false
  };

  getSelectedArr = () => Object.keys(this.state.selected);

  toggleSelected = email => () => {
    const selected = { ...this.state.selected };

    if (selected[email]) {
      delete selected[email];
    } else {
      selected[email] = true;
    }

    this.setState({ selected });
  };

  onSubmit = () =>
    withSpanAsync('addTeamMembers', SpanType.processing, async () => {
      const { environment, team, onClose } = this.props;

      this.setState({ submitting: true });

      const members = this.getSelectedArr().map(email => ({
        email,
        role: 'READER'
      }));

      const [err] = await to(
        addTeamMembers(environment, team.account.id, team.rowId, members)
      );

      if (err) {
        Sentry.captureException(err);
        this.setState({ submitting: false });
      } else {
        onClose();
      }

      return {};
    });

  renderNode = ({ user: { rowId, email, name, avatar } }) => {
    const { selected } = this.state;
    const { team } = this.props;
    const { organization: { rowId: organizationRowId } } = team;

    return (
      <TableRow key={rowId}>
        <UserAvatarLink
          rowId={rowId}
          name={name || email}
          avatar={avatar}
          noLink
          organizationRowId={organizationRowId}
        />
        <Check
          label={`add ${name} to the Team`}
          handleChange={this.toggleSelected(email)}
          checked={selected[email]}
        />
      </TableRow>
    );
  };

  renderSelector = () => {
    const { classes, team } = this.props;
    const { organization: { account: { members } } } = team;

    return (
      <>
        <div className={classes.title}>
          Select the people to add to the{' '}
          <span className={classes.teamName}>{team.name}</span> Team.
        </div>
        <ul className={classes.memberList}>
          {members.nodes.map(this.renderNode)}
        </ul>
      </>
    );
  };

  renderAllInOrg = () => {
    const { classes, team } = this.props;

    return (
      <div className={classes.title}>
        All the users in this organization are already a part of the{' '}
        <span className={classes.teamName}>{team.name}</span> Team.
      </div>
    );
  };

  render() {
    const { onClose, team } = this.props;
    const { organization: { account: { members } } } = team;
    const { submitting } = this.state;

    const numMemCount = this.getSelectedArr().length;

    let submitLabel;
    switch (numMemCount) {
      case 0:
        submitLabel = 'Add Team Members';
        break;
      case 1:
        submitLabel = 'Add 1 Team Member';
        break;
      default:
        submitLabel = `Add ${numMemCount} Team Members`;
    }

    return (
      <Modal
        title="Add Team members"
        closeButtonLabel="Cancel"
        handleCollapse={onClose}
      >
        <ModalContent>
          {members.nodes.length ? this.renderSelector() : this.renderAllInOrg()}
        </ModalContent>
        <ModalActions>
          <Pushbutton
            primary
            onClick={this.onSubmit}
            disabled={submitting || !numMemCount}
          >
            {submitLabel}
          </Pushbutton>
        </ModalActions>
      </Modal>
    );
  }
}

export default createFragmentContainer(
  compose(withGqlClient, injectSheet(styles))(AddTeamMembersModal),
  {
    team: graphql`
      fragment addTeamMembersModal_team on Team
        @argumentDefinitions(teamAccountRowId: { type: "BigInt!" }) {
        organization {
          account {
            members: membersOutsideEntity(accountRowId: $teamAccountRowId) {
              nodes {
                user {
                  id
                  rowId
                  email
                  name
                  avatar
                }
              }
            }
          }
        }
        account {
          id
        }
        rowId
        name
      }
    `
  }
);
