/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type removeOrgMemberModal_member$ref = any;
type removeTeamMemberModal_member$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type member_member$ref: FragmentReference;
declare export opaque type member_member$fragmentType: member_member$ref;
export type member_member = {|
  +id: string,
  +rowId: any,
  +name: string,
  +email: string,
  +avatar: ?string,
  +canUpdate: ?boolean,
  +organizations: {|
    +nodes: $ReadOnlyArray<?{|
      +rowId: any,
      +canUpdate: ?boolean,
      +account: ?{|
        +members: {|
          +nodes: $ReadOnlyArray<?{|
            +admin: ?boolean
          |}>
        |}
      |},
    |}>
  |},
  +teams: {|
    +totalCount: number,
    +nodes: $ReadOnlyArray<?{|
      +id: string,
      +rowId: any,
      +name: string,
      +avatar: ?string,
      +canUpdate: ?boolean,
    |}>,
  |},
  +$fragmentRefs: removeOrgMemberModal_member$ref & removeTeamMemberModal_member$ref,
  +$refType: member_member$ref,
|};
export type member_member$data = member_member;
export type member_member$key = {
  +$data?: member_member$data,
  +$fragmentRefs: member_member$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUpdate",
  "storageKey": null
},
v5 = {
  "kind": "Variable",
  "name": "teamFilter",
  "variableName": "teamFilter"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "organizationFilter",
      "type": "OrganizationFilter"
    },
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "memberFilter",
      "type": "MemberFilter"
    },
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "teamFilter",
      "type": "TeamFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "member_member",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "organizationFilter"
        }
      ],
      "concreteType": "OrganizationsConnection",
      "kind": "LinkedField",
      "name": "organizations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "account",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "filter",
                      "variableName": "memberFilter"
                    }
                  ],
                  "concreteType": "MembersConnection",
                  "kind": "LinkedField",
                  "name": "members",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Member",
                      "kind": "LinkedField",
                      "name": "nodes",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "admin",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "teamFilter"
        }
      ],
      "concreteType": "TeamsConnection",
      "kind": "LinkedField",
      "name": "teams",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Team",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "organizationFilter",
          "variableName": "organizationFilter"
        },
        (v5/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "removeOrgMemberModal_member"
    },
    {
      "args": [
        (v5/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "removeTeamMemberModal_member"
    }
  ],
  "type": "User"
};
})();
// prettier-ignore
(node/*: any*/).hash = '01336ea29706b3b64e02703672e379af';

module.exports = node;
