import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import tick from 'assets/tick.svg';

import styles from './checkBox.style';

export class CheckBox extends React.Component {
  static propTypes = {
    classes: PropType.object.isRequired,
    onToggle: PropType.func.isRequired,
    text: PropType.node,
    className: PropType.string,
    /* eslint-disable */
    checked: PropType.bool // used to set initial state
    /* eslint-enable */
  };

  static defaultProps = {
    text: '',
    className: '',
    checked: false
  };

  state = { checked: null };

  static getDerivedStateFromProps(props, state) {
    if (state.checked === null) return { checked: props.checked };
    return null;
  }

  toggle = () => {
    this.setState({ checked: !this.state.checked });
    this.props.onToggle();
  };

  render() {
    const { classes, text, className } = this.props;

    const { checked } = this.state;

    const checkBoxClasses = classNames(classes.checkBox, {
      [classes.checked]: checked
    });

    return (
      <div className={classNames(classes.container, className)}>
        <div
          className={checkBoxClasses}
          onClick={this.toggle}
          data-cy="check-box"
        >
          {checked && (
            <div className={classes.check}>
              <img src={tick} alt="check mark" className={classes.checkMark} />
            </div>
          )}
        </div>
        <div className={classes.text}>{text}</div>
      </div>
    );
  }
}

export default injectSheet(styles)(CheckBox);
