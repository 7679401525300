import React from 'react';
import PropType from 'prop-types';
import debounce from 'debounce';
import injectSheet from 'react-jss';
import classnames from 'classnames';

import { FieldPasswordCompact } from '@stratumn/atomic';

import styles from './changePassword.style';

const PASSWORD_CHECK_DELAY = 100; // ms
const MINIMUM_SCORE = 4;

const atLeastOneNumber = /[0-9]/;
const atLeastOneUpper = /[A-Z]/;
const atLeastOneSpecial = /[!@$%^&(){}[\]:;<>,\-+.?/~_=|]/;
const atLeast8CharLong = /^.{8,32}$/;

export class ChangePassword extends React.PureComponent {
  static propTypes = {
    onChange: PropType.func.isRequired,
    extraExclusionTerms: PropType.arrayOf(PropType.string),
    classes: PropType.object.isRequired,
    labelPassword: PropType.string,
    labelConfirmPassword: PropType.string
  };

  static defaultProps = {
    extraExclusionTerms: [],
    labelPassword: 'password',
    labelConfirmPassword: 'Confirm password'
  };

  state = {
    // reflect the last values that have been validated
    password: '',
    confirmation: '',

    // reflect the actual input fields
    tempPassword: '',
    tempConfirmation: '',

    showPassword: false
  };

  validatePassword = debounce(() => {
    const { tempConfirmation, tempPassword } = this.state;
    const { onChange } = this.props;
    this.setState({
      password: tempPassword,
      confirmation: tempConfirmation
    });

    if (
      this.passwordScore(tempPassword) === MINIMUM_SCORE &&
      tempConfirmation === tempPassword
    ) {
      onChange(tempPassword);
    } else {
      onChange(null);
    }
  }, PASSWORD_CHECK_DELAY);

  componentWillUnmount() {
    this.validatePassword.clear();
  }

  togglePassword = () =>
    this.setState({ showPassword: !this.state.showPassword });

  handlePasswordChange = event => {
    const { target: { value: password } } = event;
    this.props.onChange(null);
    this.setState({ tempPassword: password });
    this.validatePassword();
  };

  handleConfirmationChange = event => {
    const { target: { value: confirmation } } = event;
    this.props.onChange(null);
    this.setState({ tempConfirmation: confirmation });
    this.validatePassword();
  };

  getBarClasses = (idx, score) => {
    const { classes } = this.props;
    const { password } = this.state;

    if (
      !password ||
      password.length === 0 ||
      score === null ||
      idx > score - 1 ||
      score === 0
    )
      return classes.bar;

    return classnames(classes.bar, {
      [classes.greenBG]: score === 4,
      [classes.orangeBG]: score === 3,
      [classes.redBG]: score === 2 || score === 1
    });
  };

  renderStrengthBar = score => {
    const { classes } = this.props;
    return (
      <div className={classes.strengthBar}>
        {Array.from(Array(4), (_, idx) => (
          <div key={idx} className={this.getBarClasses(idx, score)} />
        ))}
      </div>
    );
  };

  passwordScore = tempPassword =>
    atLeast8CharLong.test(tempPassword) +
    atLeastOneNumber.test(tempPassword) +
    atLeastOneSpecial.test(tempPassword) +
    atLeastOneUpper.test(tempPassword);

  renderPasswordRules = () => {
    const { tempPassword } = this.state;
    return (
      <React.Fragment>
        {!atLeast8CharLong.test(tempPassword) ? (
          <p>Your password needs to be at least 8 characters long</p>
        ) : (
          <React.Fragment />
        )}
        {!atLeastOneNumber.test(tempPassword) ? (
          <p>Your password needs to have at least 1 number</p>
        ) : (
          <React.Fragment />
        )}
        {!atLeastOneUpper.test(tempPassword) ? (
          <p>Your password needs to have at least 1 upper char</p>
        ) : (
          <React.Fragment />
        )}
        {!atLeastOneSpecial.test(tempPassword) ? (
          <p>Your password needs to have at least 1 special char</p>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  };

  render() {
    const { classes, labelPassword, labelConfirmPassword } = this.props;
    const {
      password,
      tempPassword,
      confirmation,
      tempConfirmation,
      showPassword
    } = this.state;

    const confirmationError = confirmation && confirmation !== password;

    const isNeutral = !password || password.length === 0;

    const score = this.passwordScore(tempPassword);

    return (
      <React.Fragment>
        <div className={classes.passwordField} data-cy="password">
          <FieldPasswordCompact
            label={labelPassword}
            onValueChange={this.handlePasswordChange}
            value={tempPassword}
            onVisibilityChange={this.togglePassword}
            passwordIsVisible={showPassword}
            invalid={!isNeutral && score !== MINIMUM_SCORE}
            valid={!isNeutral && score === MINIMUM_SCORE}
          />
          {this.renderStrengthBar(score)}
          <div className={classes.password_rules}>
            {this.renderPasswordRules()}
            {score === 4 ? (
              <p>Your password is strong enough!</p>
            ) : (
              <React.Fragment />
            )}
          </div>
        </div>
        <div className={classes.confirmPasswordField} data-cy="confirmation">
          <FieldPasswordCompact
            label={labelConfirmPassword}
            onValueChange={this.handleConfirmationChange}
            value={tempConfirmation}
            onVisibilityChange={this.togglePassword}
            passwordIsVisible={showPassword}
            invalid={!!confirmationError}
          />
          {confirmationError ? (
            <div className={classes.redText}>Your passwords do not match.</div>
          ) : (
            <div className={classes.hiddenText}>____</div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default injectSheet(styles)(ChangePassword);
