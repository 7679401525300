export default theme => ({
  description: {
    fontWeight: 'normal',
    fontSize: 14,
    '&::first-letter': {
      textTransform: 'uppercase'
    },
    '&[data-is-expired=true]': {
      color: theme.warningRed
    }
  },
  button: {
    paddingRight: 8,
    paddingLeft: 8
  }
});
