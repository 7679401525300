/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type teamsHeader_organization$ref = any;
type teams_organization$ref = any;
export type TeamsOrderBy = "ACCOUNT_ID_ASC" | "ACCOUNT_ID_DESC" | "AVATAR_ASC" | "AVATAR_DESC" | "CREATED_AT_ASC" | "CREATED_AT_DESC" | "DESCRIPTION_ASC" | "DESCRIPTION_DESC" | "EMAIL_ASC" | "EMAIL_DESC" | "ID_ASC" | "ID_DESC" | "NAME_ASC" | "NAME_DESC" | "NATURAL" | "ORGANIZATION_ID_ASC" | "ORGANIZATION_ID_DESC" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "UPDATED_AT_ASC" | "UPDATED_AT_DESC" | "%future added value";
export type TeamFilter = {|
  rowId?: ?BigIntFilter,
  name?: ?StringFilter,
  description?: ?StringFilter,
  avatar?: ?StringFilter,
  organizationId?: ?BigIntFilter,
  accountId?: ?BigIntFilter,
  createdAt?: ?DatetimeFilter,
  updatedAt?: ?DatetimeFilter,
  email?: ?StringFilter,
  canInsert?: ?BooleanFilter,
  canUpdate?: ?BooleanFilter,
  and?: ?$ReadOnlyArray<TeamFilter>,
  or?: ?$ReadOnlyArray<TeamFilter>,
  not?: ?TeamFilter,
|};
export type BigIntFilter = {|
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
|};
export type StringFilter = {|
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  similarTo?: ?string,
  notSimilarTo?: ?string,
|};
export type DatetimeFilter = {|
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
|};
export type BooleanFilter = {|
  isNull?: ?boolean,
  equalTo?: ?boolean,
  notEqualTo?: ?boolean,
  distinctFrom?: ?boolean,
  notDistinctFrom?: ?boolean,
  in?: ?$ReadOnlyArray<boolean>,
  notIn?: ?$ReadOnlyArray<boolean>,
|};
export type teamsRootQueryVariables = {|
  orgRowId: any,
  teamFilter?: ?TeamFilter,
  orderBy?: ?$ReadOnlyArray<TeamsOrderBy>,
  cursor?: ?any,
  count: number,
|};
export type teamsRootQueryResponse = {|
  +organizationByRowId: ?{|
    +$fragmentRefs: teamsHeader_organization$ref & teams_organization$ref
  |}
|};
export type teamsRootQuery = {|
  variables: teamsRootQueryVariables,
  response: teamsRootQueryResponse,
|};
*/


/*
query teamsRootQuery(
  $orgRowId: BigInt!
  $teamFilter: TeamFilter
  $orderBy: [TeamsOrderBy!]
  $cursor: Cursor
  $count: Int!
) {
  organizationByRowId(rowId: $orgRowId) {
    ...teamsHeader_organization
    ...teams_organization_HMVFO
    id
  }
}

fragment teamRow_team on Team {
  id
  rowId
  name
  avatar
  account {
    members {
      totalCount
    }
    id
  }
  bots {
    totalCount
  }
}

fragment teamsHeader_organization on Organization {
  id
  rowId
  account {
    canUpdate
    id
  }
  headerTeams: teams(orderBy: $orderBy) {
    edges {
      node {
        id
        rowId
        name
        account {
          members {
            totalCount
            nodes {
              collaborator
              email
              name
            }
          }
          id
        }
        bots {
          totalCount
          nodes {
            name
            id
          }
        }
      }
    }
  }
}

fragment teams_organization_HMVFO on Organization {
  ...teamsHeader_organization
  account {
    canUpdate
    id
  }
  teams(first: $count, after: $cursor, orderBy: $orderBy, filter: $teamFilter) {
    totalCount
    edges {
      cursor
      node {
        id
        ...teamRow_team
        __typename
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgRowId",
    "type": "BigInt!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamFilter",
    "type": "TeamFilter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[TeamsOrderBy!]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "Cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "orgRowId"
  }
],
v2 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "teamFilter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v2/*: any*/)
],
v8 = [
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "teamsRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationByRowId",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "teamsHeader_organization"
          },
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v2/*: any*/),
              {
                "kind": "Variable",
                "name": "teamFilter",
                "variableName": "teamFilter"
              }
            ],
            "kind": "FragmentSpread",
            "name": "teams_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "teamsRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationByRowId",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canUpdate",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "headerTeams",
            "args": [
              (v2/*: any*/)
            ],
            "concreteType": "TeamsConnection",
            "kind": "LinkedField",
            "name": "teams",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Team",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "account",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MembersConnection",
                            "kind": "LinkedField",
                            "name": "members",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Member",
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "collaborator",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "email",
                                    "storageKey": null
                                  },
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BotsConnection",
                        "kind": "LinkedField",
                        "name": "bots",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Bot",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "TeamsConnection",
            "kind": "LinkedField",
            "name": "teams",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Team",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "avatar",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "account",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MembersConnection",
                            "kind": "LinkedField",
                            "name": "members",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BotsConnection",
                        "kind": "LinkedField",
                        "name": "bots",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Teams_teams",
            "kind": "LinkedHandle",
            "name": "teams"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "teamsRootQuery",
    "operationKind": "query",
    "text": "query teamsRootQuery(\n  $orgRowId: BigInt!\n  $teamFilter: TeamFilter\n  $orderBy: [TeamsOrderBy!]\n  $cursor: Cursor\n  $count: Int!\n) {\n  organizationByRowId(rowId: $orgRowId) {\n    ...teamsHeader_organization\n    ...teams_organization_HMVFO\n    id\n  }\n}\n\nfragment teamRow_team on Team {\n  id\n  rowId\n  name\n  avatar\n  account {\n    members {\n      totalCount\n    }\n    id\n  }\n  bots {\n    totalCount\n  }\n}\n\nfragment teamsHeader_organization on Organization {\n  id\n  rowId\n  account {\n    canUpdate\n    id\n  }\n  headerTeams: teams(orderBy: $orderBy) {\n    edges {\n      node {\n        id\n        rowId\n        name\n        account {\n          members {\n            totalCount\n            nodes {\n              collaborator\n              email\n              name\n            }\n          }\n          id\n        }\n        bots {\n          totalCount\n          nodes {\n            name\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment teams_organization_HMVFO on Organization {\n  ...teamsHeader_organization\n  account {\n    canUpdate\n    id\n  }\n  teams(first: $count, after: $cursor, orderBy: $orderBy, filter: $teamFilter) {\n    totalCount\n    edges {\n      cursor\n      node {\n        id\n        ...teamRow_team\n        __typename\n      }\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4ad497c4b13160b3d2b268cd1f5007d5';

module.exports = node;
