import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import styles from './tableRow.style';

export class TableRow extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired,
    children: PropType.node.isRequired
  };

  render() {
    const { classes, children } = this.props;

    return <li className={classes.row}>{children}</li>;
  }
}

export default injectSheet(styles)(TableRow);
