import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import { Link } from 'react-router-dom';
import compose from 'lodash.flowright';
import { validate as isValidEmail } from 'isemail';
import { CheckboxTick } from '@stratumn/icons';
import { Pushbutton } from '@stratumn/atomic';

import { Box, TextInput, Logo } from 'components/ui';
import { SpanType, withSpanAsync } from 'tracing';
import { withRestClient } from 'wrappers';
import { ROUTE_LOGIN } from 'constant/routes';

import styles from './forgot.style';

export class Forgot extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired,
    client: PropType.object.isRequired
  };

  state = {
    email: '',
    submitted: false,
    emailSent: false
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  isActive() {
    return !this.state.submitted && isValidEmail(this.state.email);
  }

  sendLink = async () =>
    withSpanAsync('forgot', SpanType.processing, async () => {
      const { email } = this.state;
      this.setState({ submitted: true });
      try {
        await this.props.client.forgot(email);
        this.setState({ emailSent: true });
      } catch (err) {
        this.setState({ submitted: false });
      }
    });

  render() {
    const { classes } = this.props;
    const { emailSent, email } = this.state;
    return (
      <>
        <Logo />
        <Box title="Forgot your password?">
          <div className={classes.text}>
            We'll get you back up and running soon! Enter your email and we'll
            send you a link to reset your password.
          </div>
          <TextInput
            data-cy="email"
            type="text"
            label="Email address"
            onChange={this.handleChange('email')}
            autoFocus
            top
            right
            disabled={emailSent}
            value={email}
          />
          <div className={classes.buttonRow}>
            <Pushbutton
              dataCy="button"
              onClick={this.sendLink}
              disabled={!this.isActive()}
              complete={emailSent}
              primary
            >
              {emailSent ? (
                <CheckboxTick className={classes.checkbox} />
              ) : (
                'Send link'
              )}
            </Pushbutton>
            {emailSent && (
              <div className={classes.emailSent}>
                An email has been sent to this email address with a link to
                reset the password.
              </div>
            )}
          </div>
          <Link to={ROUTE_LOGIN} className={classes.backToSignIn}>
            Back to log in
          </Link>
        </Box>
      </>
    );
  }
}

export default compose(withRestClient, injectSheet(styles))(Forgot);
