import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import styles from './iconButtonClose.style';

export class IconButtonClose extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string,
    buttonRef: PropTypes.func,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    label: 'Close',
    disabled: false,
    buttonRef: null
  };

  handleClick = () => {
    if (!this.props.disabled) this.props.onClick();
  };

  getSvg = () => {
    const attrs = {
      x1: '30',
      x2: '70',
      strokeWidth: '1',
      strokeLinecap: 'butt',
      vectorEffect: 'non-scaling-stroke'
    };

    return (
      <svg viewBox="0 0 100 100" aria-hidden="true">
        <line {...attrs} y1="30" y2="70" />
        <line {...attrs} y1="70" y2="30" />
      </svg>
    );
  };

  render = () => {
    const { classes, disabled, buttonRef: ref } = this.props;

    const rootProps = {
      onClick: this.handleClick,
      type: 'button',
      className: classes.root,
      disabled,
      ref
    };

    return <button {...rootProps}>{this.getSvg()}</button>;
  };
}

export default injectSheet(styles)(IconButtonClose);
