export default {
  stepName: {
    marginBottom: 15,
    textTransform: 'uppercase'
  },
  children: {
    marginBottom: 15
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};
