import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';

import {
  FieldTextCompact,
  FieldTextAreaCompact,
  Modal,
  ModalActions,
  ModalContent,
  Pushbutton
} from '@stratumn/atomic';
import * as Sentry from '@sentry/react';
import to from 'await-to-js';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';
import { SpanType, withSpanAsync } from 'tracing';

import { addBot } from 'mutations';

import { withGqlClient, withUser } from 'wrappers';
import { downloadCsv } from 'utils/downloadCsv';
import { downloadXls } from 'utils/downloadXls';
import TypeFileViewsMenu from 'components/ui/typeFileViewsMenu';
import styles from './botsHeader.style';

const INITIAL_STATE = {
  name: '',
  key: '',
  description: '',
  submitted: false,
  showModal: false
};

export const BotsHeader = props => {
  const [state, setState] = useState(INITIAL_STATE);
  const [isExportOptionShown, setIsExportOptionShown] = useState(false);

  const toggleModal = () =>
    state.showModal
      ? setState(prevState => ({
          ...prevState,
          showModal: false,
          name: ''
        }))
      : setState(prevState => ({
          ...prevState,
          showModal: true
        }));

  const handleChange = name => e =>
    setState(prevState => ({
      ...prevState,
      [name]: e.target.value
    }));

  const resetState = () => setState(INITIAL_STATE);

  const submitNewBot = () =>
    withSpanAsync('addBot', SpanType.processing, async () => {
      const { environment, organization: { id, rowId } } = props;

      const { name, key, description } = state;

      setState(prevState => ({
        ...prevState,
        submitted: true
      }));

      /*
        In order for the mutation to create the new Bot, we need to leave
        ** empty strings ** for the avatar field.
      */
      const [err] = await to(
        addBot(environment, id, rowId, name, key, description, '')
      );

      if (err) {
        Sentry.captureException(err);
        setState(prevState => ({
          ...prevState,
          submitted: false
        }));
      } else {
        resetState();
      }

      return {};
    });

  const handleDownload = fileType => {
    const { headerBots } = props.organization;
    const sortedBots = [...headerBots.edges].sort((a, b) =>
      a.node.name.localeCompare(b.node.name)
    );

    const headers = ['Bot Name', 'Team Name'];
    const rows = sortedBots.flatMap(bot => {
      const teamNames = bot.node.teams.nodes.map(team => team.name);
      if (teamNames.length === 0) {
        return [
          [
            bot.node.name,
            '' // Empty string for Team Name when there are no teams
          ]
        ];
      }
      return teamNames.map(teamName => [bot.node.name, teamName]);
    });

    if (fileType === 'csv') {
      downloadCsv(headers, rows, 'members.csv');
    } else if (fileType === 'excel') {
      downloadXls(headers, rows, 'members.xls');
    }
  };

  const toggleShowExportOption = () => {
    setIsExportOptionShown(prev => !prev);
  };

  const { classes, organization: { account: { canUpdate } } } = props;
  const { name, key, description, submitted, showModal } = state;

  return ReactDOM.createPortal(
    <>
      <div className={classes.buttonContainer}>
        <Pushbutton secondary onClick={toggleShowExportOption}>
          Export data
        </Pushbutton>
        {canUpdate && (
          <Pushbutton primary onClick={toggleModal}>
            Create New Bot
          </Pushbutton>
        )}
        {isExportOptionShown && (
          <TypeFileViewsMenu
            onDownload={handleDownload}
            onClose={toggleShowExportOption}
          />
        )}
      </div>
      {showModal && (
        <Modal
          title="Create new Bot"
          handleCollapse={toggleModal}
          closeButtonLabel="Cancel"
        >
          <ModalContent>
            <FieldTextCompact
              label="Bot name"
              value={name}
              onValueChange={handleChange('name')}
            />
            <div className={classes.modalSecondaryField}>
              <FieldTextAreaCompact
                label="Bot public signing Key"
                value={key}
                onValueChange={handleChange('key')}
                rows={4}
                noResize
              />
            </div>
            <div className={classes.modalSecondaryField}>
              <FieldTextAreaCompact
                label="Bot description"
                value={description}
                onValueChange={handleChange('description')}
                rows={4}
                noResize
              />
            </div>
          </ModalContent>
          <ModalActions>
            <Pushbutton onClick={toggleModal}>cancel</Pushbutton>
            <Pushbutton
              primary
              disabled={!name || !key || submitted}
              onClick={submitNewBot}
            >
              create bot
            </Pushbutton>
          </ModalActions>
        </Modal>
      )}
    </>,
    document.getElementById('btn-create-new-bot')
  );
};

BotsHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired
};

export default createFragmentContainer(
  compose(withUser, withGqlClient, injectSheet(styles))(BotsHeader),
  {
    organization: graphql`
      fragment botsHeader_organization on Organization {
        id # in order to update the store
        rowId
        account {
          canUpdate
        }
        headerBots: bots {
          edges {
            node {
              id
              rowId
              name
              teams {
                totalCount
                nodes {
                  name
                }
              }
            }
          }
        }
      }
    `
  }
);
