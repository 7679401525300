export default theme => ({
  userHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 24
  },
  updateActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  removeAction: {
    marginRight: 15
  },
  collectionItemFirstChild: {
    borderWidth: '1px 0 0 0',
    borderStyle: 'solid',
    borderColor: theme.grey6
  },
  teamsList: {
    marginTop: 10
  },
  collectionItemLabel: {
    color: theme.grey4,
    fontSize: 10,
    fontWeight: 600,
    paddingBottom: 4,
    letterSpacing: 1,
    textTransform: 'uppercase'
  },
  collectionItemValue: {
    paddingBottom: 16,
    fontSize: 14
  },
  link: {
    display: 'flex',
    alignItems: 'center'
  },
  teamName: {
    paddingLeft: 8
  }
});
