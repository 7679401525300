import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import styles from './tag.style';

export const Tag = ({ classes, text }) => (
  <div className={classes.tagWrapper}>
    <span className={classes.tagText}>{text}</span>
  </div>
);

Tag.propTypes = {
  classes: PropType.object.isRequired,
  text: PropType.string.isRequired
};

export default injectSheet(styles)(Tag);
