/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type inviteOrgMemberModal_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type peopleHeader_organization$ref: FragmentReference;
declare export opaque type peopleHeader_organization$fragmentType: peopleHeader_organization$ref;
export type peopleHeader_organization = {|
  +canUpdate: ?boolean,
  +id: string,
  +rowId: any,
  +organizationOwnerLicensesLeft: ?number,
  +isLeader: ?boolean,
  +account: ?{|
    +id: string,
    +rowId: any,
    +headerMembers: {|
      +totalCount: number,
      +edges: $ReadOnlyArray<{|
        +node: ?{|
          +admin: ?boolean,
          +leader: ?boolean,
          +userId: ?any,
          +collaborator: ?boolean,
          +user: ?{|
            +id: string,
            +rowId: any,
            +name: string,
            +email: string,
            +pending: ?boolean,
            +inviteExpired: boolean,
            +teams: {|
              +totalCount: number,
              +nodes: $ReadOnlyArray<?{|
                +name: string
              |}>,
            |},
          |},
        |}
      |}>,
    |},
  |},
  +$fragmentRefs: inviteOrgMemberModal_organization$ref,
  +$refType: peopleHeader_organization$ref,
|};
export type peopleHeader_organization$data = peopleHeader_organization;
export type peopleHeader_organization$key = {
  +$data?: peopleHeader_organization$data,
  +$fragmentRefs: peopleHeader_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "teamFilter",
      "type": "TeamFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "peopleHeader_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canUpdate",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationOwnerLicensesLeft",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLeader",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": "headerMembers",
          "args": null,
          "concreteType": "MembersConnection",
          "kind": "LinkedField",
          "name": "members",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "MembersEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Member",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "admin",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "leader",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "userId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "collaborator",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "email",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "pending",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "inviteExpired",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Variable",
                              "name": "filter",
                              "variableName": "teamFilter"
                            }
                          ],
                          "concreteType": "TeamsConnection",
                          "kind": "LinkedField",
                          "name": "teams",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Team",
                              "kind": "LinkedField",
                              "name": "nodes",
                              "plural": true,
                              "selections": [
                                (v3/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "inviteOrgMemberModal_organization"
    }
  ],
  "type": "Organization"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c66e6852b7e9202b009786da6e3f22ea';

module.exports = node;
