export default theme => ({
  container: {
    display: 'flex',
    height: '100%'
  },
  title: {
    fontSize: 28,
    fontWeight: 700,
    color: theme.grey1,
    width: 285,
    backgroundColor: theme.greyTable,
    padding: '13px 21px',
    boxSizing: 'border-box',
    flexShrink: 0
  },
  rightPane: {
    width: '100%',
    backgroundColor: 'white',
    overflowY: 'auto',
    padding: '28px 30px'
  }
});
