/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddBotsToTeamInput = {|
  clientMutationId?: ?string,
  teamRowId?: ?any,
  botsRowIds?: ?$ReadOnlyArray<?any>,
|};
export type addTeamBotsMutationVariables = {|
  input: AddBotsToTeamInput,
  teamRowId: any,
|};
export type addTeamBotsMutationResponse = {|
  +addBotsToTeam: ?{|
    +query: ?{|
      +teamByRowId: ?{|
        +bots: {|
          +totalCount: number
        |}
      |}
    |},
    +bots: ?$ReadOnlyArray<?{|
      +rowId: any,
      +name: string,
      +avatar: ?string,
    |}>,
  |}
|};
export type addTeamBotsMutation = {|
  variables: addTeamBotsMutationVariables,
  response: addTeamBotsMutationResponse,
|};
*/


/*
mutation addTeamBotsMutation(
  $input: AddBotsToTeamInput!
  $teamRowId: BigInt!
) {
  addBotsToTeam(input: $input) {
    query {
      teamByRowId(rowId: $teamRowId) {
        bots {
          totalCount
        }
        id
      }
      id
    }
    bots {
      rowId
      name
      avatar
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddBotsToTeamInput!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamRowId",
    "type": "BigInt!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "teamRowId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BotsConnection",
  "kind": "LinkedField",
  "name": "bots",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addTeamBotsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddBotsToTeamPayload",
        "kind": "LinkedField",
        "name": "addBotsToTeam",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "teamByRowId",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Bot",
            "kind": "LinkedField",
            "name": "bots",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addTeamBotsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddBotsToTeamPayload",
        "kind": "LinkedField",
        "name": "addBotsToTeam",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "teamByRowId",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Bot",
            "kind": "LinkedField",
            "name": "bots",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "addTeamBotsMutation",
    "operationKind": "mutation",
    "text": "mutation addTeamBotsMutation(\n  $input: AddBotsToTeamInput!\n  $teamRowId: BigInt!\n) {\n  addBotsToTeam(input: $input) {\n    query {\n      teamByRowId(rowId: $teamRowId) {\n        bots {\n          totalCount\n        }\n        id\n      }\n      id\n    }\n    bots {\n      rowId\n      name\n      avatar\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5189212f2afc72f43b0dddbddc55da11';

module.exports = node;
