export default theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    fontSize: 14,
    backgroundColor: theme.greyBackground1,
    border: `1px solid ${theme.greyBorder2}`,
    borderRadius: 2,
    padding: 8,
    color: theme.grey2
  },
  rootActive: {
    border: `1px solid ${theme.indigo3}`
  },
  input: {
    width: '100%',
    color: 'inherit',
    padding: 0,
    margin: 0,
    border: 'none',
    outline: 'none',
    fontSize: '1em'
  },
  label: {
    padding: 0,
    margin: 0,
    border: 0,
    position: 'absolute',
    color: theme.grey4,
    bottom: 8,
    transition: 'all 0.2s ease-in-out',
    transformOrigin: 'left top',
    fontSize: 'inherit',
    cursor: 'text',
    pointerEvents: 'none'
  },
  labelActive: {
    transform: 'translate3d(0, -85%, 0) scale(0.70)',
    paddingRight: 2,
    paddingLeft: 2,
    backgroundColor: 'inherit'
  },
  icon: {
    position: 'absolute',
    right: 4,
    bottom: 4,
    height: 24,
    width: 24
  },
  // had to separate the dynamic part otherwise '&:' selectors wouldn't work
  rootDynamic: props => ({
    marginTop: !props.top && theme.inputSpacing,
    marginRight: !props.right && theme.inputSpacing
  })
});
