/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type removeTeamCollaboratorModal_collaborator$ref: FragmentReference;
declare export opaque type removeTeamCollaboratorModal_collaborator$fragmentType: removeTeamCollaboratorModal_collaborator$ref;
export type removeTeamCollaboratorModal_collaborator = {|
  +name: string,
  +id: string,
  +rowId: any,
  +teams: {|
    +nodes: $ReadOnlyArray<?{|
      +id: string,
      +rowId: any,
      +name: string,
      +organization: ?{|
        +rowId: any
      |},
    |}>
  |},
  +$refType: removeTeamCollaboratorModal_collaborator$ref,
|};
export type removeTeamCollaboratorModal_collaborator$data = removeTeamCollaboratorModal_collaborator;
export type removeTeamCollaboratorModal_collaborator$key = {
  +$data?: removeTeamCollaboratorModal_collaborator$data,
  +$fragmentRefs: removeTeamCollaboratorModal_collaborator$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "teamFilter",
      "type": "TeamFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "removeTeamCollaboratorModal_collaborator",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "teamFilter"
        }
      ],
      "concreteType": "TeamsConnection",
      "kind": "LinkedField",
      "name": "teams",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Team",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "organization",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User"
};
})();
// prettier-ignore
(node/*: any*/).hash = '90161a1c3a5c4f5041cf63007bd816b1';

module.exports = node;
