/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type organization_organization$ref = any;
export type organizationQueryVariables = {|
  orgRowId: any
|};
export type organizationQueryResponse = {|
  +organizationByRowId: ?{|
    +$fragmentRefs: organization_organization$ref
  |}
|};
export type organizationQuery = {|
  variables: organizationQueryVariables,
  response: organizationQueryResponse,
|};
*/


/*
query organizationQuery(
  $orgRowId: BigInt!
) {
  organizationByRowId(rowId: $orgRowId) {
    ...organization_organization
    id
  }
}

fragment organization_organization on Organization {
  rowId
  name
  avatar
  canUpdate
  isLeader
  account {
    members {
      totalCount
    }
    id
  }
  collaborators {
    totalCount
  }
  teams {
    totalCount
  }
  bots {
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgRowId",
    "type": "BigInt!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "orgRowId"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "organizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationByRowId",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "organization_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "organizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationByRowId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUpdate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLeader",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembersConnection",
                "kind": "LinkedField",
                "name": "members",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UsersConnection",
            "kind": "LinkedField",
            "name": "collaborators",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamsConnection",
            "kind": "LinkedField",
            "name": "teams",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BotsConnection",
            "kind": "LinkedField",
            "name": "bots",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "organizationQuery",
    "operationKind": "query",
    "text": "query organizationQuery(\n  $orgRowId: BigInt!\n) {\n  organizationByRowId(rowId: $orgRowId) {\n    ...organization_organization\n    id\n  }\n}\n\nfragment organization_organization on Organization {\n  rowId\n  name\n  avatar\n  canUpdate\n  isLeader\n  account {\n    members {\n      totalCount\n    }\n    id\n  }\n  collaborators {\n    totalCount\n  }\n  teams {\n    totalCount\n  }\n  bots {\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cf35c9754b0e9f1d38ad78e18803fcbc';

module.exports = node;
