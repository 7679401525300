import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import styles from './box.style';

export class Box extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired,
    children: PropType.node.isRequired,
    title: PropType.string,
    secondaryTitle: PropType.node
  };

  static defaultProps = {
    title: null,
    secondaryTitle: null
  };

  render() {
    const { classes, children, title, secondaryTitle } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.box}>
          {(title || secondaryTitle) && (
            <div className={classes.header}>
              {title && <div className={classes.title}>{title}</div>}
              {secondaryTitle}
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }
}

export default injectSheet(styles)(Box);
