import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import compose from 'lodash.flowright';
import {
  Modal,
  ModalContent,
  Pushbutton,
  FieldPasswordCompact
} from '@stratumn/atomic';
import { withRouter } from 'react-router-dom';
import { sig } from '@stratumn/js-crypto';

import addToClipBoard from 'utils/addToClipBoard';
import { withGqlClient, withUser } from 'wrappers';
import { toast } from 'sonner/dist';
import styles from './decryptKeyModal.style';

const INITIAL_STATE = {
  password: '',
  passwordVisible: false
};

export class DecryptAndCopyKeyModal extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired,
    encryptedKey: PropType.string.isRequired,
    toggleModal: PropType.func.isRequired
  };

  // This ref is used so that the addToClipboard is over the veneer.
  copyRef = React.createRef();
  state = INITIAL_STATE;

  handlePasswordChange = e => this.setState({ password: e.target.value });
  togglePasswordVisible = () =>
    this.setState({ oldPasswordVisible: !this.state.oldPasswordVisible });

  decryptAndCopy = () => {
    const { encryptedKey, toggleModal } = this.props;
    const { password } = this.state;
    try {
      const k = new sig.SigningPrivateKey({
        pemPrivateKey: encryptedKey,
        password
      });
      addToClipBoard(k.export(), this.copyRef.current);
      toggleModal(true);
    } catch (e) {
      toast.error(
        'Could not decrypt signing private key with the given password.'
      );
    }
  };

  render() {
    const { classes, toggleModal } = this.props;
    const { password, passwordVisible } = this.state;

    return (
      <Modal title="Decrypting Signing Key" handleCollapse={toggleModal}>
        <ModalContent>
          <FieldPasswordCompact
            label="Key password"
            data-cy="password"
            onValueChange={this.handlePasswordChange}
            value={password}
            onVisibilityChange={this.togglePasswordVisible}
            passwordIsVisible={passwordVisible}
          />
        </ModalContent>
        <div className={classes.modalActionsRoot} ref={this.copyRef}>
          <div className={classes.modalActions}>
            <Pushbutton
              disabled={!password}
              primary
              onClick={this.decryptAndCopy}
            >
              decrypt
            </Pushbutton>
          </div>
        </div>
      </Modal>
    );
  }
}

export default compose(
  withUser,
  withRouter,
  withGqlClient,
  injectSheet(styles)
)(DecryptAndCopyKeyModal);
