/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type removeTeamCollaboratorModal_collaborator$ref = any;
export type TeamFilter = {|
  rowId?: ?BigIntFilter,
  name?: ?StringFilter,
  description?: ?StringFilter,
  avatar?: ?StringFilter,
  organizationId?: ?BigIntFilter,
  accountId?: ?BigIntFilter,
  createdAt?: ?DatetimeFilter,
  updatedAt?: ?DatetimeFilter,
  email?: ?StringFilter,
  canInsert?: ?BooleanFilter,
  canUpdate?: ?BooleanFilter,
  and?: ?$ReadOnlyArray<TeamFilter>,
  or?: ?$ReadOnlyArray<TeamFilter>,
  not?: ?TeamFilter,
|};
export type BigIntFilter = {|
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
|};
export type StringFilter = {|
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  similarTo?: ?string,
  notSimilarTo?: ?string,
|};
export type DatetimeFilter = {|
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
|};
export type BooleanFilter = {|
  isNull?: ?boolean,
  equalTo?: ?boolean,
  notEqualTo?: ?boolean,
  distinctFrom?: ?boolean,
  notDistinctFrom?: ?boolean,
  in?: ?$ReadOnlyArray<boolean>,
  notIn?: ?$ReadOnlyArray<boolean>,
|};
export type removeTeamCollaboratorModalQueryVariables = {|
  userRowId: any,
  teamFilter?: ?TeamFilter,
|};
export type removeTeamCollaboratorModalQueryResponse = {|
  +userByRowId: ?{|
    +$fragmentRefs: removeTeamCollaboratorModal_collaborator$ref
  |}
|};
export type removeTeamCollaboratorModalQuery = {|
  variables: removeTeamCollaboratorModalQueryVariables,
  response: removeTeamCollaboratorModalQueryResponse,
|};
*/


/*
query removeTeamCollaboratorModalQuery(
  $userRowId: BigInt!
  $teamFilter: TeamFilter
) {
  userByRowId(rowId: $userRowId) {
    ...removeTeamCollaboratorModal_collaborator_2jn8eO
    id
  }
}

fragment removeTeamCollaboratorModal_collaborator_2jn8eO on User {
  name
  id
  rowId
  teams(filter: $teamFilter) {
    nodes {
      id
      rowId
      name
      organization {
        rowId
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userRowId",
    "type": "BigInt!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamFilter",
    "type": "TeamFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "userRowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeTeamCollaboratorModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userByRowId",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "teamFilter",
                "variableName": "teamFilter"
              }
            ],
            "kind": "FragmentSpread",
            "name": "removeTeamCollaboratorModal_collaborator"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeTeamCollaboratorModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "teamFilter"
              }
            ],
            "concreteType": "TeamsConnection",
            "kind": "LinkedField",
            "name": "teams",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "removeTeamCollaboratorModalQuery",
    "operationKind": "query",
    "text": "query removeTeamCollaboratorModalQuery(\n  $userRowId: BigInt!\n  $teamFilter: TeamFilter\n) {\n  userByRowId(rowId: $userRowId) {\n    ...removeTeamCollaboratorModal_collaborator_2jn8eO\n    id\n  }\n}\n\nfragment removeTeamCollaboratorModal_collaborator_2jn8eO on User {\n  name\n  id\n  rowId\n  teams(filter: $teamFilter) {\n    nodes {\n      id\n      rowId\n      name\n      organization {\n        rowId\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cf67f501c8c5df94891c688fd1efc11f';

module.exports = node;
