import React, { useState } from 'react';
import graphql from 'babel-plugin-relay/macro';
import PropType from 'prop-types';
import { createFragmentContainer } from 'react-relay';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import to from 'await-to-js';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';

import { removeOrgMember } from 'mutations';

import {
  Modal,
  ModalActions,
  ModalContent,
  Pushbutton,
  Prose
} from '@stratumn/atomic';

import { SpanType, withSpanAsync } from 'tracing';
import { withGqlClient } from 'wrappers';

import { COLLABORATOR_KEY } from 'constant/types';

import styles from './removeCollaboratorModal.style';

export function RemoveCollaboratorModal({
  classes,
  environment,
  organizationId,
  organizationRowId,
  collaborator,
  collaborator: { teams },
  onClose
}) {
  const [submitted, setSubmit] = useState(false);

  const removeCollaborator = () => {
    withSpanAsync(`removeOrgMember`, SpanType.processing, async () => {
      setSubmit(true);

      const [err] = await to(
        removeOrgMember(
          environment,
          null,
          COLLABORATOR_KEY,
          organizationId, // In order to change the totalCount in relay store
          organizationRowId,
          collaborator.id,
          collaborator.rowId,
          true // removes the collaborator from all his teams
        )
      );

      if (err) {
        Sentry.captureException(err);
        setSubmit(false);
      } else {
        onClose();
      }

      return {};
    });
  };

  const modalDescription = `This action will remove **${
    collaborator.name ? collaborator.name : collaborator.email
  }** from your Collaborators and the following Team${
    teams.totalCount > 1 ? 's' : ''
  }:`;

  return (
    <Modal
      title="Remove Collaborator"
      handleCollapse={onClose}
      closeButtonLabel="Cancel"
    >
      <ModalContent>
        <Prose light text={modalDescription} />
        <ul className={classes.teamList}>
          {teams.nodes.map(n => (
            <li className={classes.teamListItems} key={n.id}>
              {n.name}
            </li>
          ))}
        </ul>
      </ModalContent>
      <ModalActions>
        <Pushbutton onClick={onClose}>cancel</Pushbutton>
        <Pushbutton
          warning
          onClick={() => removeCollaborator()}
          disabled={submitted}
        >
          Remove Collaborator
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
}

RemoveCollaboratorModal.propTypes = {
  environment: PropType.object.isRequired,
  classes: PropType.object.isRequired,
  organizationId: PropType.string.isRequired,
  organizationRowId: PropType.string.isRequired,
  collaborator: PropType.object.isRequired,
  onClose: PropType.func.isRequired
};

export default createFragmentContainer(
  compose(withRouter, withGqlClient, injectSheet(styles))(
    RemoveCollaboratorModal
  ),
  {
    collaborator: graphql`
      fragment removeCollaboratorModal_collaborator on User
        @argumentDefinitions(
          teamFilter: { type: "TeamFilter", defaultValue: {} }
        ) {
        id # Needed to update relay's store
        rowId
        email
        name
        teams(filter: $teamFilter) {
          totalCount
          nodes {
            id
            name
          }
        }
      }
    `
  }
);
