/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveOrganizationMemberInput = {|
  clientMutationId?: ?string,
  userRowId?: ?any,
  organizationRowId?: ?any,
  removeFromTeams?: ?boolean,
|};
export type removeOrganizationMemberMutationVariables = {|
  input: RemoveOrganizationMemberInput
|};
export type removeOrganizationMemberMutationResponse = {|
  +removeOrganizationMember: ?{|
    +boolean: ?boolean
  |}
|};
export type removeOrganizationMemberMutation = {|
  variables: removeOrganizationMemberMutationVariables,
  response: removeOrganizationMemberMutationResponse,
|};
*/


/*
mutation removeOrganizationMemberMutation(
  $input: RemoveOrganizationMemberInput!
) {
  removeOrganizationMember(input: $input) {
    boolean
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveOrganizationMemberInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveOrganizationMemberPayload",
    "kind": "LinkedField",
    "name": "removeOrganizationMember",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "boolean",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeOrganizationMemberMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeOrganizationMemberMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "removeOrganizationMemberMutation",
    "operationKind": "mutation",
    "text": "mutation removeOrganizationMemberMutation(\n  $input: RemoveOrganizationMemberInput!\n) {\n  removeOrganizationMember(input: $input) {\n    boolean\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '88f58e04ccf31b76a5c313e931d43ca0';

module.exports = node;
