import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import { generate } from 'shortid';

import styles from './textInput.style';

export class TextInput extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired,
    label: PropType.string,
    top: PropType.bool,
    right: PropType.bool,
    className: PropType.string,
    icon: PropType.node,
    onIconClick: PropType.func
  };

  static defaultProps = {
    top: false,
    right: false,
    className: '',
    label: '',
    icon: null,
    onIconClick: null
  };

  state = {
    active: false
  };

  id = generate();

  onFocus = event => {
    this.setState({ active: true });
    if (this.props.onFocus) this.props.onFocus(event);
  };

  onBlur = event => {
    this.setState({ active: event.target.value.length !== 0 });
    if (this.props.onBlur) this.props.onBlur(event);
  };

  render() {
    const { active } = this.state;
    const {
      top,
      right,
      className,
      classes,
      label,
      icon,
      onIconClick,
      ...props
    } = this.props;

    const rootClasses = classNames(
      classes.root,
      classes.rootDynamic,
      { [classes.rootActive]: active },
      className
    );

    const labelClasses = classNames(classes.label, {
      [classes.labelActive]: active
    });

    // Disable the placeholder if we are using a label
    if (label) {
      props.placeholder = undefined;
    }

    // Make sure an id is included in the props
    props.id = props.id || this.id;

    return (
      <div className={rootClasses}>
        {!!label && (
          <label className={labelClasses} htmlFor={props.id}>
            {label}
          </label>
        )}
        <input
          className={classes.input}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          {...props}
        />
        {!!icon && (
          <div className={classes.icon} onClick={onIconClick}>
            {icon}
          </div>
        )}
      </div>
    );
  }
}

export default injectSheet(styles)(TextInput);
