export default theme => ({
  teamList: {
    marginTop: 8
  },
  teamListItems: {
    fontWeight: '700',
    '&::before': {
      content: '"-"',
      marginRight: 4
    },
    fontSize: 14,
    color: theme.grey2
  }
});
