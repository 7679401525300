import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import { NavLink } from 'react-router-dom';
import { ROUTE_GDPR_POLICY_PAGE } from 'constant/routes';

import styles from './footer.style';

export const Footer = ({ classes }) => (
  <div className={classes.container}>
    Stratumn &copy; {new Date(Date.now()).getFullYear()}. All rights reserved.{' '}
    <NavLink to={ROUTE_GDPR_POLICY_PAGE}>GDPR privacy policy</NavLink>
  </div>
);

Footer.propTypes = {
  classes: PropType.object.isRequired
};

export default injectSheet(styles)(Footer);
