export default theme => ({
  '@global': {
    body: {
      backgroundColor: theme.grey8
    },
    a: {
      '&:not([role="button"])': {
        color: theme.indigo3,
        textDecoration: 'none',
        '&:focus': {
          textDecoration: 'underline',
          outline: 'none'
        }
      }
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 440,
    margin: 'auto'
  },
  box: {
    backgroundColor: theme.white1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    borderRadius: 10,
    boxShadow: '0px 3px 20px rgba(75, 53, 210, 0.1)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15
  },
  title: {
    fontSize: 30,
    fontWeight: 200,
    color: theme.grey2
  }
});
