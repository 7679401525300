/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveBotFromTeamInput = {|
  clientMutationId?: ?string,
  teamRowId?: ?any,
  botRowId?: ?any,
|};
export type removeTeamBotMutationVariables = {|
  input: RemoveBotFromTeamInput
|};
export type removeTeamBotMutationResponse = {|
  +removeBotFromTeam: ?{|
    +bot: ?{|
      +id: string,
      +rowId: any,
      +name: string,
      +teams: {|
        +nodes: $ReadOnlyArray<?{|
          +id: string,
          +rowId: any,
          +name: string,
        |}>
      |},
    |}
  |}
|};
export type removeTeamBotMutation = {|
  variables: removeTeamBotMutationVariables,
  response: removeTeamBotMutationResponse,
|};
*/


/*
mutation removeTeamBotMutation(
  $input: RemoveBotFromTeamInput!
) {
  removeBotFromTeam(input: $input) {
    bot {
      id
      rowId
      name
      teams {
        nodes {
          id
          rowId
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "RemoveBotFromTeamInput!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveBotFromTeamPayload",
    "kind": "LinkedField",
    "name": "removeBotFromTeam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Bot",
        "kind": "LinkedField",
        "name": "bot",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamsConnection",
            "kind": "LinkedField",
            "name": "teams",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeTeamBotMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeTeamBotMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "removeTeamBotMutation",
    "operationKind": "mutation",
    "text": "mutation removeTeamBotMutation(\n  $input: RemoveBotFromTeamInput!\n) {\n  removeBotFromTeam(input: $input) {\n    bot {\n      id\n      rowId\n      name\n      teams {\n        nodes {\n          id\n          rowId\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2891de92457d6f5da869fe148199e5b2';

module.exports = node;
