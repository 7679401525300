import React from 'react';
import PropType from 'prop-types';
import passwordHide from 'assets/password-hide.svg';
import passwordShow from 'assets/password-show.svg';

import { TextInput } from 'components/ui';

export class PasswordField extends React.PureComponent {
  static propTypes = {
    show: PropType.bool.isRequired,
    onPasswordChange: PropType.func.isRequired,
    onShowChange: PropType.func.isRequired
  };

  render() {
    const {
      classes,
      onShowChange,
      onPasswordChange,
      show,
      ...props
    } = this.props;

    const alt = show ? 'hide password' : 'show password';
    const src = show ? passwordShow : passwordHide;
    const type = show ? 'text' : 'password';

    return (
      <TextInput
        type={type}
        onChange={onPasswordChange}
        icon={<img src={src} alt={alt} />}
        onIconClick={onShowChange}
        {...props}
      />
    );
  }
}

export default PasswordField;
