import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation removeOrganizationBotMutation(
    $input: RemoveBotFromOrganizationInput!
    $organizationRowId: BigInt!
  ) {
    removeBotFromOrganization(input: $input) {
      query {
        organizationByRowId(rowId: $organizationRowId) {
          bots {
            totalCount
          }
        }
      }
    }
  }
`;

export default (environment, botRowId, organizationRowId) =>
  withSpanAsync('removeBotFromOrganization', SpanType.mutation, async () => {
    const variables = {
      input: {
        botRowId,
        organizationRowId
      },
      organizationRowId
    };

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);

          resolve();
        },
        onError: err => {
          console.error(err);
          reject(err);
        }
      });
    });
  });
