import React, { useState, memo } from 'react';
import PropType from 'prop-types';
import * as Sentry from '@sentry/react';
import to from 'await-to-js';
import compose from 'lodash.flowright';
import injectSheet from 'react-jss';

import { sendUserInvite, removeOrgMember } from 'mutations';

import { Pushtext } from '@stratumn/atomic';

import { SpanType, withSpanAsync } from 'tracing';
import { withGqlClient } from 'wrappers';
import { toast } from 'sonner/dist';
import styles from './pendingInvite.style';

export function PendingInvite({
  accountId,
  accountRowId,
  classes,
  connectionKey,
  email,
  environment,
  organizationId,
  organizationRowId,
  userId,
  userRowId,
  expired
}) {
  const [submitted, setSubmit] = useState(false);

  const resendUserInvite = () =>
    withSpanAsync('sendUserInvite', SpanType.processing, async () => {
      const [err] = await to(
        sendUserInvite(environment, userId, userRowId, accountRowId)
      );

      if (err) {
        Sentry.captureException(err);
      } else {
        toast.success(`Invitation resent to ${email}`);
      }
    });

  const cancelMemberInvite = () => {
    withSpanAsync('removeOrgMember', SpanType.processing, async () => {
      setSubmit(true);

      const [err] = await to(
        removeOrgMember(
          environment,
          accountId,
          connectionKey,
          organizationId,
          organizationRowId,
          userId,
          userRowId,
          true // removes the member or collaborator from all his teams
        )
      );

      if (err) {
        Sentry.captureException(err);
        setSubmit(false);
      } else {
        toast.success(`Invitation to ${email} cancelled`);
      }
    });
  };

  return (
    <>
      <div
        data-cy="invitation-status"
        className={classes.description}
        data-is-expired={expired}
      >
        {!expired ? 'pending invite' : 'expired'}
      </div>
      <div className={classes.button}>
        <Pushtext label="Resend" onClick={resendUserInvite}>
          Resend
        </Pushtext>
      </div>
      <span>|</span>
      <div className={classes.button}>
        <Pushtext
          label="Cancel"
          onClick={cancelMemberInvite}
          disabled={submitted}
        >
          Cancel
        </Pushtext>
      </div>
    </>
  );
}

PendingInvite.propTypes = {
  accountId: PropType.string.isRequired,
  accountRowId: PropType.string.isRequired,
  classes: PropType.object.isRequired,
  email: PropType.string.isRequired,
  environment: PropType.object.isRequired,
  userRowId: PropType.string.isRequired,
  expired: PropType.bool
};

PendingInvite.defaultPropTypes = {
  expired: false
};

export default compose(withGqlClient, injectSheet(styles))(memo(PendingInvite));
