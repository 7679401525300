/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type collaboratorsHeader_organization$ref = any;
type collaborators_organization$ref = any;
export type Provider = "CNP" | "COGNITO" | "GOOGLE" | "KEYCLOAK" | "MICROSOFT_SIA_PARTNERS" | "ONE_ACCOUNT" | "ONE_ACCOUNT_AXA" | "PASS_AXA" | "STRATUMN" | "%future added value";
export type TeamFilter = {|
  rowId?: ?BigIntFilter,
  name?: ?StringFilter,
  description?: ?StringFilter,
  avatar?: ?StringFilter,
  organizationId?: ?BigIntFilter,
  accountId?: ?BigIntFilter,
  createdAt?: ?DatetimeFilter,
  updatedAt?: ?DatetimeFilter,
  email?: ?StringFilter,
  canInsert?: ?BooleanFilter,
  canUpdate?: ?BooleanFilter,
  and?: ?$ReadOnlyArray<TeamFilter>,
  or?: ?$ReadOnlyArray<TeamFilter>,
  not?: ?TeamFilter,
|};
export type BigIntFilter = {|
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
|};
export type StringFilter = {|
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  similarTo?: ?string,
  notSimilarTo?: ?string,
|};
export type DatetimeFilter = {|
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
|};
export type BooleanFilter = {|
  isNull?: ?boolean,
  equalTo?: ?boolean,
  notEqualTo?: ?boolean,
  distinctFrom?: ?boolean,
  notDistinctFrom?: ?boolean,
  in?: ?$ReadOnlyArray<boolean>,
  notIn?: ?$ReadOnlyArray<boolean>,
|};
export type UserFilter = {|
  rowId?: ?BigIntFilter,
  email?: ?StringFilter,
  phone?: ?StringFilter,
  avatar?: ?StringFilter,
  accountId?: ?BigIntFilter,
  isSuperuser?: ?BooleanFilter,
  createdAt?: ?DatetimeFilter,
  updatedAt?: ?DatetimeFilter,
  pending?: ?BooleanFilter,
  firstName?: ?StringFilter,
  lastName?: ?StringFilter,
  allowedProviders?: ?ProviderListFilter,
  notificationPreference?: ?JSONFilter,
  canInsert?: ?BooleanFilter,
  canUpdate?: ?BooleanFilter,
  name?: ?StringFilter,
  and?: ?$ReadOnlyArray<UserFilter>,
  or?: ?$ReadOnlyArray<UserFilter>,
  not?: ?UserFilter,
|};
export type ProviderListFilter = {|
  isNull?: ?boolean,
  equalTo?: ?$ReadOnlyArray<?Provider>,
  notEqualTo?: ?$ReadOnlyArray<?Provider>,
  distinctFrom?: ?$ReadOnlyArray<?Provider>,
  notDistinctFrom?: ?$ReadOnlyArray<?Provider>,
  anyEqualTo?: ?Provider,
  anyNotEqualTo?: ?Provider,
|};
export type JSONFilter = {|
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  contains?: ?any,
  containedBy?: ?any,
|};
export type collaboratorsRootQueryVariables = {|
  orgRowId: any,
  teamFilter?: ?TeamFilter,
  collaboratorFilter?: ?UserFilter,
|};
export type collaboratorsRootQueryResponse = {|
  +organizationByRowId: ?{|
    +$fragmentRefs: collaboratorsHeader_organization$ref & collaborators_organization$ref
  |}
|};
export type collaboratorsRootQuery = {|
  variables: collaboratorsRootQueryVariables,
  response: collaboratorsRootQueryResponse,
|};
*/


/*
query collaboratorsRootQuery(
  $orgRowId: BigInt!
  $teamFilter: TeamFilter
  $collaboratorFilter: UserFilter
) {
  organizationByRowId(rowId: $orgRowId) {
    ...collaboratorsHeader_organization
    ...collaborators_organization_1O8CZq
    id
  }
}

fragment collaboratorsHeader_organization on Organization {
  id
  rowId
  account {
    id
    rowId
  }
  headerCollaborators: collaborators {
    totalCount
    edges {
      node {
        id
        rowId
        name
        email
        pending
        inviteExpired
        teams(filter: $teamFilter) {
          totalCount
          nodes {
            name
            id
          }
        }
      }
    }
  }
}

fragment collaborators_organization_1O8CZq on Organization {
  ...collaboratorsHeader_organization
  id
  rowId
  canUpdate
  account {
    id
    rowId
  }
  collaborators(filter: $collaboratorFilter) {
    totalCount
    edges {
      node {
        ...removeCollaboratorModal_collaborator_2jn8eO
        id
        rowId
        name
        email
        avatar
        pending
        inviteExpired
        teams(filter: $teamFilter) {
          totalCount
          nodes {
            name
            account {
              rowId
              id
            }
            id
          }
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment removeCollaboratorModal_collaborator_2jn8eO on User {
  id
  rowId
  email
  name
  teams(filter: $teamFilter) {
    totalCount
    nodes {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgRowId",
    "type": "BigInt!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamFilter",
    "type": "TeamFilter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collaboratorFilter",
    "type": "UserFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "orgRowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pending",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inviteExpired",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "teamFilter"
  }
],
v10 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "collaboratorFilter"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "collaboratorsRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationByRowId",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "collaboratorsHeader_organization"
          },
          {
            "args": [
              {
                "kind": "Variable",
                "name": "collaboratorFilter",
                "variableName": "collaboratorFilter"
              },
              {
                "kind": "Variable",
                "name": "teamFilter",
                "variableName": "teamFilter"
              }
            ],
            "kind": "FragmentSpread",
            "name": "collaborators_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "collaboratorsRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "headerCollaborators",
            "args": null,
            "concreteType": "UsersConnection",
            "kind": "LinkedField",
            "name": "collaborators",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UsersEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": (v9/*: any*/),
                        "concreteType": "TeamsConnection",
                        "kind": "LinkedField",
                        "name": "teams",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Team",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canUpdate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "UsersConnection",
            "kind": "LinkedField",
            "name": "collaborators",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UsersEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": (v9/*: any*/),
                        "concreteType": "TeamsConnection",
                        "kind": "LinkedField",
                        "name": "teams",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Team",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Account",
                                "kind": "LinkedField",
                                "name": "account",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "avatar",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Collaborators_collaborators",
            "kind": "LinkedHandle",
            "name": "collaborators"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "collaboratorsRootQuery",
    "operationKind": "query",
    "text": "query collaboratorsRootQuery(\n  $orgRowId: BigInt!\n  $teamFilter: TeamFilter\n  $collaboratorFilter: UserFilter\n) {\n  organizationByRowId(rowId: $orgRowId) {\n    ...collaboratorsHeader_organization\n    ...collaborators_organization_1O8CZq\n    id\n  }\n}\n\nfragment collaboratorsHeader_organization on Organization {\n  id\n  rowId\n  account {\n    id\n    rowId\n  }\n  headerCollaborators: collaborators {\n    totalCount\n    edges {\n      node {\n        id\n        rowId\n        name\n        email\n        pending\n        inviteExpired\n        teams(filter: $teamFilter) {\n          totalCount\n          nodes {\n            name\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment collaborators_organization_1O8CZq on Organization {\n  ...collaboratorsHeader_organization\n  id\n  rowId\n  canUpdate\n  account {\n    id\n    rowId\n  }\n  collaborators(filter: $collaboratorFilter) {\n    totalCount\n    edges {\n      node {\n        ...removeCollaboratorModal_collaborator_2jn8eO\n        id\n        rowId\n        name\n        email\n        avatar\n        pending\n        inviteExpired\n        teams(filter: $teamFilter) {\n          totalCount\n          nodes {\n            name\n            account {\n              rowId\n              id\n            }\n            id\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment removeCollaboratorModal_collaborator_2jn8eO on User {\n  id\n  rowId\n  email\n  name\n  teams(filter: $teamFilter) {\n    totalCount\n    nodes {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f594b0807d3ba3d1a17594a03d34c07a';

module.exports = node;
