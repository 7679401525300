import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import styles from './button.style';

export class Button extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired,
    onClick: PropType.func.isRequired,
    children: PropType.node.isRequired,
    top: PropType.bool,
    right: PropType.bool,
    className: PropType.string
  };

  static defaultProps = {
    top: false,
    right: false,
    className: ''
  };

  render() {
    const {
      classes,
      onClick,
      children,
      top,
      right,
      className,
      ...props
    } = this.props;

    return (
      <button
        onClick={onClick}
        className={classNames(classes.root, classes.rootDynamic, className)}
        {...props}
      >
        {children}
      </button>
    );
  }
}

export default injectSheet(styles)(Button);
