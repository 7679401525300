import React, { useState } from 'react';

import PropType from 'prop-types';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';
import { withRouter } from 'react-router-dom';
import { withGqlClient, withUser } from 'wrappers';

import { SearchBar } from 'components/ui';
import { useDebounce } from 'utils/hooks';
import { SEARCH_DELAY } from 'constant/timer';

import People from './people';
import PeopleHeader from './peopleHeader';
import LoadingState from '../loadingState';

const query = graphql`
  query peopleRootQuery(
    $orgRowId: BigInt!
    $teamFilter: TeamFilter
    $memberFilter: MemberFilter
  ) {
    organizationByRowId(rowId: $orgRowId) {
      ...peopleHeader_organization
      ...people_organization
        @arguments(teamFilter: $teamFilter, memberFilter: $memberFilter)
    }
  }
`;

export const Renderer = ({ error, props: data }) => {
  if (error) return <div>error!</div>;
  if (!data) return <LoadingState />;
  const { organizationByRowId } = data;
  return (
    <>
      <PeopleHeader organization={organizationByRowId} />
      <People organization={organizationByRowId} />
    </>
  );
};

Renderer.propTypes = {
  error: PropType.object,
  props: PropType.object
};

Renderer.defaultProps = {
  error: undefined,
  props: undefined
};

export const PeopleRoot = props => {
  const { environment, selectedOrganization, match, classes } = props;
  const variables = {};

  const [char, setChar] = useState('');
  const search = useDebounce(char, SEARCH_DELAY);

  // There are some users that have unrealistic data so we need to account
  // for them to not crash the UI.
  if (!selectedOrganization || !selectedOrganization.rowId) {
    return null;
  }
  const { rowId } = selectedOrganization;
  const organisationId = match.params.orgId || rowId;
  variables.orgRowId = organisationId;
  /* 
   Important teamfilter:
   We only want the count of the teams that the user is a part of in this organization. 
   Otherwise, if a user is a part of teams in multiple organizations we will get the wrong count.
  */

  variables.teamFilter = { organizationId: { equalTo: organisationId } };
  variables.memberFilter = {
    or: [
      { name: { likeInsensitive: `%${search}%` } },
      { email: { likeInsensitive: `%${search}%` } }
    ]
  };

  const RendererWithRouter = withRouter(prps => (
    <Renderer {...prps} classes={classes} />
  ));

  return (
    <>
      <SearchBar label="Search Members" search={char} setSearch={setChar}>
        <div id="btn-invite-org-member" />
      </SearchBar>

      <QueryRenderer
        environment={environment}
        query={query}
        variables={variables}
        render={RendererWithRouter}
      />
    </>
  );
};

PeopleRoot.propTypes = {
  classes: PropType.object.isRequired,
  environment: PropType.object.isRequired,
  selectedOrganization: PropType.object
};

PeopleRoot.defaultProps = {
  selectedOrganization: null
};

export default compose(withUser, withGqlClient)(PeopleRoot);
