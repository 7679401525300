/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type collaborator_collaborator$ref = any;
export type TeamFilter = {|
  rowId?: ?BigIntFilter,
  name?: ?StringFilter,
  description?: ?StringFilter,
  avatar?: ?StringFilter,
  organizationId?: ?BigIntFilter,
  accountId?: ?BigIntFilter,
  createdAt?: ?DatetimeFilter,
  updatedAt?: ?DatetimeFilter,
  email?: ?StringFilter,
  canInsert?: ?BooleanFilter,
  canUpdate?: ?BooleanFilter,
  and?: ?$ReadOnlyArray<TeamFilter>,
  or?: ?$ReadOnlyArray<TeamFilter>,
  not?: ?TeamFilter,
|};
export type BigIntFilter = {|
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
|};
export type StringFilter = {|
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  similarTo?: ?string,
  notSimilarTo?: ?string,
|};
export type DatetimeFilter = {|
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
|};
export type BooleanFilter = {|
  isNull?: ?boolean,
  equalTo?: ?boolean,
  notEqualTo?: ?boolean,
  distinctFrom?: ?boolean,
  notDistinctFrom?: ?boolean,
  in?: ?$ReadOnlyArray<boolean>,
  notIn?: ?$ReadOnlyArray<boolean>,
|};
export type collaboratorRootQueryVariables = {|
  userRowId: any,
  teamFilter?: ?TeamFilter,
|};
export type collaboratorRootQueryResponse = {|
  +userByRowId: ?{|
    +$fragmentRefs: collaborator_collaborator$ref
  |}
|};
export type collaboratorRootQuery = {|
  variables: collaboratorRootQueryVariables,
  response: collaboratorRootQueryResponse,
|};
*/


/*
query collaboratorRootQuery(
  $userRowId: BigInt!
  $teamFilter: TeamFilter
) {
  userByRowId(rowId: $userRowId) {
    ...collaborator_collaborator_2jn8eO
    id
  }
}

fragment collaborator_collaborator_2jn8eO on User {
  ...removeCollaboratorModal_collaborator_2jn8eO
  id
  rowId
  name
  email
  avatar
  teams(filter: $teamFilter) {
    totalCount
    nodes {
      id
      rowId
      avatar
      name
      canUpdate
    }
  }
}

fragment removeCollaboratorModal_collaborator_2jn8eO on User {
  id
  rowId
  email
  name
  teams(filter: $teamFilter) {
    totalCount
    nodes {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userRowId",
    "type": "BigInt!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamFilter",
    "type": "TeamFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "userRowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "collaboratorRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userByRowId",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "teamFilter",
                "variableName": "teamFilter"
              }
            ],
            "kind": "FragmentSpread",
            "name": "collaborator_collaborator"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "collaboratorRootQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "teamFilter"
              }
            ],
            "concreteType": "TeamsConnection",
            "kind": "LinkedField",
            "name": "teams",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canUpdate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "collaboratorRootQuery",
    "operationKind": "query",
    "text": "query collaboratorRootQuery(\n  $userRowId: BigInt!\n  $teamFilter: TeamFilter\n) {\n  userByRowId(rowId: $userRowId) {\n    ...collaborator_collaborator_2jn8eO\n    id\n  }\n}\n\nfragment collaborator_collaborator_2jn8eO on User {\n  ...removeCollaboratorModal_collaborator_2jn8eO\n  id\n  rowId\n  name\n  email\n  avatar\n  teams(filter: $teamFilter) {\n    totalCount\n    nodes {\n      id\n      rowId\n      avatar\n      name\n      canUpdate\n    }\n  }\n}\n\nfragment removeCollaboratorModal_collaborator_2jn8eO on User {\n  id\n  rowId\n  email\n  name\n  teams(filter: $teamFilter) {\n    totalCount\n    nodes {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0ab54c10a4ca1a66e8c56768f567cb9e';

module.exports = node;
