import React from 'react';
import PropType from 'prop-types';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { withGqlClient } from '../gqlClient';
import SignedInViewer from './signedInViewer';

export { withUser, UserContext } from './signedInViewer';

const meQuery = graphql`
  query privateRouteUserQuery {
    me {
      isSuperuser
      ... on User {
        ...signedInViewer_user
      }
    }
  }
`;

const orgsQuery = graphql`
  query privateRouteOrgsQuery {
    organizations {
      ... on OrganizationsConnection {
        ...signedInViewer_superUserOrgs
      }
    }
  }
`;

export class PrivateRoute extends React.Component {
  static propTypes = {
    component: PropType.func.isRequired,
    environment: PropType.object.isRequired
  };

  render() {
    const { component, environment, ...rest } = this.props;
    return (
      <QueryRenderer
        environment={environment}
        query={meQuery}
        variables={{}}
        render={({ error: userError, props: userProps }) => {
          if (userError) return <div>error!</div>;
          if (!userProps) return <div>loading...</div>;
          if (!userProps.me.isSuperuser) {
            return (
              <SignedInViewer
                component={component}
                user={userProps.me}
                superUserOrgs={null}
                {...rest}
              />
            );
          }
          return (
            <QueryRenderer
              environment={environment}
              query={orgsQuery}
              variables={{}}
              render={({ error: orgError, props: orgProps }) => {
                if (orgError) return <div>error!</div>;
                if (!orgProps) return <div>loading...</div>;
                return (
                  <SignedInViewer
                    component={component}
                    user={userProps.me}
                    superUserOrgs={orgProps.organizations}
                    {...rest}
                  />
                );
              }}
            />
          );
        }}
      />
    );
  }
}

export default withGqlClient(PrivateRoute);
