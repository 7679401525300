import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import validator from 'validator';

import { FieldTextCompact, InfoTooltip } from '@stratumn/atomic';
import { Pen, Save, TimesCircle, Add, Trash } from '@stratumn/icons';
import styles from './mailingList.style';

const { isEmail } = validator;

export const MailingList = ({
  canUpdateOrganization,
  email,
  onUpdate,
  classes
}) => {
  const [mailingListEmail, setMailingListEmail] = useState(email || '');
  const [editEmail, setEditEmail] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(isEmail(email));

  useEffect(
    () => {
      const emailIsValid = isEmail(mailingListEmail);
      setIsDirty(email !== mailingListEmail);
      setIsValidEmail(emailIsValid);
    },
    [mailingListEmail, email]
  );

  const handleAddClick = () => {
    onUpdate(mailingListEmail).catch(() => {
      setMailingListEmail(email);
    });
    setEditEmail(!editEmail);
  };

  const handleChange = useCallback(e => {
    const newValue = e.target.value;
    setMailingListEmail(newValue);
  }, []);

  const handleDelete = () => {
    setMailingListEmail('');
    onUpdate('').catch(() => {});
    setEditEmail(!editEmail);
  };

  const toggleEditButton = () => {
    setEditEmail(!editEmail);
    if (isDirty) {
      setMailingListEmail(email);
    }
  };

  if (!mailingListEmail && !canUpdateOrganization) return null;

  return (
    <div className={classes.container}>
      {!editEmail &&
        (mailingListEmail ? (
          <p>
            Notification mailing list:
            {canUpdateOrganization ? (
              <button
                name="Edit email"
                type="button"
                className={classes.button}
                onClick={toggleEditButton}
              >
                <span>
                  {mailingListEmail}
                  <Pen className={classes.editIcon} />
                </span>
              </button>
            ) : (
              <span>{mailingListEmail}</span>
            )}
          </p>
        ) : (
          <button
            name="Add email"
            type="button"
            className={classes.addEmail}
            onClick={toggleEditButton}
          >
            Set mailing list for notifications
            <Add />
          </button>
        ))}

      {canUpdateOrganization &&
        editEmail && (
          <div className={classes.edit}>
            <FieldTextCompact
              label=""
              data-cy="email"
              value={mailingListEmail}
              invalid={isDirty && !isValidEmail}
              onValueChange={handleChange}
            />

            <button
              name="Save email"
              type="submit"
              className={classes.button}
              disabled={!isDirty || !isValidEmail}
              onClick={handleAddClick}
            >
              <InfoTooltip
                text="Save the email"
                textColor="#fff"
                backgroundColor="grey"
                position={{
                  place: 'above',
                  placeShift: 3,
                  adjustPlace: true,
                  anchor: 'center'
                }}
                delay={200}
              >
                <Save />
              </InfoTooltip>
            </button>

            <button
              name="Cancel"
              type="reset"
              className={classes.button}
              onClick={toggleEditButton}
            >
              <InfoTooltip
                text="Cancel and close"
                textColor="#fff"
                backgroundColor="grey"
                textAlign="center"
                position={{
                  place: 'above',
                  placeShift: 3,
                  adjustPlace: true,
                  anchor: 'center'
                }}
                delay={200}
              >
                <TimesCircle />
              </InfoTooltip>
            </button>
            <button
              name="Delete"
              type="submit"
              className={classes.button}
              onClick={handleDelete}
            >
              <InfoTooltip
                text="Remove the email"
                textColor="#fff"
                backgroundColor="grey"
                position={{
                  place: 'above',
                  placeShift: 3,
                  adjustPlace: true,
                  anchor: 'center'
                }}
                delay={200}
              >
                <Trash />
              </InfoTooltip>
            </button>
          </div>
        )}
    </div>
  );
};

MailingList.propTypes = {
  classes: PropTypes.object.isRequired,
  canUpdateOrganization: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default injectSheet(styles)(MailingList);
