export default theme => ({
  container: {
    display: 'flex',
    gap: '2rem',
    borderBottom: `solid 1px ${theme.grey6}`,
    marginBottom: '9px'
  },
  pageLink: {
    textDecoration: 'none',
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontWeight: '500',
    '&.active': {
      fontWeight: '600',
      '& > span': {
        opacity: 1
      },
      '& :hover ': {
        color: 'black'
      }
    }
  },
  linkName: {
    fontSize: '1rem',
    paddingBottom: '2px',
    color: theme.grey1
  },
  underLine: {
    height: '5px',
    width: '100%',
    borderRadius: '10px',
    backgroundColor: theme.teal1,
    opacity: 0
  }
});
