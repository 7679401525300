import React from 'react';
import PropType from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import injectSheet from 'react-jss';
import to from 'await-to-js';
import compose from 'lodash.flowright';
import qs from 'query-string';
import * as Sentry from '@sentry/react';
import { ROUTE_FORGOT } from 'constant/routes';
import { Box, Divider, OauthOptions, Logo } from 'components/ui';
import { SpanType, withSpanAsync } from 'tracing';
import { withRestClient } from 'wrappers';
import { redirectAfterLogin } from 'utils/loginRedirect';

import {
  FieldTextCompact,
  FieldPasswordCompact,
  Pushbutton
} from '@stratumn/atomic';

import styles from './login.style';

export class Login extends React.PureComponent {
  static propTypes = {
    location: PropType.object.isRequired,
    history: PropType.object.isRequired,
    classes: PropType.object.isRequired,
    client: PropType.object.isRequired
  };

  state = {
    email: '',
    password: '',
    showPassword: false
  };

  togglePassword = () =>
    this.setState({ showPassword: !this.state.showPassword });

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  isValid() {
    const { email, password } = this.state;
    return !!email && !!password;
  }

  login = async event =>
    withSpanAsync('login', SpanType.processing, async () => {
      if (event) event.preventDefault();

      const { location, history } = this.props;
      const { email, password } = this.state;

      const {
        responseType,
        clientId,
        redirectUri,
        codeChallenge,
        codeChallengeMethod
      } = qs.parse(location.search);

      const [err, rsp] = await to(
        this.props.client.login(email, password, {
          responseType,
          clientId,
          redirectUri,
          codeChallenge,
          codeChallengeMethod
        })
      );
      // client is responsible for error handling however errors in Promise
      // have to be handled hence the return;
      if (err) {
        Sentry.captureException(err);
        return;
      }

      const { redirectUri: r, authCode } = rsp;
      redirectAfterLogin(history, r, authCode);
    });

  render() {
    const { classes } = this.props;
    const { showPassword, email, password } = this.state;

    const emailInputProps = {
      label: 'Email',
      onValueChange: this.handleChange('email'),
      value: email
    };

    const passwordInputProps = {
      label: 'Password',
      onValueChange: this.handleChange('password'),
      value: password,
      onVisibilityChange: this.togglePassword,
      passwordIsVisible: showPassword
    };

    return (
      <>
        <Logo />
        <Box title="Log in">
          <form
            onSubmit={this.isValid() ? this.login : undefined}
            className={classes.form}
          >
            <div className={classes.formField}>
              <FieldTextCompact {...emailInputProps} />
            </div>
            <div className={classes.formField}>
              <FieldPasswordCompact {...passwordInputProps} />
            </div>
            <div className={classes.forgot}>
              <Link to={ROUTE_FORGOT}>Forgot your password?</Link>
            </div>
            <div className={classes.optionBox}>
              <Pushbutton disabled={!this.isValid()} type="submit" primary>
                Log in
              </Pushbutton>
            </div>
          </form>
          <Divider text="Or log in with" />
          <OauthOptions />
          <div className={classes.signUp}>
            <b>Don&apos;t have an account?</b> Currently Stratumn ID is only
            available to clients using our product range. If you would like to
            learn more, <a href="mailto:hello@stratumn.com">contact us</a>.
          </div>
        </Box>
      </>
    );
  }
}

export default compose(withRouter, withRestClient, injectSheet(styles))(Login);
