export default theme => ({
  text: {
    color: theme.grey3,
    paddingBottom: 20,
    fontSize: 13,
    lineHeight: 1.5
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 0px 15px 0px'
  },
  emailSent: {
    paddingLeft: 15,
    color: theme.grey2,
    fontSize: 11
  },
  backToSignIn: {
    fontSize: 11
  },
  checkbox: {
    width: 20,
    height: 16
  }
});
