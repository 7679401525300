export default theme => ({
  tagWrapper: {
    height: 24,
    border: `1px solid ${theme.grey5}`,
    padding: '4px 4px',
    fontSize: 12,
    borderRadius: 2
  },
  tagText: {
    color: theme.grey3
  }
});
