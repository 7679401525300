import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import styles from './stepper.style';

export const StepperContext = React.createContext({});

export const createStep = stepName => Component => props => (
  <StepperContext.Consumer>
    {({ activeStep, nextStep, finalStep, stepStates }) => {
      const { [stepName]: initialState, ...otherStepStates } = stepStates;
      if (activeStep !== stepName) return null;
      return (
        <Component
          nextStep={nextStep}
          finalStep={finalStep}
          initialState={initialState || {}}
          otherStepStates={otherStepStates}
          {...props}
        />
      );
    }}
  </StepperContext.Consumer>
);

export const withStepperContext = Component => props => (
  <StepperContext.Consumer>
    {({ prevStep, nextStep, prevSteps, activeStep }) => (
      <Component
        {...props}
        {...{ prevStep, nextStep, prevSteps, activeStep }}
      />
    )}
  </StepperContext.Consumer>
);

export class Stepper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: props.initialStep,
      stepStates: {},
      nextSteps: {},
      prevSteps: {
        [props.initialStep]: null
      }
    };
  }

  static propTypes = {
    children: PropType.node.isRequired,
    initialStep: PropType.string.isRequired,
    onFinish: PropType.func.isRequired,
    classes: PropType.object.isRequired,
    title: PropType.string.isRequired
  };

  prevStep = state =>
    this.setState({
      activeStep: this.state.prevSteps[this.state.activeStep],
      stepStates: { ...this.state.stepStates, [this.state.activeStep]: state }
    });

  nextStep = (stepName, state) =>
    this.setState({
      activeStep: stepName,
      stepStates: { ...this.state.stepStates, [this.state.activeStep]: state },
      nextSteps: { ...this.state.nextSteps, [this.state.activeStep]: stepName },
      prevSteps: { ...this.state.prevSteps, [stepName]: this.state.activeStep }
    });

  finalStep = state => {
    this.props.onFinish({
      ...this.state.stepStates,
      [this.state.activeStep]: state
    });
  };

  render() {
    const { activeStep, stepStates, prevSteps, nextSteps } = this.state;
    const { classes, initialStep, title, children } = this.props;

    return (
      <StepperContext.Provider
        value={{
          initialStep,
          activeStep,
          stepStates,
          prevSteps,
          nextSteps,
          prevStep: this.prevStep,
          nextStep: this.nextStep,
          finalStep: this.finalStep
        }}
      >
        <div className={classes.header}>{title}</div>
        <div className={classes.stepContainer}>{children}</div>
      </StepperContext.Provider>
    );
  }
}

export default injectSheet(styles)(Stepper);
