export default theme => ({
  passwordTip: {
    marginTop: 4,
    fontSize: 11,
    color: theme.grey2
  },
  password_rules: {
    fontSize: 11,
    color: theme.grey2,
    marginTop: 8
  },
  strengthBar: {
    display: 'flex',
    marginTop: 4
  },
  bar: {
    backgroundColor: theme.grey8,
    height: 5,
    flexBasis: '100%',
    '&:not(:last-child)': {
      marginRight: 2
    }
  },
  passwordField: {
    paddingTop: 5
  },
  confirmPasswordField: {
    paddingTop: 10
  },
  hiddenText: {
    fontSize: 11,
    opacity: 0
  },
  redText: {
    fontSize: 11,
    color: theme.warningRed
  },
  orangeText: {
    fontSize: 11,
    color: theme.cautionOrangeDark
  },
  greenText: {
    fontSize: 11,
    color: theme.validGreenDark
  },
  greenBG: {
    backgroundColor: theme.validGreen
  },
  orangeBG: {
    backgroundColor: theme.cautionOrange
  },
  redBG: {
    backgroundColor: theme.warningRed
  }
});
