import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';

import { StepperContext } from 'components/ui/stepper';
import PreviousStep from 'components/ui/stepper/previousStep';
import NextStep from 'components/ui/stepper/nextStep';
import Submit from 'components/ui/stepper/submit';

import styles from './step.style';

export const Step = ({
  classes,
  children,
  isValid,
  finalState,
  nextStepName,
  onSubmit
}) => (
  <StepperContext.Consumer>
    {({ initialStep, activeStep }) => (
      <div className={classes.container}>
        <div className={classes.stepName}>{activeStep}</div>
        <div className={classes.children}>{children}</div>
        <div className={classes.buttons}>
          {activeStep !== initialStep ? (
            <PreviousStep stepState={finalState} top />
          ) : (
            <div />
          )}
          {nextStepName ? (
            <NextStep
              isValid={isValid}
              stepState={finalState}
              nextStepName={nextStepName}
              right
              top
            />
          ) : (
            <Submit isValid={isValid} onSubmit={onSubmit} right top />
          )}
        </div>
      </div>
    )}
  </StepperContext.Consumer>
);

Step.propTypes = {
  classes: PropType.object.isRequired,
  children: PropType.node.isRequired,
  isValid: PropType.bool.isRequired,
  finalState: PropType.oneOfType([PropType.func, PropType.object]),
  nextStepName: PropType.string,
  onSubmit: PropType.func
};

Step.defaultProps = {
  nextStepName: null,
  finalState: {},
  onSubmit: () => null
};

export default injectSheet(styles)(Step);
