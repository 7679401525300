export default theme => ({
  root: {
    backgroundColor: theme.indigo3,
    color: 'white',
    padding: '8px 14px',
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontSize: 11,
    fontWeight: 700,
    borderRadius: 2,
    width: 'fit-content',
    '&:disabled': {
      backgroundColor: theme.indigoPastel1,
      cursor: 'default'
    }
  },
  rootDynamic: props => ({
    marginTop: !props.top && theme.inputSpacing,
    marginRight: !props.right && theme.inputSpacing
  })
});
