import React from 'react';
import PropType from 'prop-types';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { withGqlClient } from 'wrappers';

import RemoveCollaboratorModal from './removeCollaboratorModal';

const query = graphql`
  query removeCollaboratorModalRootQuery(
    $collaboratorRowId: BigInt!
    $teamFilter: TeamFilter
  ) {
    userByRowId(rowId: $collaboratorRowId) {
      ...removeCollaboratorModal_collaborator
        @arguments(teamFilter: $teamFilter)
    }
  }
`;

export const Renderer = props => {
  const {
    environment,
    collaboratorRowId,
    organizationId,
    organizationRowId,
    onClose
  } = props;

  const variables = {};

  variables.collaboratorRowId = collaboratorRowId;
  variables.teamFilter = { organizationId: { equalTo: organizationRowId } };

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={variables}
      render={({ err, props: data }) => {
        if (err) return 'error';
        if (!data) return null;
        const { userByRowId } = data;
        return (
          <RemoveCollaboratorModal
            organizationId={organizationId}
            organizationRowId={organizationRowId}
            collaborator={userByRowId}
            onClose={onClose}
          />
        );
      }}
    />
  );
};

Renderer.propTypes = {
  environment: PropType.object.isRequired,
  organizationId: PropType.string.isRequired,
  organizationRowId: PropType.string.isRequired,
  collaboratorRowId: PropType.string.isRequired,
  onClose: PropType.func.isRequired
};

export default withGqlClient(Renderer);
