import React from 'react';
import PropType from 'prop-types';
import to from 'await-to-js';
import { Navbar } from '@stratumn/atomic';
import compose from 'lodash.flowright';
import { withRouter } from 'react-router-dom';

import { ROUTE_LOGIN, ROUTE_HOME } from 'constant/routes';
import { withRestClient } from 'wrappers/restClient';
import { TRACE_URL, ANALYTICS_URL } from 'constant/api';
import { env } from 'constant/env';

export const Header = ({ history, user, client }) => {
  const signOut = async () => {
    const [err] = await to(client.logout());
    // generic error handling is done by the client
    if (err) return;
    history.push(ROUTE_LOGIN);
  };

  const currentPage = {
    name: 'Account',
    url: ROUTE_HOME
  };

  const links = [
    {
      name: 'Trace',
      url: TRACE_URL
    },
    {
      name: 'Analytics',
      url: ANALYTICS_URL
    }
  ];

  const menuLinks = [
    {
      link: currentPage,
      iconName: 'Profile'
    }
  ];

  return (
    <Navbar
      currentPage={currentPage}
      signOut={() => signOut()}
      links={links}
      menuLinks={menuLinks}
      userAvatarPath={user?.avatar}
      environmentName={env.REACT_APP_ENVIRONMENT}
    />
  );
};

Header.propTypes = {
  history: PropType.object.isRequired,
  user: PropType.object.isRequired,
  client: PropType.object.isRequired
};

export default compose(withRouter, withRestClient)(Header);
