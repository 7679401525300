import React from 'react';
import * as Sentry from '@sentry/react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import compose from 'lodash.flowright';
import {
  Modal,
  ModalContent,
  Prose,
  Pushbutton,
  Pushtext
} from '@stratumn/atomic';
import { withRouter } from 'react-router-dom';
import to from 'await-to-js';

import { ChangePassword, GenerateKey } from 'components/ui';
import { createUserSigningKey } from 'mutations';
import { SpanType, withSpanAsync } from 'tracing';
import { withGqlClient, withUser } from 'wrappers';

import styles from './regenerateKeyModal.style';

const INITIAL_STATE = {
  password: null,
  submitted: false,
  signingKey: null
};

export class RegenerateKeyModal extends React.PureComponent {
  static propTypes = {
    classes: PropType.object.isRequired,
    environment: PropType.object.isRequired,
    toggleModal: PropType.func.isRequired
  };

  state = INITIAL_STATE;

  handleStateChange = state => val => this.setState({ [state]: val });

  handlePasswordChange = this.handleStateChange('password');

  handleSigningKeyChange = this.handleStateChange('signingKey');

  submitUserKeys = password => () =>
    withSpanAsync('regenerateUserKey', SpanType.processing, async () => {
      const {
        environment,
        user: { account, rowId: userRowId },
        toggleModal
      } = this.props;
      const { signingKey } = this.state;

      this.setState({ submitted: true });

      // Add keys
      const { publicKey, privateKey } = signingKey.export(password);

      const [err] = await to(
        createUserSigningKey(
          environment,
          account.id,
          userRowId,
          publicKey,
          privateKey,
          !!password
        )
      );

      if (err) {
        Sentry.captureException(err);
        this.setState({ submitted: false });
      } else {
        toggleModal();
      }

      return {};
    });

  render() {
    const { classes, toggleModal } = this.props;
    const { submitted, password, signingKey } = this.state;

    return (
      <Modal title="Regenerating Signing Key" handleCollapse={toggleModal}>
        <ModalContent>
          <GenerateKey
            signingKey={signingKey}
            onSigningKeyGeneration={this.handleSigningKeyChange}
          />
          <div className={classes.passwordPrompt}>
            <Prose
              light
              text="**Please secure your keys with a password.** This password can be the same as your log in password."
            />
          </div>
          <ChangePassword
            labelPassword="Key password"
            labelConfirmPassword="Confirm key password"
            onChange={this.handlePasswordChange}
          />
        </ModalContent>
        <div className={classes.modalActionsRoot}>
          <div className={classes.modalActions}>
            <Pushtext
              mute
              disabled={submitted || !signingKey}
              secondary
              onClick={this.submitUserKeys()}
            >
              Skip this step
            </Pushtext>

            <Pushbutton
              disabled={submitted || !signingKey || !password}
              primary
              onClick={this.submitUserKeys(password)}
            >
              secure keys
            </Pushbutton>
          </div>
        </div>
      </Modal>
    );
  }
}

export default compose(
  withUser,
  withRouter,
  withGqlClient,
  injectSheet(styles)
)(RegenerateKeyModal);
