/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateBotInput = {|
  clientMutationId?: ?string,
  organizationId?: ?any,
  name?: ?string,
  description?: ?string,
  avatar?: ?string,
  publicKey?: ?string,
|};
export type addBotMutationVariables = {|
  input: CreateBotInput
|};
export type addBotMutationResponse = {|
  +createBot: ?{|
    +bot: ?{|
      +id: string,
      +rowId: any,
      +name: string,
      +avatar: ?string,
      +description: ?string,
      +teams: {|
        +totalCount: number
      |},
    |}
  |}
|};
export type addBotMutation = {|
  variables: addBotMutationVariables,
  response: addBotMutationResponse,
|};
*/


/*
mutation addBotMutation(
  $input: CreateBotInput!
) {
  createBot(input: $input) {
    bot {
      id
      rowId
      name
      avatar
      description
      teams {
        totalCount
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateBotInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateBotPayload",
    "kind": "LinkedField",
    "name": "createBot",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Bot",
        "kind": "LinkedField",
        "name": "bot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamsConnection",
            "kind": "LinkedField",
            "name": "teams",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addBotMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addBotMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "addBotMutation",
    "operationKind": "mutation",
    "text": "mutation addBotMutation(\n  $input: CreateBotInput!\n) {\n  createBot(input: $input) {\n    bot {\n      id\n      rowId\n      name\n      avatar\n      description\n      teams {\n        totalCount\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b5acd844e9b28a38d1e54763e5807790';

module.exports = node;
