import { commitMutation } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

import { COLLABORATOR_KEY, MEMBER_KEY, TEAM_MEMBER_KEY } from 'constant/types';

const mutation = graphql`
  mutation removeOrganizationMemberMutation(
    $input: RemoveOrganizationMemberInput!
  ) {
    removeOrganizationMember(input: $input) {
      boolean
    }
  }
`;

export default (
  environment,
  accountId,
  connectionKey,
  organizationId,
  organizationRowId,
  userId,
  userRowId,
  removeFromTeams
) =>
  withSpanAsync('removeOrganizationMember', SpanType.mutation, async () => {
    const variables = {
      input: {
        userRowId,
        organizationRowId,
        removeFromTeams
      }
    };

    /*
    We probably want the UI to know if the user removed themself from an account. 
  */
    let removedSelf = false;

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);

          resolve(removedSelf);
        },
        onError: err => {
          console.error(err);
          reject(err);
        },
        updater: store => {
          const account = store.get(accountId); // update members
          const organization = store.get(organizationId); // update collaborators

          /**
           * Cancel Invitation, updates UI
           */
          if (connectionKey === MEMBER_KEY) {
            const oldMembers = account.getLinkedRecord('members');
            const oldTotalCount = oldMembers.getValue('totalCount');

            // Since, we're using filters, we need to use the connectionHandler from relay.
            const membersConnection = ConnectionHandler.getConnection(
              account,
              MEMBER_KEY
            );

            const edges = membersConnection.getLinkedRecords('edges');

            // Creating a new array without the member whose invitation has been cancelled
            const newMembers = edges.filter(
              edge =>
                edge.getLinkedRecord('node').getValue('userId') !== userRowId
            );

            // Replacing the old members with the new members array
            membersConnection.setLinkedRecords(newMembers, 'edges');

            membersConnection.setValue(oldTotalCount - 1, 'totalCount');
            oldMembers.setValue(oldTotalCount - 1, 'totalCount');
          } else if (connectionKey === COLLABORATOR_KEY) {
            const collaborators = organization.getLinkedRecord('collaborators');
            const oldTotalCount = collaborators.getValue('totalCount');

            /* 
          We don't need to pass in the filters to getConnection.
          Since, we're using filters, we now need to fetch the 'edges', not the 'nodes'
          */
            const collaboratorsConnection = ConnectionHandler.getConnection(
              organization,
              COLLABORATOR_KEY
            );

            ConnectionHandler.deleteNode(collaboratorsConnection, userId);

            // Update the collaborators count that are being filtered (e.g: to make sure the DirectoryRow component shows a bottom border on the last item of the list)
            collaboratorsConnection.setValue(oldTotalCount - 1, 'totalCount');
            // Update the collaborators count regardless of being filtered. (e.g: for the tally UI component)
            collaborators.setValue(oldTotalCount - 1, 'totalCount');
          } else if (connectionKey === TEAM_MEMBER_KEY) {
            // In this case, account represents the team
            // Get the field account of a team
            const teamAccount = account.getLinkedRecord('account');
            console.log(teamAccount.getValue('rowId'));

            // Since, we're using filters, we need to use the connectionHandler from relay.
            const membersConnection = ConnectionHandler.getConnection(
              teamAccount,
              connectionKey
            );

            const edges = membersConnection.getLinkedRecords('edges');

            // Creating a new array without the member whose invitation has been cancelled
            const newMembers = edges.filter(
              edge =>
                edge.getLinkedRecord('node').getValue('userId') !== userRowId
            );
            // Replacing the old members with the new members array
            membersConnection.setLinkedRecords(newMembers, 'edges');

            // Update the number of members
            membersConnection.setValue(
              membersConnection.getValue('totalCount') - 1,
              'totalCount'
            );

            // Update the number of organization's collaborators on the sidebar
            const organizationCollaborators = organization.getLinkedRecord(
              'collaborators'
            );
            organizationCollaborators.setValue(
              organizationCollaborators.getValue('totalCount') - 1,
              'totalCount'
            );
          } else {
            const members = organization
              .getLinkedRecord('account')
              .getLinkedRecord('members');

            members.setValue(members.getValue('totalCount') - 1, 'totalCount');
            // If the user removes them self we will set removedSelf to true to notify.
            // the caller.
            const loggedInUserRowId = store
              .getRoot()
              .getLinkedRecord('me')
              .getValue('rowId');

            if (userRowId === loggedInUserRowId) removedSelf = true;
          }
        }
      });
    });
  });
