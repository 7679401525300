import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';

import styles from './textButton.style';

export const TextButton = ({
  classes,
  onClick,
  title,
  description,
  children,
  disabled
}) => (
  <div
    className={classNames(classes.root, classes.rootDynamic, {
      [classes.disabled]: !!disabled
    })}
    onClick={onClick}
  >
    <div className={classes.title}>{title}</div>
    {description && <div className={classes.description}>{description}</div>}
    {children}
  </div>
);

TextButton.propTypes = {
  classes: PropType.object.isRequired,
  title: PropType.string.isRequired,
  onClick: PropType.func,
  description: PropType.string,
  children: PropType.node,
  disabled: PropType.bool
};

TextButton.defaultProps = {
  onClick: undefined,
  description: null,
  children: null,
  disabled: false
};

export default injectSheet(styles)(TextButton);
