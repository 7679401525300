import { stringify } from 'querystring';
import { env } from './env';

export const ROOT_API_URL = env.REACT_APP_ROOT_API_URL;
export const MEDIA_API_URL = env.REACT_APP_MEDIA_API_URL;
export const TRACE_URL = env.REACT_APP_TRACE_URL;
export const ANALYTICS_URL = env.REACT_APP_ANALYTICS_URL;
export const ACCOUNT_URL = env.REACT_APP_ACCOUNT_URL;

const ONE_ACCOUNT_AXA_CLIENT_ID = env.REACT_APP_ONE_ACCOUNT_AXA_CLIENT_ID;
const ONE_ACCOUNT_AXA_REDIRECT_URI = env.REACT_APP_ONE_ACCOUNT_AXA_REDIRECT_URI;
const ONE_ACCOUNT_AXA_OAUTH_URL = env.REACT_APP_ONE_ACCOUNT_AXA_OAUTH_URL;
export const ONE_ACCOUNT_AXA_URL = `${ONE_ACCOUNT_AXA_OAUTH_URL}?${stringify({
  response_type: 'code',
  client_id: ONE_ACCOUNT_AXA_CLIENT_ID,
  redirect_uri: ONE_ACCOUNT_AXA_REDIRECT_URI,
  scope: 'openid email profile'
})}`;

const KEYCLOAK_CLIENT_ID = env.REACT_APP_KEYCLOAK_CLIENT_ID;
const KEYCLOAK_REDIRECT_URI = env.REACT_APP_KEYCLOAK_REDIRECT_URI;
const KEYCLOAK_OAUTH_URL = env.REACT_APP_KEYCLOAK_OAUTH_URL;
export const KEYCLOAK_URL = `${KEYCLOAK_OAUTH_URL}?${stringify({
  response_type: 'code',
  client_id: KEYCLOAK_CLIENT_ID,
  redirect_uri: KEYCLOAK_REDIRECT_URI,
  scope: 'openid email profile'
})}`;

const MICROSOFT_SIA_CLIENT_ID = env.REACT_APP_MICROSOFT_SIA_CLIENT_ID;
const MICROSOFT_SIA_REDIRECT_URI = env.REACT_APP_MICROSOFT_SIA_REDIRECT_URI;
const MICROSOFT_SIA_OAUTH_URL = env.REACT_APP_MICROSOFT_SIA_OAUTH_URL;
export const MICROSOFT_SIA_URL = `${MICROSOFT_SIA_OAUTH_URL}?${stringify({
  response_type: 'code',
  client_id: MICROSOFT_SIA_CLIENT_ID,
  redirect_uri: MICROSOFT_SIA_REDIRECT_URI,
  scope: 'openid email profile'
})}`;
