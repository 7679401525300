import React from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import compose from 'lodash.flowright';
import {
  Pushbutton,
  FieldTextCompact,
  AvatarButton,
  IsolateMain
} from '@stratumn/atomic';
import { withRouter } from 'react-router-dom';

import { updateUser } from 'mutations';
import { ROUTE_MY_PROFILE } from 'constant/routes';
import { Box, SignupStepper, CropDialog, Logo } from 'components/ui';
import { SpanType, withSpanAsync } from 'tracing';
import { withGqlClient, withRestClient, withUser } from 'wrappers';

import styles from './editProfileSignup.style';

export class EditProfileSignup extends React.PureComponent {
  static propTypes = {
    history: PropType.object.isRequired,
    user: PropType.object.isRequired,
    classes: PropType.object.isRequired,
    environment: PropType.object.isRequired,
    client: PropType.object.isRequired
  };

  state = {
    phoneNumber: '',
    imageSrc: null,
    croppedImageUrl: null,
    uploadFile: null,
    submitted: false
  };

  fileInputRef = React.createRef();

  isValid() {
    const { submitted, phoneNumber, uploadFile } = this.state;

    return !submitted && (phoneNumber || uploadFile);
  }

  onSubmit = () =>
    withSpanAsync('updateUserInSignup', SpanType.processing, async () => {
      const { uploadFile, phoneNumber } = this.state;
      const { client, user, environment, history } = this.props;

      this.setState({ submitted: true });

      try {
        let avatarUrl;
        if (uploadFile) {
          const { digest } = await client.media.uploadFile(uploadFile, {
            disableEncryption: true
          });
          avatarUrl = await client.media.buildDownloadURL(digest);
        }

        await updateUser(
          environment,
          user.rowId,
          null,
          null,
          phoneNumber,
          avatarUrl
        );
        history.push(ROUTE_MY_PROFILE);
      } catch (e) {
        this.setState({ submitted: false });
      }
    });

  onSkip = () => {
    this.props.history.push(ROUTE_MY_PROFILE);
  };

  onPhoneChange = event => {
    this.setState({ phoneNumber: event.target.value });
  };

  onAvatarClick = () => this.fileInputRef.current.click();

  onFileChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      const targetFile = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ imageSrc: reader.result })
      );
      reader.readAsDataURL(targetFile);
    }
  };

  onCropFinish = async ({ croppedImageUrl, croppedFile }) => {
    this.setState({ imageSrc: null, uploadFile: croppedFile, croppedImageUrl });
    this.fileInputRef.current.value = '';
  };

  onCropCancel = () => {
    this.setState({ imageSrc: null });
    this.fileInputRef.current.value = '';
  };

  render() {
    const { classes } = this.props;
    const { phoneNumber, imageSrc, croppedImageUrl } = this.state;

    return (
      <IsolateMain>
        <SignupStepper step={2} />
        <Logo />
        <Box
          secondaryTitle={
            <div className={classes.title}>
              Personalize your account with your picture and phone number to
              make team collaboration easier.
            </div>
          }
        >
          <div className={classes.avatarTitle}>Add profile picture</div>
          <AvatarButton
            src={croppedImageUrl}
            onClick={this.onAvatarClick}
            size={56}
          />
          <input
            type="file"
            ref={this.fileInputRef}
            onChange={this.onFileChange}
            className={classes.fileInput}
          />
          <CropDialog
            imageSrc={imageSrc}
            onCancel={this.onCropCancel}
            onFinish={this.onCropFinish}
            actionLabel="crop"
          />
          <div className={classes.fieldExplainer}>
            Your avatar will be displayed next to signed attestations. Adding
            your photo personalizes the process and makes you easier to identify
            to team members.
          </div>
          <FieldTextCompact
            label="Phone"
            value={phoneNumber}
            onValueChange={this.onPhoneChange}
          />
          <div className={classes.fieldExplainer}>
            By adding your phone number you can easily make youself available to
            other members of your workflows.
          </div>
          <div className={classes.buttons}>
            <Pushbutton
              onClick={this.onSubmit}
              disabled={!this.isValid()}
              primary
            >
              Save
            </Pushbutton>
            <Pushbutton onClick={this.onSkip}>Skip This Step</Pushbutton>
          </div>
        </Box>
      </IsolateMain>
    );
  }
}

export default compose(
  withUser,
  withRouter,
  withRestClient,
  withGqlClient,
  injectSheet(styles)
)(EditProfileSignup);
