import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  Modal,
  ModalActions,
  ModalContent,
  Pushbutton,
  FieldTextAreaCompact
} from '@stratumn/atomic';
import * as Sentry from '@sentry/react';
import { withGqlClient } from 'wrappers';

import { addBotKey } from 'mutations';
import to from 'await-to-js';
import { SpanType, withSpanAsync } from 'tracing';

export const AddBotKeyModal = props => {
  const [key, setKey] = useState('');
  const [submitted, setSubmit] = useState(false);

  const { environment, bot, toggleModal } = props;

  const updateKey = () => {
    withSpanAsync(`updateBotKey`, SpanType.processing, async () => {
      setSubmit(true);

      const [err] = await to(addBotKey(environment, bot.rowId, key));

      if (err) {
        Sentry.captureException(err);
        setSubmit(false);
      } else {
        toggleModal();
      }

      return {};
    });
  };

  return (
    <Modal
      title="Add Bot Public Signing Key"
      handleCollapse={toggleModal}
      closeButtonLabel="Cancel"
    >
      <ModalContent>
        <FieldTextAreaCompact
          label="Bot public signing Key"
          value={key}
          onValueChange={e => setKey(e.target.value)}
          rows={4}
          noResize
        />
      </ModalContent>
      <ModalActions>
        <Pushbutton onClick={toggleModal}>cancel</Pushbutton>
        <Pushbutton primary onClick={updateKey} disabled={!key || submitted}>
          Add key
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
};

AddBotKeyModal.propTypes = {
  environment: PropType.object.isRequired,
  bot: PropType.object.isRequired,
  toggleModal: PropType.func.isRequired
};

export default withGqlClient(AddBotKeyModal);
