import React from 'react';
import PropType from 'prop-types';
import { Todo, TodoItem } from '@stratumn/atomic';

const SignupStepper = ({ step }) => (
  <Todo>
    <TodoItem
      label="Step 1"
      title="Create ID"
      done={step > 0}
      doing={step === 0}
    />
    <TodoItem
      label="Step 2"
      title="Generate keys"
      done={step > 1}
      doing={step === 1}
    />
    <TodoItem
      label="Step 3"
      title="Personalize account"
      done={step > 2}
      doing={step === 2}
    />
  </Todo>
);

SignupStepper.propTypes = {
  step: PropType.number.isRequired
};

export default SignupStepper;
