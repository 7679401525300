import React, { useState } from 'react';
import PropType from 'prop-types';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom';
import {
  Modal,
  ModalActions,
  ModalContent,
  Prose,
  Pushbutton
} from '@stratumn/atomic';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';
import * as Sentry from '@sentry/react';
import { withGqlClient } from 'wrappers';

import { removeOrgMember } from 'mutations';
import to from 'await-to-js';
import { SpanType, withSpanAsync } from 'tracing';

import { ROUTE_MEMBERS } from 'constant/routes';

import styles from './removeOrgMemberModal.style';

export function RemoveOrgMemberModal(props) {
  const [submitted, setSubmit] = useState(false);

  const {
    classes,
    environment,
    member,
    member: { organizations: { nodes: [organization] }, teams },
    toggleModal,
    history
  } = props;

  const removeMemberOrg = () => {
    withSpanAsync(`removeOrgMember`, SpanType.processing, async () => {
      setSubmit(true);

      const [err, self] = await to(
        removeOrgMember(
          environment,
          null,
          null,
          organization.id,
          organization.rowId,
          member.id,
          member.rowId,
          true // removes the member from all his teams
        )
      );

      if (err) {
        Sentry.captureException(err);
        setSubmit(false);
      } else if (self) {
        window.location.reload();
      } else {
        history.push(ROUTE_MEMBERS);
      }

      return {};
    });
  };

  const modalDescriptionText = `This action will remove **${
    member.name ? member.name : member.email
  }** from **${organization.name}**${
    teams.totalCount > 0 ? ' and the following teams' : ''
  }`;

  return (
    <Modal
      title="Remove from organization"
      handleCollapse={toggleModal}
      closeButtonLabel="Cancel"
    >
      <ModalContent>
        <Prose light text={modalDescriptionText} />
        <ul className={classes.teamList}>
          {member.teams.nodes.map(n => (
            <li className={classes.teamListItems} key={n.id}>
              {n.name}
            </li>
          ))}
        </ul>
      </ModalContent>
      <ModalActions>
        <Pushbutton onClick={toggleModal}>cancel</Pushbutton>
        <Pushbutton
          warning
          onClick={() => removeMemberOrg()}
          disabled={submitted}
        >
          Remove from organization
        </Pushbutton>
      </ModalActions>
    </Modal>
  );
}

RemoveOrgMemberModal.propTypes = {
  classes: PropType.object.isRequired,
  environment: PropType.object.isRequired,
  history: PropType.object.isRequired,
  member: PropType.object.isRequired,
  toggleModal: PropType.func.isRequired
};

export default createFragmentContainer(
  compose(withRouter, withGqlClient, injectSheet(styles))(RemoveOrgMemberModal),
  {
    member: graphql`
      fragment removeOrgMemberModal_member on User
        @argumentDefinitions(
          organizationFilter: { type: "OrganizationFilter", defaultValue: {} }
          teamFilter: { type: "TeamFilter", defaultValue: {} }
        ) {
        id #Needed to update the store
        rowId
        name
        email
        organizations(filter: $organizationFilter) {
          nodes {
            id
            rowId
            name
          }
        }
        teams(filter: $teamFilter) {
          totalCount
          nodes {
            id
            name
          }
        }
      }
    `
  }
);
