/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type removeOrgMemberModal_member$ref: FragmentReference;
declare export opaque type removeOrgMemberModal_member$fragmentType: removeOrgMemberModal_member$ref;
export type removeOrgMemberModal_member = {|
  +id: string,
  +rowId: any,
  +name: string,
  +email: string,
  +organizations: {|
    +nodes: $ReadOnlyArray<?{|
      +id: string,
      +rowId: any,
      +name: string,
    |}>
  |},
  +teams: {|
    +totalCount: number,
    +nodes: $ReadOnlyArray<?{|
      +id: string,
      +name: string,
    |}>,
  |},
  +$refType: removeOrgMemberModal_member$ref,
|};
export type removeOrgMemberModal_member$data = removeOrgMemberModal_member;
export type removeOrgMemberModal_member$key = {
  +$data?: removeOrgMemberModal_member$data,
  +$fragmentRefs: removeOrgMemberModal_member$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "organizationFilter",
      "type": "OrganizationFilter"
    },
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "teamFilter",
      "type": "TeamFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "removeOrgMemberModal_member",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "organizationFilter"
        }
      ],
      "concreteType": "OrganizationsConnection",
      "kind": "LinkedField",
      "name": "organizations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "teamFilter"
        }
      ],
      "concreteType": "TeamsConnection",
      "kind": "LinkedField",
      "name": "teams",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Team",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a571957e483d9263de73a1bd932ef4da';

module.exports = node;
