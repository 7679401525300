import React from 'react';
import PropType from 'prop-types';
import qs from 'query-string';
import injectSheet from 'react-jss';
import compose from 'lodash.flowright';
import to from 'await-to-js';
import { withRouter } from 'react-router-dom';
import { SpanType, withSpanAsync } from 'tracing';
import { withRestClient } from 'wrappers';
import { ROUTE_LOGIN } from 'constant/routes';
import { redirectAfterLogin } from 'utils/loginRedirect';
import * as Sentry from '@sentry/react';
import styles from './oAuth.style';

export class OAuth extends React.PureComponent {
  static propTypes = {
    location: PropType.object.isRequired,
    history: PropType.object.isRequired,
    client: PropType.object.isRequired,
    classes: PropType.object.isRequired
  };

  componentDidMount = () => {
    const { state, code } = qs.parse(this.props.location.search);
    this.getTokenFromCode(state, code);
  };

  getTokenFromCode = async (state, code) =>
    withSpanAsync('getTokenFromCode', SpanType.processing, async () => {
      const { client, history } = this.props;
      const parsedState = JSON.parse(Buffer.from(state, 'base64').toString());
      const oauthPayload = parsedState;
      const [err, rsp] = await to(
        client.oAuth(parsedState, code, oauthPayload)
      );
      if (err) {
        Sentry.captureException(err);
        history.push(ROUTE_LOGIN);
        return;
      }

      const { redirectUri, authCode } = rsp;
      redirectAfterLogin(history, redirectUri, authCode);
    });

  render() {
    return <div className={this.props.classes.container}>Logging in...</div>;
  }
}

export default compose(withRouter, withRestClient, injectSheet(styles))(OAuth);
