export default theme => ({
  GDPRwrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    overflowY: 'auto'
  },
  container: {
    maxWidth: 800,
    margin: '0 auto',
    padding: '70px 20px',
    fontSize: 12,
    color: theme.indigo1,
    '& section': {
      margin: '40px 0'
    },
    '& h1': {
      fontSize: '26px',
      fontWeight: '600'
    },
    '& h3': {
      fontSize: '14px',
      fontWeight: '600',
      marginBottom: 16
    },
    '& span': {
      fontSize: '12px'
    },
    '& li': {
      position: 'relative',
      paddingLeft: 16,
      display: 'flex',
      margin: '14px 0px',
      '& span::before': {
        position: 'absolute',
        fontSize: '10px',
        content: '"\\25ba"',
        color: theme.indigo1,
        marginLeft: -16,
        top: 2
      },
      '& div': {
        marginLeft: 6
      },
      '& li span::before': {
        color: theme.indigo1,
        content: '"\\25B7"'
      },
      '& a ': {
        color: theme.indigo3,
        fontWeight: 600,
        textDecoration: 'none',
        marginLeft: '6px'
      }
    }
  },
  grid: {
    display: 'grid',
    border: `1px solid ${theme.indigo1}`,
    '& div': {
      border: `1px solid ${theme.indigo1}`,
      padding: '6px'
    }
  },
  howDataCollectedTableGrid: {
    gridTemplateRows: 'auto auto auto auto auto',
    gridTemplateColumns: 'auto auto auto',
    '& div:nth-child(-n+3)': {
      fontSize: 16,
      fontWeight: 600
    }
  },
  legalBasisGrid: {
    gridTemplateRows: 'auto auto auto auto auto',
    gridTemplateColumns: 'auto auto',
    '& div:nth-child(-n+2)': {
      fontSize: 16,
      fontWeight: 600
    }
  }
});
