import React from 'react';
import PropType from 'prop-types';
import { sig } from '@stratumn/js-crypto';
import {
  LoaderUnsure,
  UiconKeyHorizontal,
  CollectionItem,
  UiconCircleTick
} from '@stratumn/atomic';

export class GenerateKey extends React.Component {
  static propTypes = {
    onSigningKeyGeneration: PropType.func.isRequired,
    signingKey: PropType.object
  };

  static defaultProps = {
    signingKey: null
  };

  // Used to cancel key generation function on unmounting.
  immediateKeyGeneration;

  componentDidMount() {
    this.immediateKeyGeneration = setImmediate(() => {
      this.generateSigningKey();
    });
  }

  componentWillUnmount() {
    if (this.immediateKeyGeneration) {
      clearImmediate(this.immediateKeyGeneration);
    }
  }

  generateSigningKey = () => {
    const { onSigningKeyGeneration } = this.props;

    const signingKey = new sig.SigningKeyPair({ algo: 'ED25519' });
    onSigningKeyGeneration(signingKey);
  };

  render() {
    const { signingKey } = this.props;

    const action = !signingKey ? (
      <LoaderUnsure />
    ) : (
      <UiconCircleTick size={27} />
    );

    return (
      <>
        <CollectionItem
          label=""
          value={
            !signingKey
              ? 'Generating Signing Key...'
              : `Signing Key successfully created`
          }
          prefix={<UiconKeyHorizontal size={20} />}
          action={action}
          idle
          borderless
        />
      </>
    );
  }
}

export default GenerateKey;
