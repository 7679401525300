export default theme => ({
  profileHeaderRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 21
  },
  pushTextCustom: {
    color: theme.grey1,
    fontSize: 14,
    fontWeight: 'normal',
    height: 29,
    lineHeight: '29px',
    marginBottom: 10
  },
  collectionItemFirstChild: {
    borderWidth: '1px 0 0 0',
    borderStyle: 'solid',
    borderColor: theme.grey6
  },
  signingKeyWrapper: {
    marginTop: 43,
    borderWidth: '0 0 1px 0',
    borderStyle: 'solid',
    borderColor: theme.grey6
  },
  signingKeyHeader: {
    display: 'flex'
  },
  signingKeyTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1
  },
  signingKeyTitle: {
    paddingRight: 6,
    fontSize: 16,
    fontWeight: 600
  },
  buttonClipboard: {
    paddingRight: 20
  },
  bold: {
    fontWeight: 700
  }
});
