import React from 'react';
import PropType from 'prop-types';
import { QueryRenderer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import compose from 'lodash.flowright';

import { withGqlClient, withUser } from 'wrappers';

import RemoveTeamMemberModal from './removeTeamMemberModal';

const query = graphql`
  query removeTeamMemberModalQuery(
    $userRowId: BigInt!
    $teamFilter: TeamFilter
  ) {
    userByRowId(rowId: $userRowId) {
      ...removeTeamMemberModal_member @arguments(teamFilter: $teamFilter)
    }
  }
`;

export const Renderer = props => {
  const {
    environment,
    selectedOrganization,
    memberRowId,
    teamRowId,
    toggleModal
  } = props;
  const variables = {};

  // There are some users that have unrealistic data so we need to account
  // for them to not crash the UI.
  if (!selectedOrganization || !selectedOrganization.rowId) {
    return null;
  }

  variables.userRowId = memberRowId;
  variables.teamFilter = { rowId: { equalTo: teamRowId } };

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={variables}
      render={({ error, props: data }) => {
        if (error) return 'error';
        if (!data) return null;
        const { userByRowId } = data;
        return (
          <RemoveTeamMemberModal
            member={userByRowId}
            toggleModal={toggleModal}
          />
        );
      }}
    />
  );
};

Renderer.propTypes = {
  environment: PropType.object.isRequired,
  selectedOrganization: PropType.object,
  memberRowId: PropType.string.isRequired,
  teamRowId: PropType.string.isRequired,
  toggleModal: PropType.func.isRequired
};

Renderer.defaultProps = {
  selectedOrganization: null
};

export default compose(withUser, withGqlClient)(Renderer);
