export default theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  checkBox: {
    height: 18,
    width: 18,
    cursor: 'pointer',
    border: `1px solid ${theme.greyBorder2}`,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.greyBackground1,
    marginRight: 10
  },
  checked: {
    borderColor: theme.indigoPastel1
  },
  check: {
    height: 14,
    width: 14,
    backgroundColor: theme.indigo3,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  checkMark: {
    height: 10,
    width: 10
  },
  text: {
    fontSize: 11,
    color: theme.grey2
  }
});
