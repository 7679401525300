/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type botsHeader_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type bots_organization$ref: FragmentReference;
declare export opaque type bots_organization$fragmentType: bots_organization$ref;
export type bots_organization = {|
  +rowId: any,
  +account: ?{|
    +canUpdate: ?boolean
  |},
  +bots: {|
    +totalCount: number,
    +edges: $ReadOnlyArray<{|
      +cursor: ?any,
      +node: ?{|
        +id: string,
        +rowId: any,
        +name: string,
        +avatar: ?string,
        +description: ?string,
        +teams: {|
          +totalCount: number
        |},
      |},
    |}>,
    +pageInfo: {|
      +hasNextPage: boolean,
      +endCursor: ?any,
    |},
  |},
  +$fragmentRefs: botsHeader_organization$ref,
  +$refType: bots_organization$ref,
|};
export type bots_organization$data = bots_organization;
export type bots_organization$key = {
  +$data?: bots_organization$data,
  +$fragmentRefs: bots_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "Cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "botFilter",
      "type": "BotFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "bots"
        ]
      }
    ]
  },
  "name": "bots_organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canUpdate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "bots",
      "args": null,
      "concreteType": "BotsConnection",
      "kind": "LinkedField",
      "name": "__Bots_bots_connection",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BotsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Bot",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "avatar",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TeamsConnection",
                  "kind": "LinkedField",
                  "name": "teams",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "botsHeader_organization"
    }
  ],
  "type": "Organization"
};
})();
// prettier-ignore
(node/*: any*/).hash = '1a47ac856e48a5c028319172fa718497';

module.exports = node;
