/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type teamRow_team$ref = any;
export type CreateTeamInput = {|
  clientMutationId?: ?string,
  team: TeamInput,
|};
export type TeamInput = {|
  name: string,
  description: string,
  avatar?: ?string,
  organizationId: any,
  createdAt?: ?any,
  updatedAt?: ?any,
  email?: ?string,
|};
export type addTeamMutationVariables = {|
  input: CreateTeamInput
|};
export type addTeamMutationResponse = {|
  +createTeam: ?{|
    +team: ?{|
      +$fragmentRefs: teamRow_team$ref
    |}
  |}
|};
export type addTeamMutation = {|
  variables: addTeamMutationVariables,
  response: addTeamMutationResponse,
|};
*/


/*
mutation addTeamMutation(
  $input: CreateTeamInput!
) {
  createTeam(input: $input) {
    team {
      ...teamRow_team
      id
    }
  }
}

fragment teamRow_team on Team {
  id
  rowId
  name
  avatar
  account {
    members {
      totalCount
    }
    id
  }
  bots {
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateTeamInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addTeamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateTeamPayload",
        "kind": "LinkedField",
        "name": "createTeam",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "team",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "teamRow_team"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addTeamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateTeamPayload",
        "kind": "LinkedField",
        "name": "createTeam",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Team",
            "kind": "LinkedField",
            "name": "team",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rowId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MembersConnection",
                    "kind": "LinkedField",
                    "name": "members",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BotsConnection",
                "kind": "LinkedField",
                "name": "bots",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "addTeamMutation",
    "operationKind": "mutation",
    "text": "mutation addTeamMutation(\n  $input: CreateTeamInput!\n) {\n  createTeam(input: $input) {\n    team {\n      ...teamRow_team\n      id\n    }\n  }\n}\n\nfragment teamRow_team on Team {\n  id\n  rowId\n  name\n  avatar\n  account {\n    members {\n      totalCount\n    }\n    id\n  }\n  bots {\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8d3bf56934f97a58a14db1316723b909';

module.exports = node;
