export default () => ({
  logoBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  logo: {
    height: 24,
    margin: '30px 0'
  }
});
