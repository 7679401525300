import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { SpanType, withSpanAsync } from 'tracing';

const mutation = graphql`
  mutation removeTeamMemberMutation($input: RemoveTeamMemberInput!) {
    removeTeamMember(input: $input) {
      boolean
    }
  }
`;

export default (
  environment,
  userId,
  userRowId,
  teamId,
  teamRowId,
  organizationRowId
) =>
  withSpanAsync('removeTeamMember', SpanType.mutation, async () => {
    const variables = {
      input: {
        userRowId,
        teamRowId
      }
    };

    return new Promise((resolve, reject) => {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('Response received from server: ', response, errors);

          resolve();
        },
        onError: err => {
          console.error(err);

          reject(err);
        },
        updater: store => {
          const payload = store.getRootField('removeTeamMember');
          if (payload && payload.getValue('boolean')) {
            const user = store.get(userId);

            // Need to pass the same filter used within our component's fragment.
            const teams = user.getLinkedRecord('teams', {
              filter: {
                organizationId: {
                  equalTo: organizationRowId
                }
              }
            });

            const nodes = teams.getLinkedRecords('nodes');

            const newTeams = nodes.filter(n => n.getValue('id') !== teamId);

            // Updating our store
            teams.setLinkedRecords(newTeams, 'nodes');
            teams.setValue(teams.getValue('totalCount') - 1, 'totalCount');
          }
        }
      });
    });
  });
