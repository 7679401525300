/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type signedInViewer_superUserOrgs$ref: FragmentReference;
declare export opaque type signedInViewer_superUserOrgs$fragmentType: signedInViewer_superUserOrgs$ref;
export type signedInViewer_superUserOrgs = {|
  +nodes: $ReadOnlyArray<?{|
    +id: string,
    +rowId: any,
    +name: string,
    +avatar: ?string,
  |}>,
  +$refType: signedInViewer_superUserOrgs$ref,
|};
export type signedInViewer_superUserOrgs$data = signedInViewer_superUserOrgs;
export type signedInViewer_superUserOrgs$key = {
  +$data?: signedInViewer_superUserOrgs$data,
  +$fragmentRefs: signedInViewer_superUserOrgs$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "signedInViewer_superUserOrgs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatar",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationsConnection"
};
// prettier-ignore
(node/*: any*/).hash = '8ba07081dd157c2283533b144e705b91';

module.exports = node;
