/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Provider = "CNP" | "COGNITO" | "GOOGLE" | "KEYCLOAK" | "MICROSOFT_SIA_PARTNERS" | "ONE_ACCOUNT" | "ONE_ACCOUNT_AXA" | "PASS_AXA" | "STRATUMN" | "%future added value";
export type RoleType = "ADMIN" | "LEADER" | "READER" | "%future added value";
export type AddTeamMembersInput = {|
  clientMutationId?: ?string,
  teamRowId?: ?any,
  members?: ?$ReadOnlyArray<?TeamMemberInviteInput>,
|};
export type TeamMemberInviteInput = {|
  email?: ?string,
  role?: ?RoleType,
  addToOrganization?: ?RoleType,
  allowedProviders?: ?$ReadOnlyArray<?Provider>,
  userid?: ?number,
|};
export type addTeamMembersMutationVariables = {|
  input: AddTeamMembersInput,
  teamRowId: any,
|};
export type addTeamMembersMutationResponse = {|
  +addTeamMembers: ?{|
    +query: ?{|
      +teamByRowId: ?{|
        +account: ?{|
          +members: {|
            +totalCount: number
          |}
        |},
        +organization: ?{|
          +collaborators: {|
            +totalCount: number
          |}
        |},
      |}
    |},
    +members: ?$ReadOnlyArray<?{|
      +reader: ?boolean,
      +admin: ?boolean,
      +collaborator: ?boolean,
      +userId: ?any,
      +user: ?{|
        +id: string,
        +rowId: any,
        +email: string,
        +accountId: any,
        +name: string,
        +avatar: ?string,
        +pending: ?boolean,
      |},
    |}>,
  |}
|};
export type addTeamMembersMutation = {|
  variables: addTeamMembersMutationVariables,
  response: addTeamMembersMutationResponse,
|};
*/


/*
mutation addTeamMembersMutation(
  $input: AddTeamMembersInput!
  $teamRowId: BigInt!
) {
  addTeamMembers(input: $input) {
    query {
      teamByRowId(rowId: $teamRowId) {
        account {
          members {
            totalCount
          }
          id
        }
        organization {
          collaborators {
            totalCount
          }
          id
        }
        id
      }
      id
    }
    members {
      reader
      admin
      collaborator
      userId
      user {
        id
        rowId
        email
        accountId
        name
        avatar
        pending
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "AddTeamMembersInput!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamRowId",
    "type": "BigInt!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "teamRowId"
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "MembersConnection",
  "kind": "LinkedField",
  "name": "members",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UsersConnection",
  "kind": "LinkedField",
  "name": "collaborators",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Member",
  "kind": "LinkedField",
  "name": "members",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reader",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "admin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "collaborator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatar",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pending",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addTeamMembersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddTeamMembersPayload",
        "kind": "LinkedField",
        "name": "addTeamMembers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "teamByRowId",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addTeamMembersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddTeamMembersPayload",
        "kind": "LinkedField",
        "name": "addTeamMembers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "Team",
                "kind": "LinkedField",
                "name": "teamByRowId",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "addTeamMembersMutation",
    "operationKind": "mutation",
    "text": "mutation addTeamMembersMutation(\n  $input: AddTeamMembersInput!\n  $teamRowId: BigInt!\n) {\n  addTeamMembers(input: $input) {\n    query {\n      teamByRowId(rowId: $teamRowId) {\n        account {\n          members {\n            totalCount\n          }\n          id\n        }\n        organization {\n          collaborators {\n            totalCount\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    members {\n      reader\n      admin\n      collaborator\n      userId\n      user {\n        id\n        rowId\n        email\n        accountId\n        name\n        avatar\n        pending\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8fef381ec0a348f7fae63ea02c06a3c0';

module.exports = node;
