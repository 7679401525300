import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import compose from 'lodash.flowright';
import { withRouter } from 'react-router-dom';
import { NotFound } from '@stratumn/atomic';
import { ROUTE_MEMBERS } from 'constant/routes';

import styles from './notFound.style';

export const NotFoundPage = ({ history }) => {
  const goToHome = React.useCallback(
    () => {
      history.push(ROUTE_MEMBERS);
    },
    [history]
  );
  return <NotFound onHomeClick={goToHome} />;
};

NotFoundPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default compose(withRouter, injectSheet(styles))(NotFoundPage);
