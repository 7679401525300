/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type KeysOrderBy = "ACCOUNT_ID_ASC" | "ACCOUNT_ID_DESC" | "CREATED_AT_ASC" | "CREATED_AT_DESC" | "DEPRECATED_ASC" | "DEPRECATED_AT_ASC" | "DEPRECATED_AT_DESC" | "DEPRECATED_DESC" | "ID_ASC" | "ID_DESC" | "NATURAL" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "PUBLIC_KEY_ASC" | "PUBLIC_KEY_DESC" | "UPDATED_AT_ASC" | "UPDATED_AT_DESC" | "USAGE_ASC" | "USAGE_DESC" | "%future added value";
export type CreateBotSigningKeyInput = {|
  clientMutationId?: ?string,
  botRowId?: ?any,
  publicKey?: ?string,
|};
export type addBotKeyMutationVariables = {|
  input: CreateBotSigningKeyInput,
  orderKeysBy?: ?$ReadOnlyArray<KeysOrderBy>,
|};
export type addBotKeyMutationResponse = {|
  +createBotSigningKey: ?{|
    +account: ?{|
      +id: string,
      +rowId: any,
      +keys: {|
        +totalCount: number,
        +nodes: $ReadOnlyArray<?{|
          +id: string,
          +rowId: any,
          +publicKey: string,
          +deprecated: boolean,
          +createdAt: any,
          +deprecatedAt: ?any,
        |}>,
      |},
    |}
  |}
|};
export type addBotKeyMutation = {|
  variables: addBotKeyMutationVariables,
  response: addBotKeyMutationResponse,
|};
*/


/*
mutation addBotKeyMutation(
  $input: CreateBotSigningKeyInput!
  $orderKeysBy: [KeysOrderBy!]
) {
  createBotSigningKey(input: $input) {
    account {
      id
      rowId
      keys(orderBy: $orderKeysBy) {
        totalCount
        nodes {
          id
          rowId
          publicKey
          deprecated
          createdAt
          deprecatedAt
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateBotSigningKeyInput!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderKeysBy",
    "type": "[KeysOrderBy!]"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateBotSigningKeyPayload",
    "kind": "LinkedField",
    "name": "createBotSigningKey",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "orderBy",
                "variableName": "orderKeysBy"
              }
            ],
            "concreteType": "KeysConnection",
            "kind": "LinkedField",
            "name": "keys",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Key",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publicKey",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deprecated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deprecatedAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addBotKeyMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addBotKeyMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "addBotKeyMutation",
    "operationKind": "mutation",
    "text": "mutation addBotKeyMutation(\n  $input: CreateBotSigningKeyInput!\n  $orderKeysBy: [KeysOrderBy!]\n) {\n  createBotSigningKey(input: $input) {\n    account {\n      id\n      rowId\n      keys(orderBy: $orderKeysBy) {\n        totalCount\n        nodes {\n          id\n          rowId\n          publicKey\n          deprecated\n          createdAt\n          deprecatedAt\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6192c00ac86b76e7d4d94e3073e6d598';

module.exports = node;
