import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import PropType from 'prop-types';

import injectSheet from 'react-jss';

import moment from 'moment';

import addToClipBoard from 'utils/addToClipBoard';
import pluralize from 'utils/pluralize';

import {
  Pushtext,
  Pushbutton,
  UiconKeyHorizontal,
  UiconClipboard
} from '@stratumn/atomic';
import { DownArrowFill } from '@stratumn/icons';

import styles from './keys.style';

// split active and expired keys
const splitKeys = keys => {
  const activeKeys = [];
  const expiredKeys = [];
  const nbKeys = keys.length;
  for (let idx = 0; idx < nbKeys; idx += 1) {
    const key = keys[idx];
    if (key.deprecated) {
      expiredKeys.unshift({
        idx,
        key
      });
    } else {
      activeKeys.unshift({
        idx,
        key
      });
    }
  }

  return { activeKeys, expiredKeys };
};

export const Keys = ({ classes, keys, expireKey, addKey }) => {
  const [copiedPublicKey, setCopiedPublicKey] = useState(null);
  const [displayExpiredKeys, setDisplayExpiredKeys] = useState(false);
  const pkTimeoutRef = useRef(null);

  // clear copied key timeout on unmount
  useEffect(
    () => () => {
      if (pkTimeoutRef.current) {
        clearTimeout(pkTimeoutRef.current);
      }
    },
    []
  );

  // split active and expired keys
  const { activeKeys, expiredKeys } = useMemo(() => splitKeys(keys), [keys]);

  // copy the publicKey form the key object provided and set component state accordingly
  // timeout after 1500ms and release the state of the component
  const copyPublicKey = useCallback(async key => {
    const { rowId, publicKey } = key;
    addToClipBoard(publicKey);
    setCopiedPublicKey(rowId);
    if (pkTimeoutRef.current) {
      clearTimeout(pkTimeoutRef.current);
    }
    pkTimeoutRef.current = setTimeout(() => {
      setCopiedPublicKey(null);
    }, 1500);
  }, []);

  // active keys
  const activeKeysSection = (
    <div className={classes.section}>
      <div className={classes.sectionHeader}>
        <div className={classes.sectionHeaderTitle}>
          <div className={classes.sectionHeaderTitleText}>Signing Keys</div>
          <UiconKeyHorizontal size={27} />
        </div>
      </div>
      {activeKeys.map(({ idx, key }) => {
        const { createdAt, rowId } = key;
        const isCopiedKey = copiedPublicKey === rowId;
        const keyName = `#${idx < 9 ? '0' : ''}${idx + 1}`;
        return (
          <div key={rowId} className={classes.keyItem}>
            <div className={classes.keyInfo}>
              <div className={classes.keyIdx}>{keyName}</div>
              <div className={classes.keyEvent}>{`Created: ${moment(
                createdAt
              ).format('DD MMM YYYY')}`}</div>
            </div>
            <div className={classes.keyActions}>
              {activeKeys.length > 1 && (
                <div className={classes.keyAction}>
                  <Pushtext
                    onClick={() =>
                      expireKey({
                        name: keyName,
                        key
                      })
                    }
                    dataCy="expire-key"
                  >
                    Expire
                  </Pushtext>
                </div>
              )}
              <div className={classes.keyAction}>
                <Pushtext
                  suffix={<UiconClipboard size={22} checked={isCopiedKey} />}
                  onClick={() => copyPublicKey(key)}
                  mute={!isCopiedKey}
                  dataCy="copy-key"
                >
                  {`${isCopiedKey ? 'Copied' : 'Copy'} public key`}
                </Pushtext>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );

  // expired keys
  const isCollapsible = expiredKeys.length > 0;
  const expiredKeysSection = (
    <div className={classes.section}>
      <div className={classes.sectionHeader}>
        <div className={classes.sectionHeaderTitle}>
          <div className={classes.sectionHeaderTitleText}>Expired Keys</div>
          <UiconKeyHorizontal size={27} />
        </div>
        <div
          className={classes.sectionHeaderCollapse}
          onClick={() =>
            isCollapsible && setDisplayExpiredKeys(!displayExpiredKeys)
          }
          data-is-collapsible={isCollapsible}
          data-cy="display-expired-keys"
        >
          <div className={classes.sectionHeaderCollapseMessage}>
            {pluralize('expired key', expiredKeys.length)}
          </div>
          {isCollapsible && (
            <DownArrowFill
              className={classes.sectionHeaderCollapseIcon}
              data-input-open={displayExpiredKeys}
            />
          )}
        </div>
      </div>
      {displayExpiredKeys &&
        expiredKeys.map(({ idx, key }) => {
          const { createdAt, deprecatedAt, rowId } = key;
          const isCopiedKey = copiedPublicKey === rowId;
          return (
            <div key={rowId} className={classes.keyItem}>
              <div className={classes.keyInfo}>
                <div className={classes.keyIdx}>{`#${idx < 9 ? '0' : ''}${idx +
                  1}`}</div>
                <div className={classes.keyEvent}>{`Created: ${moment(
                  createdAt
                ).format('DD MMM YYYY')}`}</div>
                <div className={classes.keyEvent}>{`Expired: ${moment(
                  deprecatedAt
                ).format('DD MMM YYYY')}`}</div>
              </div>
              <div className={classes.keyActions}>
                <div className={classes.keyAction}>
                  <Pushtext
                    suffix={<UiconClipboard size={22} checked={isCopiedKey} />}
                    onClick={() => copyPublicKey(key)}
                    mute={!isCopiedKey}
                    dataCy="copy-key"
                  >
                    {`${isCopiedKey ? 'Copied' : 'Copy'} public key`}
                  </Pushtext>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );

  // add key button
  const addKeyButton = (
    <div className={classes.addKeyButton}>
      <Pushbutton secondary onClick={addKey} dataCy="add-key">
        Add a signing key
      </Pushbutton>
    </div>
  );

  // render
  return (
    <>
      {activeKeysSection}
      {addKeyButton}
      {expiredKeysSection}
    </>
  );
};

Keys.propTypes = {
  classes: PropType.object.isRequired,
  keys: PropType.arrayOf(PropType.object).isRequired,
  expireKey: PropType.func.isRequired,
  addKey: PropType.func.isRequired
};

export default injectSheet(styles)(React.memo(Keys));
