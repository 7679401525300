import qs from 'query-string';

import { ROUTE_MY_PROFILE, ROUTE_OAUTH, ROUTE_LOGIN } from 'constant/routes';

const LOGIN_REDIRECT_KEY = 'login_redirect';

export const getAndClearLoginRedirect = () => {
  const path = localStorage.getItem(LOGIN_REDIRECT_KEY);
  localStorage.removeItem(LOGIN_REDIRECT_KEY);
  return path;
};

export const setLoginRedirect = path => {
  localStorage.setItem(LOGIN_REDIRECT_KEY, path);
};

export const redirectAfterLogin = (history, redirectUri, authCode) => {
  if (redirectUri) {
    // Redirect back to another service.
    let targetUri = redirectUri;
    if (authCode) {
      const { url, query } = qs.parseUrl(redirectUri);
      targetUri = `${url}?${qs.stringify({ ...query, authCode })}`;
    }
    window.location.replace(targetUri);
  } else {
    // Redirect back to account.
    let route = getAndClearLoginRedirect();
    // Make sure we do not redirect to the login page.
    if (!route || [ROUTE_LOGIN, ROUTE_OAUTH].includes(route)) {
      route = ROUTE_MY_PROFILE;
    }
    history.push(route);
  }
};
