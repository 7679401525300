export default theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  avatarWrapper: {
    marginRight: 10
  },
  text: {
    fontSize: 14,
    color: theme.grey3
  }
});
